import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withAuthorization } from '../../Session';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Card, Paper, Button, Grid, Typography, CircularProgress } from '@material-ui/core';
import { connect }           from "react-redux"

import _ from 'lodash'
import firebase from "firebase";
import { withFirebase } from '../../Firebase';
import Steps from './Steps'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 2,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  container: {
        width: "80%",
        margin: "0 auto",
        padding: 20,
        background: "#f0e68c"
  }
});


function Stepper(props) {
  var cooked = false
  var ready = false
  var delivered = false
  return (
    <div>
      <Grid items container spacing={32}>
        <Grid item lg={4}>

          <Button style={{width: 80, height: 80, borderRadius: 40, backgroundColor: cooked ? "red" : "blue"}}
            onClick={() => {
              // console.log("clicked button", cooked, this);
              if (cooked) {
                cooked = false
              } else {
                cooked = true
              }
            }}>Cooking</Button>

        </Grid>
        <Grid item lg={4}>
          <Button style={{width: 80, height: 80, borderRadius: 40}}>Cooking</Button>

        </Grid>
        <Grid item lg={4}>
          <Button style={{width: 80, height: 80, borderRadius: 40}}>Cooking</Button>
        </Grid>
      </Grid>
    </div>
  )
}


class CurrentOrders extends React.Component {

  constructor(props) {
    super(props)
    this.state = { loading: false}
  }

  componentDidMount() {
    if (_.size(this.props.orders)) {
      this.setState({ loading: true })
    }
  }

  componentWillUpdate(nextProps, nextState) {
    // when isUpdated changed from false to true
    if (nextProps.orderResponse != this.props.orderResponse) {
      // get updated list of dishes
      // this.getOpenOrders()
      // // set isUpdated to false
      // this.getCurrentOrders()

      return true
    }

    if (nextProps.orderStatus == "delivered" && nextProps.orderStatus != this.props.orderStatus) {
      // this.getCurrentOrders()
      // this.getPreviousOrders()
      // console.log("current orders called");
      return true
    }
  }

  showOrder(order) {
    var dishNo = 0

    // console.log("showOrder", order);
    // return _.size(order) ? _.map(this.props.orders, orderObj => {
    //   dishNo = 0
    //   orderId = _.first(_.keys(orderObj))
    //   order = _.first(_.values(orderObj))
    //
    //   console.log("order", order, orderId);
      return _.size(order) ? <div style={{width: "90%", margin: "5%", minHeight: 100, backgroundColor: "white", borderRadius: 10}}>
        <div style={{width: "90%", margin: "5%"}}>
          <Grid items container spacing={32}>
            <Grid item lg={4}>
              {_.map(_.get(order, "dishes", []), dish => {
                dishNo += 1
                return   <Typography variant="h6" gutterBottom>
                    {`${dishNo}. ${_.get(dish, "dishName", "undefined")}`}
                  </Typography>
              })}
            </Grid>
            <Grid item lg={8} >
              <Steps orderId={_.get(order, "orderId", "")} status={_.get(order, "status", "")} deliveryMethods={_.get(order, "deliveryMethods", "")}/>
            </Grid>
            </Grid>
            <br/>
            <Grid items container spacing={32}>
              <Grid item lg={2}>
                <Typography variant="h5" gutterBottom>
                 ${parseFloat(_.get(order, "totalAmount", 0)).toFixed(2)}
                </Typography>
              </Grid>

              <Grid item lg={4}>
                <Typography variant="subtitle1" gutterBottom >
                   <span style={{overflow: "hidden"}}>
                   {_.get(order, "order_time", "Time not found").substr(0, 25)}
                   </span>
                </Typography>
              </Grid>
              <Grid item lg={3}>
                <Typography variant="subtitle1" gutterBottom>
                  Delivery Method: {_.get(order, "deliveryMethods", "")}
                </Typography>
              </Grid>
              <Grid item lg={3}>
                  {_.get(order, "deliveryMethods", "") == "Kitchen Delivery" &&
                    <Typography variant="subtitle1" gutterBottom>
                      Delivery Address: {typeof _.get(order, "address", {}) == "string" ? _.get(order, "address", {}) : _.join(_.values(_.get(order, "address", {})), ", ")}
                    </Typography>}
              </Grid>
            </Grid>
       </div>
     </div>
    : this.state.loading ?
        <div style={{marginTop: 200, marginLeft: "30%"}}> <h3>No Current Orders</h3></div>
        : <CircularProgress style={{marginTop: 200, marginLeft: "45%", color: "rgb(153, 204, 0)"}}/>
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <Card style={{width: "100%", height: 500, backgroundColor: "#E9E9E9"}}>
          <div style={{overflowY: "scroll", height: 470, marginBottom: 10}}>
            {_.map(this.props.orders, order => {
              return this.showOrder(order)
              })
            }
          </div>
        </Card>

      </div>
    )
  }
}

CurrentOrders.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  // console.log("previousOrders state", state)
  return {
    orderStatus : state.kitchenOrder.orderStatus || null,
    orderResponse : state.kitchenOrder.orderResponse || "",
  }
}

const mapDispatchToProps = (dispatch) => ({
  setOrderStatus: orderStatus => dispatch({ type: 'GET_ORDER_RESPONSE', orderStatus }),
})

export default withStyles(styles)
(connect(mapStateToProps, mapDispatchToProps)(CurrentOrders))
