import React from 'react';
import PropTypes from 'prop-types'
import { connect }           from "react-redux"

import { withStyles } from '@material-ui/core/styles';

import { Paper, Grid, InputBase, Divider, IconButton,
          Button, Typography, CircularProgress } from '@material-ui/core'
import LocationIcon from '@material-ui/icons/LocationOn';
import { Category } from '@material-ui/icons';

import firebase from "firebase";
import { withFirebase } from '../../Firebase';

import _ from 'lodash'

import red from '@material-ui/core/colors/red';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';

import geolib from 'geolib'

const styles = theme => ({
  card: {
    maxWidth: "100%",
    minHeight: 400,
    // height: 500
    marginLeft: 0
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  avatar: {
    backgroundColor: red[500],
  },
})

class KitchenListItem extends React.Component {

  constructor() {
    super()

    this.state = {dishNames: [], distance: 0, categories: "", loading: false}

  }

  componentDidMount() {
    this.showDishNames()
    this.getDistance()
    this.showCategories()
    this.setState({ loading: true })
  }

  showCategories = () => {
    var categories = _.get(this.props.kitchen, "categories", [])
        // categoriesVal = []
    //
    // _.each(categories, (val, key) => {
    //   if(val > 0) {
    //     categoriesVal.push(key)
    //   }
    // })

    this.setState({ categories : _.join(categories, " • ") })
  }

  showDishNames() {
    var dishName = this.state.dishNames,
        dishesIds = _.get(this.props.kitchen, "postedDishesIds", [])

    _.each(dishesIds, id => {
      this.props.firebase.dish(id).once('value', snapshot => {
        dishName.push(_.get(snapshot.val(), "dishName"))
        this.setState({ dishNames : _.join(dishName, ", ")})
      })
    })
  }

  getDistance() {
    var lat = _.get(this.props.coords, "latitude", "") || 18.00,
        lngt = _.get(this.props.coords, "longitude", "") || 73.2,
        coords = _.get(this.props.kitchen, "coords", []),
        kitchenLat = _.get(coords, "latitude", "") || 44.00,
        kitchenLngt = _.get(coords, "longitude", "") || 41.2,
        distance = 0

    if (lat && lngt && kitchenLat && kitchenLngt) {
      distance = geolib.getDistance (
                    {latitude: lat, longitude: lngt},
                    {latitude: kitchenLat, longitude: kitchenLngt}
                  )
      distance = distance/1000.0

      this.setState({ distance: distance })
    }
  }

  render() {
    const { classes } = this.props

    return this.state.loading ?
      <Card className={classes.card} onClick={() => this.props.setSelectedKitchen(this.props.kitchen)}>
        <CardHeader
          title={_.get(this.props.kitchen, "kitchen_name", "")}
          subheader={_.get(this.props.kitchen, "city", "")}
        />
        <CardMedia
          className={classes.media}
          image={_.get(this.props.kitchen, "avatarURL", "https://img.freepik.com/free-photo/board-amidst-cooking-ingredients_23-2147749529.jpg?size=626&ext=jpg")}
          title={_.get(this.props.kitchen, "kitchen_name", "")}
        />
        <CardContent>
          <div style={{display: "flex"}}>
            <Typography variant="subtitle1" gutterBottom style={{width: "100%", height: 30, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "noWrap"}}>
              {this.state.categories}
            </Typography>
          </div>
          <div style={{display: "flex"}}>
            <Typography variant="subtitle2" gutterBottom style={{width: "100%", height: 30, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "noWrap"}}>
              {this.state.dishNames}
            </Typography>
          </div>
          <div style={{display: "flex"}}>
            <Grid items container spacing={32}>
              <Grid item lg={6}>
                <Typography variant="body2" gutterBottom>
                  {this.state.distance} KM
                </Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography variant="body2" gutterBottom>
                  Rating: {parseFloat(_.get(this.props.kitchen, "ratings", 0))/parseFloat(_.get(this.props.kitchen, "totalRating", 1))}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card> : <CircularProgress color="secondary"/>
  }
}

KitchenListItem.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  searchedKitchens: state.findFood.searchedKitchens || [],
  coords: state.findFood.coords || {}
})

const mapDispatchToProps = (dispatch) => ({
  setSelectedKitchen: selectedKitchen => dispatch({ type: "GET_SELECTED_KITCHEN", selectedKitchen })
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withFirebase(KitchenListItem)))
