// import React, { Component } from 'react'
// import { FlickingEvent, SelectEvent, ChangeEvent, NeedPanelEvent } from "@egjs/flicking";
// import Flicking from "@egjs/react-flicking";
// import { Parallax, Fade, AutoPlay } from "@egjs/flicking-plugins";


// export class getApp extends Component {
//   render() {
//     return (

//       //   <div id="getApp">
//       //   <div className="container">
//       //     <div className="row">
//       //       <div className="col-xs-12 col-md-6"> <img src="img/appGIF.gif" className="img-responsive" alt=""/> 
//       //       </div>
//       //       <div className="col-xs-12 col-md-6">
//       //         <div className="chef-text">
//       //           <h2>TasteSwap App</h2>
//       //           <div className="col-lg-6 col-sm-6 col-xs-12">
//       //               <ul>
//       //               <h3>How to get started? </h3>
//       //               </ul>
//       //             </div>
//       //             <div className="col-lg-6 col-sm-6 col-xs-12">
//       //               <ul>
//       //               <h3> Saftey Measures </h3>
//       //               </ul>
//       //             </div>
//       //           <div className="list-style">
//       //             <div className="col-lg-6 col-sm-6 col-xs-12">
//       //               <ul>
//       //                 {this.props.data ? this.props.data.Why.map((d, i) => <li  key={`${d}-${i}`}>{d}</li>) : 'loading'}
//       //               </ul>

//       //             </div>
//       //             <div className="col-lg-6 col-sm-6 col-xs-12">
//       //               <ul>
//       //               {this.props.data ? this.props.data.Why2.map((d, i) => <li  key={`${d}-${i}`}> {d}</li>) : 'loading'}

//       //               </ul>
//       //               <a
//       //                 href="trade-main"
//       //                 className="btn btn-custom4 btn-lg page-scroll"
//       //               > Get Started HERE
//       //               </a>
//       //               <br /> 
//       //               <br />
//       //               <a
//       //                 href="trade-main"
//       //                 className="btn btn-custom1 btn-lg page-scroll"
//       //               > Get Started With the App!
//       //               </a>
//       //             </div>
//       //           </div>
//       //         </div>
//       //       </div>
//       //     </div>
//       //   </div>
//       // </div>
//     )
//   }
// }

// export default getApp



import React, { Component } from 'react';
import GetAppCard from './GetAppCard';
import getApp from '../getApp.css'
import data from '../data/data1'
import Iphone from '../iphone.png'
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Arrow from "./Arrow";
import MobileStoreButton from 'react-mobile-store-button';
import Grid from '@material-ui/core/Grid'
import { Hidden } from '@material-ui/core';
import appStore from '../images/appstore.png'
import googlePlay from '../images/googlePlay.png'

import { BorderColor } from '@material-ui/icons';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';


class GetApp extends Component {

  constructor(props){
    super(props);
    this.state = {
      properties: data.properties,
      property: data.properties[2]
    }
  }
  

  nextProperty = () => {
    const newIndex = this.state.property.index+1;
    this.setState({
      property: data.properties[newIndex]
    })
  }

  prevProperty = () => {
    const newIndex = this.state.property.index-1;
    this.setState({
      property: data.properties[newIndex]
    })
  }

  render() {
    const {properties, property} = this.state;
    const iOSUrl = 'https://apps.apple.com/us/app/tasteswap-foodies/id1559387262';
    const androidUrl =  '';

    return (
      

      <div id="getApp" style={{position: "relative"}} className="getApp">  
        <div className="page" style={{height: 800}}>
        <div className="section-title2" style={{textAlign: "center", marginTop: 100}}>
        <h2>TasteSwap App</h2>
          </div>      
       
            <Grid container spacing={32}>
              
              <Grid item xs={6} style={{maxHeight: 100}}>
              {/* <MobileStoreButton
            store="ios"
            url={iOSUrl}
            linkProps={{ title: 'iOS Store Button' }}
            style={{width: 170, marginTop: 12, float: "right"}}
             //
            /> */}
            <a href={'https://apps.apple.com/us/app/tasteswap-foodies/id1559387262'} target="_blank" style={{float: "right", background: "white", borderColor: "none", borderStyle: "none"}}  >
              <img  style={{width: 170, marginTop: 12, height: 60}} 
              src={appStore} alt="my image"  
              />
             
              </a>

              </Grid>
              <Grid item xs={6} style={{maxHeight: 100}}>
              {/* <MobileStoreButton
            store="android"
            url={androidUrl}
            linkProps={{ title: 'Android Store Button' }}
            /> */}
            <a href={''} target="_blank" style={{float: "left", background: "white", borderColor: "none", borderStyle: "none"}}  >
              <img  style={{width: 200, marginTop: -2, height: 90}} 
              src={googlePlay} alt="my image"  
              />
             
              </a>
              </Grid>
              <Grid item xs={2} md={1}>
            
              <Arrow
                    onClick={() => this.prevProperty()} 
                    disabled={property.index === 0}
                    direction="left"
                    style={{color: "black", marginTop: 250}}
                  />
              </Grid>
              <Grid item xs={8} md={10} >
              <div className="col" style={{zIndex: -1000, marginLeft: -75}}>
              <div className={`cards-slider active-slide-${property.index}`}>
                <div className="cards-slider-wrapper" style={{
                  'transform': `translateX(-${property.index*(100/properties.length)}%)`
                }}> 
                
                  {
      
                    properties.map(property => 
                      
                    <GetAppCard key={property._id} property={property} />
                    )                    
                  }
                </div>

              </div>
            </div>
              </Grid>
              <Grid item xs={2} md={1}>
              <Arrow
                onClick={() => this.nextProperty()}  
                disabled={property.index === data.properties.length-1}
                direction="right"
                style={{color: "black", marginTop: 250, alignItems: "flex-end", float: "right"}}

              />
              </Grid>

            </Grid>
            
        </div>
    
      </div>
    );
  }
}


{/* <button 
onClick={() => this.nextProperty()} 
disabled={property.index === data.properties.length-1}
>Next</button>
<button 
onClick={() => this.prevProperty()} 
disabled={property.index === 0}
>Prev</button> */}

export default GetApp;
