import React from 'react'
import PropTypes from 'prop-types';
import { connect }           from "react-redux"
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { FormLabel, CircularProgress } from '@material-ui/core';
import Checkout from './Checkout'
import green from '@material-ui/core/colors/green';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  button: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
});

class PaymentMethod extends React.Component {

  state = {
    value: '',
    loading: false
  };

  handleChange = event => {
    this.setState({ value: event.target.value });
    // console.log("changed payment method", event.target.value);
    this.props.setPaymentMethod(event.target.value)
  };

  componentDidMount() {
    if (this.props.paymentMethod) {
      this.setState({ value : this.props.paymentMethod})
    }

    this.setState({ loading: true })
  }


  render() {
    const { classes } = this.props;

    return this.state.loading ? <div>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">Payment Method</FormLabel>
          <RadioGroup
            aria-label="Payment Method"
            name="paymentMethod"
            className={classes.group}
            value={this.state.value}
            onChange={this.handleChange}

          >
            <FormControlLabel value="cash" control={<Radio className={classes.button} />} label="Pay by cash" />
            <FormControlLabel value="card" control={<Radio className={classes.button}  disabled={true} />} label="Pay by credit or debit card" />
          </RadioGroup>
        </FormControl>
        <div style={{marginLeft: 50}}>
          {
            this.state.value == "card" && this.props.paymentStatus != "PaymentSuccess" ?
              <Checkout
                name={'Add Card Details'}
                description={'#order_number'}
                amount={this.props.totalAmount || 1}
              />
            : <div>{this.state.value == "card" ?
              this.props.paymentStatus == "PaymentSuccess" ?
                <p style={{color: "green"}}>Added card successfully</p>
                : <p style={{color: "green"}}>Error adding card</p> : ""}</div>
          }
        </div>

    </div> : <CircularProgress color="secondary"/>
  }
}

PaymentMethod.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ( state ) => ({
  paymentStatus: state.clientOrder.paymentStatus || "",
  totalAmount: state.clientOrder.totalAmount || 0,
})

const mapDispatchToProps = ( dispatch ) => ({
  setPaymentStatus: paymentStatus => dispatch({ type: "GET_PAYMENT_STATUS", paymentStatus}),
  setPaymentMethod: paymentMethod => dispatch({type: "GET_PAYMENT_METHOD", paymentMethod })
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PaymentMethod));
