import React, { Component } from 'react';
import { connect } from "react-redux"
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Radio from '@material-ui/core/Radio';

import RadioGroup from '@material-ui/core/RadioGroup';

import FormHelperText from '@material-ui/core/FormHelperText';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import FormControl from '@material-ui/core/FormControl';

import { FormLabel, TextField, Grid, CircularProgress } from '@material-ui/core';

import green from '@material-ui/core/colors/green';

import Checkbox from './Checkbox';

import _ from 'lodash'

const items = [
  'One',
  'Two',
  'Three',
];

const styles = theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  button: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
});

class DeliveryMethod extends Component {

  constructor(props) {
    super(props)

    this.state = { methods : [], value : "" , street: "", city: "", zipcode: null, loading: false}
  }

  componentWillMount = () => {
    this.selectedCheckboxes = new Set();
  }

  getMethods() {
    var setMethods = []

    // _.each(this.props.orderDishes, dish => {
    //   if (_.get(dish, "deliveryMethods", "")) {
    //     setMethods = _.concat(setMethods, _.get(dish, "deliveryMethods", ""))
    //   }
    // })

    // console.log("deliveryOptions from sel kitchen", this.props.selectedKitchen, _.get(this.props.selectedKitchen, "deliveryOptions", []));
    this.setState({ methods : _.pull(_.get(this.props.selectedKitchen, "deliveryOptions", []), "3rd Party(i.e UberEats)") })


    // this.props.firebase.kitchen()
    // .orderByChild('userId')
    // .equalTo(_.get(this.props.selectedKitchen, "userId", ""))
    // .limitToFirst(1)
    // .once('value', snapshot => {
    //
    // })

  }

  componentDidMount() {
    // console.log("deliveryMethods", this.props.selectedKitchen);
    this.getMethods()
    if (this.props.deliveryMethod) {
      this.setState({ value: this.props.deliveryMethod})
    }
    this.setState({ loading: true })
  }

  handleChange = event => {
    this.setState({ value: event.target.value });
    // console.log("changed payment method", event.target.value, this.state.methods);
    this.props.setDeliveryMethods(event.target.value)
  };

  createCheckbox = (label) => {
    const { classes } = this.props

    return <FormControlLabel
        value={label}
        control={<Radio className={classes.button} />}
        label={label} />

  }

  handleAddress = event => {
    this.setState({ [event.target.name] : event.target.value})

    this.props.setDeliveryAddress({'street' : this.state.street, 'city' : this.state.city, 'zipcode': this.state.zipcode})
  }

  createCheckboxes = () => {
    const { classes } = this.props
    return <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">Delivery Method</FormLabel>
      <RadioGroup
        aria-label="Delivery Method"
        name="deliveryMethod"
        className={classes.group}
        value={this.state.value}
        onChange={this.handleChange}

      >
       {  this.state.methods.map(this.createCheckbox) }
      </RadioGroup>
    </FormControl>
  }

  render() {
    return this.state.loading ?
      <div className="container">
        <Grid items container spacing={32}>
          <Grid item sm={6} xs={12}>
            <div className="col-sm-12">
              {this.createCheckboxes()}
            </div>
          </Grid>
          <Grid item sm={6} xs={12}>
            { this.state.value === "Kitchen Delivery" &&
                <div id="deliveryAddress">
                  <TextField
                    name="street"
                    value={this.state.street}
                    placeholder="Apartment/House no, Street"
                    label="Apartment/House no, Street"
                    style={{width: "90%", margin: 10}}
                    required
                    onChange={this.handleAddress}/>
                  <TextField
                    name="city"
                    value={this.state.city}
                    placeholder="City"
                    label="City"
                    required
                    style={{width: "40%", margin: 10}}
                    onChange={this.handleAddress}/>
                  <TextField
                    name="zipcode"
                    value={this.state.zipcode}
                    placeholder="Zipcode"
                    label="Zipcode"
                    required
                    style={{width: "40%", margin: 10}}
                    onChange={this.handleAddress}/>
                </div>
            }
          </Grid>
        </Grid>

      </div> : <CircularProgress color="secondary"/>
  }
}

DeliveryMethod.propTypes = {
  classes: PropTypes.object.isRequired,
  // isUpdated: PropTypes.bool.isRequired,
  // history: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  // console.log("post dishes state", state)

  return {
    // isUpdated : state.dishes.isUpdated || false,
    orderDishes: state.findFood.orderDishes || {},
    deliveryMethod: state.clientOrder.deliveryMethod || "",
    selectedKitchen: state.findFood.selectedKitchen || []
  }
}

const mapDispatchToProps = (dispatch) => ({
  setDeliveryMethods: deliveryMethod => dispatch({ type: 'GET_DELIVERY_METHODS', deliveryMethod }),
  setDeliveryAddress: deliveryAddress => dispatch({ type: 'GET_DELIVERY_ADDRESS', deliveryAddress }),
})


export default  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DeliveryMethod));
