const INITIAL_STATE = {
  message: null,
};

const getMessage = (state, action) => ({
  ...state,
  message: action.message.text,
  variant: action.message.variant
});

function snackbarReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'GET_MESSAGE': {
      return getMessage(state, action);
    }
    default:
      return state;
  }
}

export default snackbarReducer;
