import styled from "styled-components";

export default styled.button.attrs(({ type = "button" }) => ({ type }))`
  box-sizing: border-box;
  transition: all 0.3s ease;
  margin: 15;
  font-size: 1.6em;
  background-color: rgb(230, 255, 204);
  color: ${props => (props.disabled ? "#999" : "#333")};
  border-radius: 50%;
  border: none;
  padding: 0;
  width: 50px;
  height: 50px;
  min-width: 50px;
  line-height: 50px;
  align-self: center;
  cursor: pointer;
  outline: none;
  &:hover:enabled,
  &:focus:enabled {
    color: #00e600;
    background-color: rgb(0, 230, 0)	;
    box-shadow: 0 0 2px 0 #333;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;
