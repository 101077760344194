import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import _ from 'lodash';

import * as ROUTES from '../../constants/routes';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
      };
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          // authUser
          //   ? this.setState({ authUser })
          //   : this.setState({ authUser: null });
          if (authUser) {
            this.props.firebase
              .user(authUser.uid)
              .once('value')
              .then(snapshot => {
                const dbUser = snapshot.val();

                // console.log("roles db", dbUser, !!dbUser);
                // default empty roles
                // if (!dbUser) {
                //   dbUser = {}
                //   dbUser.roles = {}
                // } else if (dbUser && !dbUser.roles) {
                //   dbUser.roles = {};
                // }

                // _.get(dbUser, "roles", {})
                if (!_.get(dbUser, "roles", {})) {
                  dbUser.roles = {};
                }

                // merge auth and db user
                authUser = {
                  uid: authUser.uid,
                  email: authUser.email,
                  ...dbUser,
                };

                // console.log("authuser", authUser);

                this.setState({ authUser });
                // console.log("authenticated", this.props);

              });
          } else {
            this.setState({ authUser: null });
          }
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  WithAuthentication.propTypes = {
    history: PropTypes.array.isRequired
  }


  return withFirebase(WithAuthentication);
};

export default withAuthentication;
