import React, { Component } from "react"


class FindFoodSlide extends Component {
  render() {
    return (
      <div style={{width: "100%", height: 500, backgroundColor: "orange"}}>
        <h3>Find Food</h3>
      </div>
    )
  }
}

export default FindFoodSlide;
