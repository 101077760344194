import React from 'react';
import PropTypes from 'prop-types'
import { connect }           from "react-redux"

import { withStyles } from '@material-ui/core/styles';

import { Paper, Grid, InputBase, Divider, IconButton, Fab, TextField,
          Button, Typography, AppBar, Tabs, Tab, Card, CardContent, Avatar, CircularProgress } from '@material-ui/core'
import LocationIcon from '@material-ui/icons/LocationOn';
import { Category } from '@material-ui/icons';

import StarRatings from 'react-star-ratings';

import geolib from 'geolib'

import { Link } from 'react-router-dom';

import * as ROUTES from '../../../constants/routes'

import { ExpandMore, ExpandLess, Close, Add, Remove } from '@material-ui/icons'

import firebase from "firebase";
import { withFirebase } from '../../Firebase';

import _ from 'lodash'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "60%",
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 600,
  },
})

class Dish extends React.Component {

  constructor() {
    super()

    this.state = {distance: 0, removedDishes: [], orderDishes: [], loading: false}
  }

  componentDidMount() {
    // console.log("dish", this.props.dish);
    this.getDistance()
    this.setState({ removedDishes: [], orderedDishes: this.props.orderDishes, loading: true})

  }

  componentWillUpdate(nextProps, nextState) {
    // when isUpdated changed from false to true
    if (nextProps.orderUpdated != this.props.orderUpdated) {
      return true
    }
  }

  getDistance() {
    var currentLatitude = _.get(this.props.address, "coords.latitude", ""),
        currentLongitude = _.get(this.props.address, "coords.longitude", ""),
        kitchenLatitude = _.get(this.props.selectedKitchenkitchen, "coords.latitude", ""),
        kitchenLongitude = _.get(this.props.selectedKitchenkitchen, "coords.longitude", ""),
        distance = 0

    if (currentLatitude && currentLongitude && kitchenLatitude && kitchenLongitude) {
      distance = geolib.getDistance(
                {latitude: currentLatitude, longitude: currentLongitude},
                {latitude: kitchenLatitude, longitude: kitchenLongitude}
            )
      distance = distance/1000.0

      this.setState({ distance: distance})
    }
  }

  render() {
    const classes = this.props;

    var prevOrderedDishes = []

    return this.state.loading ?
      <div>
          <Card className={classes.card} style={{width: "100%", minHeight: 80, maxHeight: 500, marginBottom: "2.5%"}}
            onClick={(e) => {

              if (!(e.target.innerHTML == "Add" || e.target.innerHTML == "Remove") || e.target.innerHTML == "Details") {
                this.props.setDishDetails(this.props.dish)
                var viewMoreDisplay = document.getElementById("dishViewMore")

                viewMoreDisplay.style.display === "none" ? viewMoreDisplay.style.display = "block" : viewMoreDisplay.style.display = "none"
              }

            }}>

            <CardContent>
              <Grid items container spacing={32} style={{width: "100%", margin: 0, padding: 0}}>

                <Grid item lg={3} md={4} sm={4} xs={6}>
                  <Avatar alt="" src={_.get(this.props.dish, "avatarURL", "")}
                    className={classes.bigAvatar}
                    style={{width: 130, height: 130, float: "left"}} />
                </Grid>

                <Grid item lg={3} md={4} sm={4} xs={6}>
                  <Typography variant="h5" component="h2">
                    {_.get(this.props.dish, "dishName", "Unknown")}
                  </Typography>

                  <Typography className={classes.pos} color="textSecondary"
                    style={{whiteSpace: "nowrap", position: "relative", width: 200,
                            overflow: "hidden", textOverflow: "ellipsis"}} >

                      {_.get(this.props.dish, "description", "")}

                  </Typography>
                  <br/>
                  <div >
                    <Typography className={classes.pos} color="textSecondary" variant="subtitle1"
                      style={{height: 25, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "noWrap"}}>
                      Category: {_.join(_.get(this.props.dish, "categoriesMethods", []), ", ")}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary" variant="subtitle1"
                      style={{height: 25, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "noWrap"}}>
                      Restrictions: {_.join(_.get(this.props.dish, "restrictionsMethods", []), ", ")}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary" variant="subtitle1"
                      style={{height: 25, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "noWrap"}}>
                       Delivery: {_.join(_.get(this.props.dish, "deliveryOption", []), ", ")}
                    </Typography>
                  </div>

                  <StarRatings
                      rating={parseFloat(_.get(this.props.dish, "ratings", "0"))/(parseFloat(_.get(this.props.dish, "totalRatings", "1")))}
                      starRatedColor="#ffd633"
                      numberOfStars={5}
                      name='rating'
                      starDimension="20px"
                      starSpacing="0px"
                      style={{marginTop: 10}}
                    />
                </Grid>

                <Grid item lg={6} md={4} sm={4} xs={12} style={{padding: 0, margin: 0}}>

                  <Grid items container spacing={32} style={{width: "100%", padding: 0, margin: 0}}>
                    <Grid item lg={12} sm={12} xs={3}>
                      <Typography variant="h5" component="h2" color="textSecondary" style={{textAlign: "right"}}>
                        {_.get(this.props.dish, "price", 0) ? `$${parseFloat(_.get(this.props.dish, "price", 0)).toFixed(2)}`: "$0.00"}
                      </Typography>
                    </Grid>
                    <Grid item lg={12} sm={12} xs={3} style={{paddingLeft: 0, paddingRight: 0, margin: 0}}>
                      <Typography variant="h5" component="h2" color="textSecondary" style={{textAlign: "right"}}>
                        {this.state.distance} km
                      </Typography>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={6}>
                      <div id="actionBtns" style={{width: "100%"}}>
                        <Grid items container spacing={16}>

                          <Grid item lg={9} md={8} sm={6} xs={7}>
                            <Button style={{backgroundColor: "rgb(241, 119, 33)", color: "black", float: "right"}}
                                    onClick={() => {
                                      // console.log("called dishDetails");
                                      // this.props.setDishDetails(dish)
                                      // var viewMoreDisplay1 = document.getElementById("dishViewMore")
                                      //
                                      // console.log("element", viewMoreDisplay1, viewMoreDisplay1.style.display);
                                      // viewMoreDisplay1.style.display === "none" ?
                                      // document.getElementById("dishViewMore").style.display = "block" :
                                      // document.getElementById("dishViewMore").style.display = "none"

                                    }}>Details</Button>
                          </Grid>

                          <Grid item lg={3} md={4} sm={6} xs={5}>
                            <Button size="medium" id={`btn${_.get(this.props.dish, "id", "")}`}
                              style={{backgroundColor: "rgb(241, 119, 33)", color: "black", float: "right"}}
                              onClick={(e) => {
                                e.preventDefault()
                                if (_.findIndex(this.props.orderDishes, ['id', this.props.dish.id]) != -1) {
                                  prevOrderedDishes = this.props.orderDishes
                                  prevOrderedDishes = _.pullAllBy(prevOrderedDishes, [{'id': this.props.dish.id}], 'id')
                                  this.setState({ removedDishes : _.pullAllBy(this.state.orderedDishes, [{'id': this.props.dish.id}], 'id') })
                                  this.props.orderDish(prevOrderedDishes)
                                  // document.getElementById(`btn${_.get(this.props.dish, "id", "")}`).innerHTML = "Add"
                                } else {
                                  this.props.orderDish(_.concat(this.props.orderDishes, [this.props.dish]))
                                  // document.getElementById(`btn${_.get(this.props.dish, "id", "")}`).innerHTML = "Remove"
                                }
                              }}>

                              {_.findIndex(this.props.orderDishes, ['id', _.get(this.props.dish, "id", "")]) != -1 ? "Remove" : "Add"}

                            </Button>
                          </Grid>

                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
      </div> : <CircularProgress color="secondary"/>
  }
}

Dish.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  selectedKitchen: state.findFood.selectedKitchen || [],
  address: state.findFood.address || "",
  searchedDishes : state.findFood.searchedDishes || [],
  orderDishes: state.findFood.orderDishes || [],
  orderUpdated: state.findFood.orderUpdated || false,

  dishDetails: state.findFood.dishDetails || [],
  removedDish: state.findFood.removedDish || false

})

const mapDispatchToProps = (dispatch) => ({
  setSelectedKitchen: selectedKitchen => dispatch({ type: "GET_SELECTED_KITCHEN", selectedKitchen }),
  setSearchedDishes: searchedDishes => dispatch({ type: 'GET_SEARCHED_DISHES', searchedDishes }),
  orderDish: orderDishes => dispatch({ type: 'ORDER_DISHES', orderDishes }),
  setDishDetails: dishDetails => dispatch({ type: 'DISH_DETAILS', dishDetails })

})

export default withStyles(styles)(withFirebase(connect(mapStateToProps, mapDispatchToProps)(Dish)))
