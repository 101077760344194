const INITIAL_STATE = {
  dishes: null,
  isUpdated: false,
  categoriesMethods: null,
  restrictionsMethods: null,
};

const getDishes = (state, action) => ({
  ...state,
  dishes: action.dishes,
});

const postedDish = (state, action) => ({
  ...state,
  isUpdated: action.isUpdated,
});

const getDishDetails = (state, action) => ({
  ...state,
  dishDetails: action.dishDetails,
});

const getEditDish = (state, action) => ({
  ...state,
  editDish: action.editDish,
});

const getCategoriesMethods = (state, action) => ({
  ...state,
  categoriesMethods: action.categoriesMethods,
});
const getRestrictionsMethods = (state, action) => ({
  ...state,
  restrictionsMethods: action.restrictionsMethods,
});


function dishesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'GET_DISHES': {
      return getDishes(state, action);
    }
    case 'POST_DISH': {
      return postedDish(state, action);
    }
    case 'GET_DISH_DETAILS': {
      return getDishDetails(state, action);
    }
    case 'GET_EDIT_DISH': {
      return getEditDish(state, action);
    }
    case 'GET_GATEGORIES_METHODS': {
      return getCategoriesMethods(state, action);
    }
    case 'GET_RESTRICTIONS_METHODS': {
      return getRestrictionsMethods(state, action);
    }
    default:
      return state;
  }
}

export default dishesReducer;
