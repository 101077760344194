import React from 'react';
import PropTypes             from 'prop-types';

// connect to store
import { connect }           from "react-redux"
import { Paper, withStyles, Grid, TextField, Button, FormControlLabel, Checkbox, Typography, Card } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { SignUpLink } from '../../SignUp';
import { PasswordForgetLink, PasswordForgetForm } from '../../PasswordForget';
import { withFirebase } from '../../Firebase';
import * as ROUTES from '../../../constants/routes';
import logo from '../../../static/images/logo.svg'


const SignInPage = () => (
  <div>
    <h1>SignIn</h1>
    <SignInForm />
    <PasswordForgetLink />
    <SignUpLink />
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  windowWidth: 0,
  windowHeight: 0
};


const styles = theme => ({
    margin: {
        // margin: theme.spacing.unit * 2,

    },
    padding: {
        padding: theme.spacing.unit * 2 ,
        marginTop: 500,
    },
    root: {
      flexGrow: 1,
      width: "100%",
      height: "100%"
    },
    form: {
      textAlign: 'center',
      marginTop: 100,
      width: "80%",
      margin: "5%",
      marginLeft: "10%",
      marginRight: "10%",
    },

    font: {
      variant: 'h1',
    },
    // paper: {
    //   ...theme.mixins.gutters(),
    //   paddingTop: theme.spacing.unit * 4,
    //   paddingBottom: theme.spacing.unit * 4,
    // },
});

class TradeTastes extends React.Component {


      constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
      }

      componentDidMount() {
        this.props.setUserType("tradeTaste")
        this.updateDimensions()
        window.addEventListener("resize", this.updateDimensions);
      }

      componentWillUpdate(nextProps, nextState) {
        if (nextState.windowWidth != this.state.windowWidth) {
          return true
        }
      }

      updateDimensions = () => {
        this.setState({
          windowHeight: window.innerHeight,
          windowWidth: window.innerWidth
        });
      }

       onSubmit = event => {
        const { email, password } = this.state;

        // console.log("login submit", email, password)

        this.props.firebase
          .doSignInWithEmailAndPassword(email, password)
          .then(() => {
            this.setState({ ...INITIAL_STATE });
            this.props.history.push(ROUTES.HOME);
          })
          .catch(error => {
            this.setState({ error });
          });

        event.preventDefault();
      };

      onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
      };

       render() {
        const { classes } = this.props;
        const { email, password, error } = this.state;

        const isInvalid = password === '' || email === '';

         return (
          <div className={classes.root} style={{width: "100%", height: this.state.windowHeight+20, overflowY: "scroll"}}>
          <Grid items container spacing={32}>
            <Grid item lg={7} md={6} sm={5} xs={0} style={{padding: 0, display: this.state.windowWidth <= 600 ? "none" : "block"}}>
              <div style={{width: "100%", height: this.state.windowHeight + 20, backgroundImage: "url(https://images.pexels.com/photos/2062426/pexels-photo-2062426.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)", backgroundSize: "cover"}}>
                <div style={{width: "100%", height: this.state.windowHeight + 20, backgroundColor: "rgb(179, 71, 0, 0.4)"}}>
                <Typography variant="display1" align='center' style={{fontSize: this.state.windowWidth <= 900 ? 40 : 70, paddingTop: 200, paddingLeft: 50, color: "white"}}>
                    Kitchen login <br/>
                        or <br/>
                    Build a kitchen
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item lg={5} md={6} sm={7} xs={12} style={{padding: 0}}>
              <Card style={{width: "100%", height: this.state.windowHeight + 20, overflowY: "scroll"}}>
              {/* <div  style={{marginLeft: "40%", marginTop: "70px"}}>
                  <img src={logo} style={{width: "10%", height: "10%", minWidth: 150, minHeight: "auto"}}/>
               </div> */}
               <div style={{overflowY: "scroll"}}>
                <div className={classes.form} id="kitchenSignIn" style={{display: "block"}}>
                  <h2 style={{textAlign: 'center'}}> Kitchen Login </h2>
                    <TextField
                      id="username"
                      label="Username"
                      type="email"
                      name="email"
                      value={email}
                      onChange={this.onChange}
                      fullWidth autoFocus required />
                    <TextField
                      id="password"
                      label="Password"
                      type="password"
                      name="password"
                      value={password}
                      onChange={this.onChange}
                      fullWidth required />
                    <FormControlLabel control={
                         <Checkbox
                            color="primary"
                         />
                     } label="Remember me" />
                       <Button disableFocusRipple disableRipple style={{ textTransform: "none" }} variant="text" color="primary"
                         onClick={() => {
                           if(document.getElementById("kitchenPasswordForgot")) {
                             document.getElementById("kitchenPasswordForgot").style.display = "block"
                           }
                           if(document.getElementById("kitchenSignIn")) {
                             document.getElementById("kitchenSignIn").style.display = "none"
                           }
                         }}>
                          Forgot password?
                       </Button>
                       <br />
                       <Button onClick={this.onSubmit} variant="outlined" color="primary" style={{ textTransform: "none" }} disabled={isInvalid} type="submit" >Login</Button>
                       <Typography> <h4>No kitchen yet? <Button disableFocusRipple disableRipple style={{ textTransform: "none" }} variant="text" color="primary"> <Link to="/build-kitchen" style={{textDecoration: 'none'}}>Build your kitchen here!</Link></Button></h4>
                        </Typography>
                        {error && <p style={{color: "red"}}>{error.message}</p>}
                  </div>
                  <div id="kitchenPasswordForgot" style={{display: "none"}}>
                    <PasswordForgetForm signInId="kitchenSignIn" forgotId="kitchenPasswordForgot"/>
                  </div>
                  </div>
                </Card>
              </Grid>
              </Grid>


          </div>

        );

       }


}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(TradeTastes);

TradeTastes.propTypes = {
  userType: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  // console.log("dishes state", state)
  return {
    userType : state.nav.userType || ""
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUserType: userType => dispatch({ type: 'GET_USER_TYPE', userType }),
})

export default withStyles(styles)
(connect(mapStateToProps, mapDispatchToProps)
  (SignInForm)
);
