import React       from 'react';
import PropTypes   from 'prop-types';
import { connect } from "react-redux"

import { withRouter }        from 'react-router-dom';
import { withStyles }        from '@material-ui/core/styles';
import { withAuthorization } from '../../Session';
import { withFirebase }      from '../../Firebase';
import firebase              from "firebase";

import { Card, Grid, Button, CardHeader, CardMedia,
            CardContent, CardActions, Typography,
          FormControl,  FormLabel, RadioGroup, FormControlLabel, Radio, TextField, CircularProgress }   from '@material-ui/core';

import _           from 'lodash'
import StarRatings from 'react-star-ratings';

import AddDish     from './AddDish'

import green from '@material-ui/core/colors/green';

import MultipleSelect from './MultipleSelect'

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
  card: {
    width: "70%",
    marginTop: 30,
    marginLeft: "15%",
    height: 550
  },
  img: {
    height: 130,
    textAlign: 'center'
  },
  cardHeader: {
    paddingBottom: 0,
    margin: 0,
    marginTop: 40,
  },
  cardContent: {
    paddingTop: 0,
    height: "70%"
  },
  cardActions: {
    float: "right",
    paddingTop: 10,
    // marginTop: 20,
    marginRight: 20
  },
  close: {
    color: '#aaaaaa',
    float: 'right',
    fontSize: 40,
    fontWeight: 'bold',
    marginRight: 20,
    marginTop: 10,
    '&:hover': {
      color: '#000',
      textDecoration: 'none',
      cursor: 'pointer',
    }
  },
  addDish: {
    display: 'none',
    position: 'fixed',
    zIndex: 1,
    paddingTop: 10,
    paddingBottom: 10,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  datePicker: {
    width: 100,
    // zIndex: 1000
    // fontSize: 30
  },
  dayPickerInput: {
    fontSize:18,
    padding:10,
    display: "block",
    width:100,
    border: "none",
    borderBottom:1,
    borderStyle: "solid",
    borderColor: "#757575",
  },
  button: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DeliveryOptions = [
  'Self-pickup',
  'Kitchen Delivery',
  '3rd Party(i.e UberEats)',
];

class PostForm extends React.Component {

  constructor() {
    super();

    this.state = {editDish: {}, edit: false, value:'',
    cookingAvailability: "availableNow", servings: 1, price: 0, priceUpdated: false,

    dishCookingDate: "", dishExpiryDate: "", orderDeadlineDate: "", orderAcceptingDate: "",
    timeToCook: "",
    kitchenDeliveryMethods: DeliveryOptions, deliveryOptions: [], kitchenId: "",
    cookedDate: "", cookedTime: "", dishExpiryTime: "", orderAcceptingTime: "", orderDeadlineTime: "", timeToCook: "",
    windowWidth: 0, windowHeight: 0, loading: false}
  }



  componentDidMount() {
    this.getDeliveryMethods()

    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions);
    this.setState({
      cookedDate: this.getDate(new Date()),
      dishExpiryDate: this.getDate(new Date()),
      orderDeadlineDate: this.getDate(new Date()),
      orderAcceptingDate: this.getDate(new Date()),
      cookedTime: _.first(this.getTime(this.getDate(new Date()), "past")),
      dishExpiryTime: _.last(this.getTime(this.getDate(new Date()), "future")),
      orderAcceptingTime: this.getTime(this.getDate(new Date()), "future")[0],
      orderDeadlineTime: _.last(this.getTime(this.getDate(new Date()), "future")),
      timeToCook: "00:30",
      loading: true,
      price: _.get(this.props.dish, "price", 0)
    })
    // console.log("dish post", this.props.dish);
    if (document.getElementById("postDish").style.display === "block") {
      // this.setState({ price: _.get(this.props.dish, "price", 0) })
      this.postDishForm()
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.windowWidth != this.state.windowWidth) {
      return true
    }
    //
    if (nextProps.dish != this.props.dish) {
      // console.log("updating post form", this.props.dish);
      this.setState({ price: _.get(this.props.dish, "price", 0)})
      return true
    }
  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  }


  getDeliveryMethods() {
    var kitchen = []
    this.props.firebase.kitchens()
    .orderByChild('userId')
    .equalTo(_.get(firebase.auth().currentUser, "uid", ""))
    .limitToFirst(1)
    .once('value', snapshot => {
        kitchen = _.first(_.values(snapshot.val()))

        // console.log("kitchen", kitchen);

        this.setState({ deliveryOptions: _.get(kitchen, "deliveryOptions", []),
                        kitchenId: _.first(_.keys(snapshot.val())) })
    })
  }

  getDatetime(date, time) {
    var year = new Date().getFullYear()
    var fullDate = `${date} ${year}`
    var times = _.split(time, " ")
    var hour = times[4] == "PM" ?
                  parseInt(_.split(times[3], ":")[0])+12 : _.split(times[3], ":")[0]
    var minutes = _.split(times[3], ":")[1]

    return `${fullDate} ${hour}:${minutes}:00`
  }

  postDishForm() {
    var year = new Date().getFullYear(),
        cookingDatetime = this.getDatetime(this.state.cookedDate, this.state.cookedTime),
        dishExpiryDatetime = this.getDatetime(this.state.dishExpiryDate, this.state.dishExpiryTime),
        orderDeadlineDatetime = this.getDatetime(this.state.orderDeadlineDate, this.state.orderDeadlineTime),
        orderAcceptingDatetime = this.getDatetime(this.state.orderAcceptingDate, this.state.orderAcceptingTime),
        query = {}

  // console.log("Datetimes", cookingDatetime, _.split(this.state.cookedTime, " "), dishExpiryDatetime);
  // console.log("time", this.state.cookedTime, _.split(this.state.cookedTime, " ")[1] == "PM" ? parseInt(_.split(this.state.cookedTime, " ")[0])+12 : _.split(this.state.cookedTime, " ")[0]);
    // console.log("dishExpiryDatetime", this.state.dishExpiryDate, this.state.dishExpiryTime, dishExpiryDatetime);

    if (this.state.cookingAvailability === "availableNow" || this.state.cookingAvailability === "availableLater") {
      query = {'cookingDatetime' : cookingDatetime,
        'dishExpiryDatetime': dishExpiryDatetime,
        'cookingAvailability': this.state.cookingAvailability,
        'servings': this.state.servings,
        'price': this.state.price || _.get(this.props.dish, "price", 0)}
    } else if (this.state.cookingAvailability === "availableOnRequest") {
      query = {'cookingDatetime' : cookingDatetime,
        'orderDeadlineDatetime': orderDeadlineDatetime,
        'orderAcceptingDatetime': orderAcceptingDatetime,
        'cookingAvailability': this.state.cookingAvailability,
        'servings': this.state.servings,
        'price': this.state.price || _.get(this.props.dish, "price", 0),
        'timeToCook': `${this.state.timeToCookHR || 0}:${this.state.timeToCookMM || 0}`}
    }
    this.props.firebase.dish(_.get(this.props.dish, "id", ""))
    .update(query)
    .then(dish => {
      this.props.setMessage({text: "Successfully updated dish availability", variant: 'success'})
    })
    .catch(err => {
      this.props.setMessage({text: "Error updating dish availability", variant: 'error'})
      // console.log("post error", err);
    })

    if (_.size(this.state.deliveryOptions) && this.state.kitchenId) {
      this.props.firebase.kitchen(this.state.kitchenId).update({deliveryOptions: _.uniq(this.state.deliveryOptions)})
      document.getElementById("postDish").style.display = "none"
    }
    // console.log("posting dish", query, this.props.dish, this.props.postingDish, _.get(this.props.dishDetails, "dishId", ""));
  }

  getDate(date) {
    var day  = date.getDay(),
        month = date.getMonth(),
        date = date.getDate()


    var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                  "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"]

    return `${days[day]}, ${months[month]} ${date}`
  }

  getCurrentTime(current, tense) {
    var date = new Date(),
        hour = current ? date.getHours() : 24,
        minutes = date.getMinutes(),
        meridiem = "AM",
        time = ""

    if (hour > 12) {
      hour = hour - 12
      meridiem = "PM"
    }

    if (tense == "past") {
      time = `${hour}:${minutes < 30 ? "00" : "30"} ${meridiem} - ${minutes < 30 ? hour-1 : hour}:${minutes < 30 ? "30" : "00"} ${meridiem}`
    } else {
      time = `${hour}:${minutes < 30 ? "30" : "00"} ${meridiem} - ${minutes < 30 ? hour+1 : hour}:${minutes < 30 ? "00" : "30"} ${meridiem}`
    }



    return time
  }

  getTime(selectedDate, tense) {
      var date = new Date(),
          hours = date.getHours(),
          minutes = date.getMinutes(),
          meridiem = "AM",
          hour = hours,
          times = [],
          count = 0

      if (hour > 12) {
        hour = hour - 12
        meridiem = "PM"
      }

      if (minutes > 30) {
        minutes = 30
      } else {
        minutes = 0
      }

      // console.log("getTime1", this.getDate(date), this.getDate(date) == selectedDate, selectedDate, tense);

      if (this.getDate(date) == selectedDate) {
        if (tense == "past") {
          if (minutes < 30) {
            minutes = 30
          } else {
            minutes = 0
          }

          // console.log("getTime1", "past");

          do{
            if (minutes == 0) {
              times.push(`${hour}:30 ${meridiem} - ${hour}:00 ${meridiem}`)
              if (hour != 0) {
                times.push(`${hour}:00 ${meridiem} - ${hour == 1 && meridiem == "PM" ? "12" : hour-1}:30 ${meridiem}`)
              }
            }

            if (minutes == 30 && hour != 0) {
              times.push(`${hour}:00 ${hour == 12 ? "PM" : meridiem} - ${hour == 1 && meridiem == "PM" ? "12" : hour-1}:30 ${meridiem}`)
              times.push(`${hour == 1 && meridiem == "PM" ? "12" : hour-1}:30 ${meridiem} - ${hour == 1 && meridiem == "PM" ? "12" : hour-1}:00 ${meridiem}`)
            } else {
              times.push(`${hour}:30 ${meridiem} - ${hour}:00 ${meridiem}`)
            }

            hour -= 1

            if (hour == 0 && meridiem == "PM") {
              hour = 12
              meridiem = "AM"
            }
            count += 1
            // hour = Math.abs(hour)
          } while (hour > 0 && count < 24)

        } else if (tense == "future") {
          count = 0
          do{

            if (hour == 12 && meridiem == "AM") {
              hour = 0
              meridiem = "PM"
            }

            if (minutes == 0) {
              times.push(`${hour}:00 ${meridiem} - ${hour}:30 ${meridiem}`)
              times.push(`${hour}:30 ${meridiem} - ${hour+1 == 12 && meridiem == "PM" ? "11" : hour+1}:${hour+1 == 12 && meridiem == "PM" ? "59" : "00"} ${meridiem}`)
            }

            if (minutes == 30) {
              times.push(`${hour}:30 ${meridiem} - ${hour+1 == 12 && meridiem == "PM" ? "11" : hour+1}:${hour+1 == 12 && meridiem == "PM" ? "59" : "00"} ${meridiem}`)
              minutes = 0
            }

            hour += 1

            count += 1

          } while (hour != 12 && meridiem == "PM" && count < 24)
        }
      } else {        // get 24 hours

        if (minutes == 0) {
          times.push(`${hour}:00 ${meridiem} - ${hour}:30 ${meridiem}`)
          times.push(`${hour}:30 ${meridiem} - ${hour+1}:00 ${meridiem}`)
        }

        if (minutes == 30) {
          times.push(`${hour}:30 ${meridiem} - ${hour+1}:00 ${meridiem}`)
        }

        hour += 1

        var noTimes = 0
        count = 0

        do {
            times.push(`${hour}:00 ${meridiem} - ${hour}:30 ${hour == 12 ? "PM" : meridiem}`)
            times.push(`${hour}:30 ${hour == 12 ? "PM" : meridiem} - ${hour+1 > 12 ? 1 : hour+1}:00 ${hour == 12 ? "PM" : meridiem}`)
            hour += 1
            if (hour > 12) {
              hour = hour - 12
              meridiem = meridiem == "AM" ? "PM" : "AM"
            }
          noTimes += 1
          // console.log("noTimes", noTimes);
          count += 1
        } while(noTimes <= 22 && count < 24)
      }

      // console.log("times", tense, selectedDate, times);

      return times
  }

  getFutureDateOpts() {
    var datenow = new Date()

    var dates = []

    dates.push(this.getDate(datenow))


    for (var i = 0; i < 6; i++) {
      datenow.setDate(datenow.getDate()+1)
      dates.push(this.getDate(datenow))
    }

    // console.log("times", dates);

    return dates

  }

  getPastDateOpts() {
    var datenow = new Date()

    var dates = []

    dates.push(this.getDate(datenow))


    for (var i = 0; i < 6; i++) {
      datenow.setDate(datenow.getDate()-1)
      dates.push(this.getDate(datenow))
    }

    // console.log("times", dates);

    return dates
  }

  handleMultiSelectChange = event => {
    this.setState({deliveryOptions : event.target.value})
  }

  handleSelectizeChange = e => {

    // console.log("times", e.target);
    this.setState({ [e.target.name]: e.target.value });
  }

  selectize(options, label, name) {
    const { classes } = this.props
    return     <FormControl className={classes.formControl} style={{width: "100%"}}>
        <InputLabel shrink htmlFor="age-label-placeholder">
          {label}
        </InputLabel>
        <Select
          value={_.get(this.state, name, "")}
          onChange={this.handleSelectizeChange}
          input={<Input required name={name} id="age-label-placeholder" style={{width: "100%"}}/>}
          displayEmpty
          name={name}
          label={label}
          placeholder={label}

          className={classes.selectEmpty}
          style={{width: "100%"}}
        >
          {_.map(options, opt => {
            return <MenuItem value={opt}>{opt}</MenuItem>
          })}
        </Select>
      </FormControl>
  }

  availableNow() {
    const { classes } = this.props
    return <div>
      <Grid items container spacing={32}>
          <Grid item lg={6} xs={6}>
            {this.selectize(this.getPastDateOpts(), "Date cooked", "cookedDate")}
          </Grid>
          <Grid item lg={6} xs={6}>
            {this.selectize(this.getTime(this.state.cookedDate, "past"), "Cooking Time", "cookedTime")}
          </Grid>
        </Grid>
        <Grid items container spacing={32}>
          <Grid item lg={6} xs={6}>
            {this.selectize(this.getFutureDateOpts(), "Date Expiry", "dishExpiryDate")}
          </Grid>
          <Grid item lg={6} xs={6}>

          {this.selectize(this.getTime(this.state.dishExpiryDate, "future"), "Time Expiry", "dishExpiryTime")}
          </Grid>
      </Grid>
    </div>
  }


  availableOnRequest() {
    const { classes } = this.props
    return <div>
      <Grid items container spacing={32}>
        <Grid item lg={6} xs={6}>
          {this.selectize(this.getFutureDateOpts(), "Order Accepting", "orderAcceptingDate")}
        </Grid>
        <Grid item lg={6} xs={6}>
          {this.selectize(this.getTime(this.state.orderAcceptingDate, "future"), "Order Accepting time", "orderAcceptingTime")}
        </Grid>
      </Grid>
      <Grid items container spacing={32}>
        <Grid item lg={6} xs={6}>
          {this.selectize(this.getFutureDateOpts(), "Order deadline date", "orderDeadlineDate")}

        </Grid>
        <Grid item lg={6} xs={6}>
          {this.selectize(this.getTime(this.state.orderDeadlineDate, "future"), "Order deadline time", "orderDeadlineTime")}
        </Grid>
      </Grid>
      <Grid items container spacing={32}>
        <Grid item lg={4}>
          <Typography variant="subtitle1" gutterBottom>
            Time to cook
          </Typography>
        </Grid>
        <Grid item lg={4}>
            <TextField
              value={this.state.timeToCook}
              placeholder="HR:MM"
              name="timeToCook"
              label=""
              style={{width: 150}}
              onChange={this.updateTimeValues}
              />
        </Grid>
      </Grid>
    </div>
  }

  handleChange = event => {
    this.setState({ cookingAvailability: event.target.value });
    // console.log("cookingAvailability", event.target.value);
  };

  updateValues = event => {
    this.setState({ [event.target.name]: event.target.value });

    if (event.target.name == "price") {
      this.setState({ priceUpdated: true })
    }
    // console.log("cookingAvailability", event.target.value);
  };

  updateTimeValues = event => {
    if(_.parseInt(event.target.value.substr(event.target.value.length-1, event.target.value.length)) || event.target.value.substr(event.target.value.length-1, event.target.value.length) == ":") {
      if (_.size(event.target.value) == 2 && _.parseInt(event.target.value)) {
        this.setState({ [event.target.name] : event.target.value + ":"})
      } else {
        this.setState({ [event.target.name] : event.target.value})
      }
    }

    // console.log("time", event.target.value, _.get(this.state, event.target.name));
  };

  handleDateChange(name, date) {
    // console.log("updating date state", name, date);
      this.setState({
          [name] : date,
      });
  }

  render() {
    const { classes } = this.props;
    return this.state.loading ?
      <div>
        <div className="dishDetails" style={{overflow: 'scroll', }}>
          <Card className={classes.card} style={{width: this.state.windowWidth < 700 ? "90%" : "70%", marginLeft: this.state.windowWidth < 700 ? "5%" : "15%"}}>
            <CardContent className={classes.cardContent}>
              <div style={{marginTop: 30, zIndex: -1, overflowY: "scroll", height: 450, width: "90%", marginLeft: "5%"}}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Cooking Availability</FormLabel>
                    <RadioGroup className={classes.button} aria-label="position" name="position" value={this.state.cookingAvailability} onChange={this.handleChange} row>
                      <FormControlLabel
                        value="availableNow"
                        control={<Radio className={classes.button} style={{'&$checked': {color: 'green'}}}/>}
                        label="Available Now"
                        labelPlacement="end"
                        className={classes.button}
                      />
                      <FormControlLabel
                        value="availableLater"
                        control={<Radio className={classes.button} />}
                        label="Available Later"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="availableOnRequest"
                        control={<Radio className={classes.button} />}
                        label="Available On Request"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                  <br/>


                  { (this.state.cookingAvailability === "availableNow" || this.state.cookingAvailability === "availableLater") && this.availableNow()}
                  { this.state.cookingAvailability === "availableOnRequest" && this.availableOnRequest()}
                  <br/>
                  <TextField
                      name="servings"
                      type="number"
                      placeholder="Max Servings"
                      label="Max Servings"
                      value={this.state.servings}
                      onChange={this.updateValues}
                      required
                      style={{margin: 10}}/>
                    <TextField
                      name="price"
                      type="number"
                      placeholder="Price"
                      label="Price"
                      value={this.state.priceUpdated ?  this.state.price : _.get(this.props.dish, "price", 0)}
                      onChange={this.updateValues}
                      required
                      style={{margin: 10}}/>
                    <br/>
                    <br/>

                    <FormControl>
                      <InputLabel htmlFor="select-multiple-checkbox"> Kitchen Delivery Methods</InputLabel>
                      <Select
                        multiple
                        value={this.state.deliveryOptions}
                        onChange={this.handleMultiSelectChange}
                        input={<Input id="select-multiple-checkbox" />}
                        renderValue={selected => selected.join(', ')}
                        MenuProps={MenuProps}
                        style={{width: 350}}
                      >
                        {DeliveryOptions.map(name => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={this.state.deliveryOptions.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

              </div>
              <CardActions className={classes.cardActions}>
                <Button size="medium" variant="contained" style={{backgroundColor: "#99cc00"}}
                  onClick={() => this.postDishForm()}> Save
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </div>
    </div> : <CircularProgress style={{color: "green"}}/>
  }
}

const condition = authUser =>
  authUser && !!authUser.roles && !!authUser.roles['cook'];

PostForm.propTypes = {
  classes: PropTypes.object.isRequired,
  // isUpdated: PropTypes.bool.isRequired,
  // dishDetails: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  // console.log("post dishes state", state, state.dishes.dishDetails)
  return {
    // isUpdated : state.dishes.isUpdated || false,
    // dishDetails: state.dishes.dishDetails || {}

  }
}

const mapDispatchToProps = (dispatch) => ({
  setMessage: message => dispatch({ type: 'GET_MESSAGE', message })
})

export default withStyles(styles)
(withAuthorization(condition)
  (withRouter
    (withFirebase
      (connect(mapStateToProps, mapDispatchToProps)
        (PostForm)
      )
    )
  )
);
