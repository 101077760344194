import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import { TextField, Button, Grid } from '@material-ui/core'

const PasswordForgetPage = () => (
  <div>
    <h1>PasswordForget</h1>
    <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        if (document.getElementById(this.props.forgotId)) {
          document.getElementById(this.props.forgotId).style.display = "none"
        }
        if (document.getElementById(this.props.signInId)) {
          document.getElementById(this.props.signInId).style.display = "block"
        }
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <div style={{textAlign: 'center', marginTop: 200, width: "80%", marginLeft: "10%", marginRight: "10%"}}>
        <form onSubmit={this.onSubmit}>
          <h2 style={{textAlign: "center"}}>Reset Password</h2>
          <TextField
            name="email"
            value={this.state.email}
            onChange={this.onChange}
            type="text"
            style={{margin: 20}}
            placeholder="Email Address"
            required
            fullWidth required
          />
          <Grid items container spacing={32}>
            <Grid item xs={6}>
              <Button style={{backgroundColor: "orange", width: "90%"}} onClick={() => {
                  if (document.getElementById(this.props.forgotId)) {
                    document.getElementById(this.props.forgotId).style.display = "none"
                  }
                  if (document.getElementById(this.props.signInId)) {
                    document.getElementById(this.props.signInId).style.display = "block"
                  }
                }}>
                  Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button disabled={isInvalid} type="submit" style={{backgroundColor: "#9BD238",  width: "90%"}}>
                { window.innerWidth < 700 ? "Reset" : "Reset Password" }
              </Button>
            </Grid>
          </Grid>

          {error && <p style={{color: "red"}}>{error.message}</p>}
        </form>
      </div>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
