import React, { Component } from "react";
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Logo from '../../public/cheflogo.png'

export class Navigation extends Component {
  render() {
    return (


      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>

            {/* <img src={Logo} style={{width: 50, marginLeft: -210}}/> */}
            <a 
            className="navbar-brand page-scroll " href="#page-top" >              
              TasteSwap
            </a>{" "}
          </div>
          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href="#tryTaste" className="page-scroll">
                  Tasteswap
                </a>
              </li>
              <li>
                <a href="#chef" className="page-scroll">
                  TasteSwap Chef
                </a>
              </li>
              <li>
                <a href="#how" className="page-scroll">
                  How it works? 
                </a>
              </li>
              <li>
                <a href="#getApp" className="page-scroll">
                  Get the Apps
                </a>
              </li>
              {/* <li>
                <a href="#testimonials" className="page-scroll">
                  Testimonials
                </a>
              </li> */}
              <li>
                <a href="#team" className="page-scroll">
                  Team
                </a>
              </li>
              <li>
                <a href="#contact" className="page-scroll">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;
