export const INGREDIENTS = [
  "Rice",
  "Chicken",
  "Lentils",
  "eggs",
  "milk",
  "flour",
  "sugar",
  "salt",
  "oil",
];
