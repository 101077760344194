// import React from 'react';
// import Button from '@material-ui/core/Button';
// import Snackbar from '@material-ui/core/Snackbar';
// import PropTypes from 'prop-types';
// import { connect }           from "react-redux"
//
// class SnackbarMessages extends React.Component {
//
//   constructor(props) {
//     super(props)
//     this.state = {open: false, vertical: 'bottom', horizontal: 'right'}
//   }
//
//   handleClose = () => {
//     this.setState({ open: false });
//   }
//
//   componentWillUpdate(nextProps, nextState) {
//     if (this.props.message != nextProps.message) {
//       this.setState({ open: true})
//     }
//   }
//
//   render () {
//     const { vertical, horizontal, open } = this.state;
//
//     return (
//       <div>
//         <Snackbar
//           anchorOrigin={{ vertical, horizontal }}
//           key={`${vertical},${horizontal}`}
//           open={open}
//           onClose={this.handleClose}
//           autoHideDuration={6000}
//           ContentProps={{
//             'aria-describedby': 'message-id',
//           }}
//           variant="success"
//           message={<span id="message-id">{this.props.message}</span>}
//         />
//       </div>
//     );
//   }
// }
//
// SnackbarMessages.propTypes = {
//   classes: PropTypes.object.isRequired
// }
//
// const mapStateToProps = (state) => ({
//   message: state.snackbar.message || ""
// })
//
// export default connect(mapStateToProps, null)(SnackbarMessages)

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import { connect }           from "react-redux"

// import { makeStyles } from '@material-ui/core/styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = theme => ({
  success: {
    backgroundColor: '#90c43b',
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 10,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function MySnackbarContentWrapper(props) {
  const { classes } = props;
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message} style={{color: "black"}}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} style={{color: "black"}}/>
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} style={{color: "black"}}/>
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

// const useStyles2 = theme => ({
//   margin: {
//     margin: theme.spacing(1),
//   },
// });

class CustomizedSnackbars extends React.Component {
  constructor(props) {
    super(props)

    this.state = { open: false }
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props.message != nextProps.message) {
      this.handleClose()
      this.setState({ open: true })
    }
  }
  handleClick = () => {
    this.setState({ open: true })
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false })
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant={this.props.variant || 'info'}
            message={this.props.message}
            classes={classes}
          />
        </Snackbar>
      </div>
    );
  }

}

CustomizedSnackbars.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  message: state.snackbar.message || "",
  variant: state.snackbar.variant || ""
})

export default withStyles(styles)(connect(mapStateToProps, null)(CustomizedSnackbars))
