export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const SIGNOUT = '/signout';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/forget-password';

export const LANDING = '/';

export const HOME = '/home';
export const BUILD_KITCHEN = '/build-kitchen';
export const DISHES = '/dishes';
export const KITCHEN_PROFILE = '/my-kitchen';
export const TRADETASTES = '/trade-main';
export const KITCHEN_ACCOUNT = '/kitchen-account';
export const ORDERS = '/orders';
export const POST_DISH = '/post-dish';


export const FIND_FOOD = '/find-food';
export const TASTE = '/taste';
export const CLIENT_ORDER = '/client-order';


export const FOODTEST = '/safety-test';
export const FEATURES = '/features';
export const PRIVACY = '/privacy'; 


