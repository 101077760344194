import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withAuthorization } from '../../Session';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Card, Paper, Button, Grid } from '@material-ui/core';
import { connect }           from "react-redux"

import _ from 'lodash'
import firebase from "firebase";
import { withFirebase } from '../../Firebase';

import Tabs from './Tabs'
import PreviousOrders from './PreviousOrders'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 2,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

let id = 0;
function createData(orderNumber, customerName, location, dishName, DeliveryOption, OrderStatus) {
  id += 1;
  return { orderNumber, customerName, location, dishName, DeliveryOption, OrderStatus };
}

const rows = [
  createData('1','Ahmed', '21 Celeveland Blvd Caldwell ID', 24, 4.0, 'deliverd'),
  createData('2','Kanchan', '12 Celeveland Blvd Caldwell ID', 37, 4.3,'delivered'),
  createData('3','Granzow', '120 Celeveland Blvd Caldwell ID', 24, 6.0,'delivered'),
  createData('4','Moha' , '1211 Celeveland Blvd Caldwell ID', 67, 4.3,'delivered'),
  createData('5','Alaa', '2009 Celeveland Blvd Caldwell ID', 49, 3.9,'delivered'),

];

class Orders extends React.Component {

  state = { openOrders: [],
    currentOrders: [],
     previousOrders: [], kitchenDishes: [], allOrders: [], windowWidth: 0, windowHeight: 0}

  componentDidMount() {

    // get orders only for the current kitchen
    this.getAllOrders()
    this.getKitchenDishes()

    // this.getOpenOrders()
    // this.getPreviousOrders()
    // this.getCurrentOrders()

    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions);
  }

  // isUpdated is set to true whenever dish is posted, updated, added, or deleted
  componentWillUpdate(nextProps, nextState) {
    // when isUpdated changed from false to true
    if (nextProps.orderResponse != this.props.orderResponse) {
      // get updated list of dishes
      this.getOpenOrders()
      // set isUpdated to false
      this.getCurrentOrders()
      this.getAllOrders()

    }

    if (nextProps.orderStatus != this.props.orderStatus) {
      // this.getCurrentOrders()
      this.getPreviousOrders()
      this.getAllOrders()
      // console.log("update", this.props.orderStatus);

    }

    if (nextState.windowWidth != this.state.windowWidth) {
      return true
    }
  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  }


  getAllOrders() {
    var allOrders = []
    var openOrders = []
    var orders = []
    var prevOrders  = []
    this.props.firebase.orders()
      .orderByChild('kitchenUserId')
      .equalTo(_.get(firebase.auth(), "currentUser.uid", ""))
      .once('value', snapshot => {
        allOrders = _.map(snapshot.val(), (order, id) => {
          return _.merge({orderId: id}, order)
        })
        // console.log("allOrders", allOrders);

      openOrders = _.filter(allOrders, ['status', 'open'])
      // console.log("open orders", openOrders);

      // console.log("original orders", snapshot.val());
      this.setState({openOrders:  openOrders})

      orders = _.filter(allOrders, o => o.status == "accepted" || o.status == "cooked" || o.status == "dish_ready")
      // console.log("currentOrders", orders);
      this.setState({currentOrders: orders})

      prevOrders = _.filter(allOrders, o => o.status == "complete" || o.status == "recieved" || o.status == "delivered")
      this.setState({previousOrders: prevOrders})
    })
  }

  getKitchenDishes() {
    this.props.firebase.dishes()
    .orderByChild("userId").equalTo(_.get(firebase.auth(), "currentUser.uid", ""))
    .once('value', snapshot => {
      this.setState({kitchenDishes: snapshot.val()})
      // console.log("kitchenDishes", this.state.kitchenDishes);

    })
  }

  getOpenOrders() {
    var openOrders = []
    this.props.firebase.orders()
      .orderByChild('kitchenUserId')
      .equalTo(_.get(firebase.auth(), "currentUser.uid", ""))
      .once('value', snapshot => {
      openOrders = _.filter(snapshot.val(), ['status', 'open'])
      // console.log("open orders", openOrders);

      // console.log("original orders", snapshot.val());

      this.setState({openOrders:  openOrders})
    })
  }

  getCurrentOrders() {
    var orders = []

    this.props.firebase.orders()
      .orderByChild('kitchenUserId')
      .equalTo(_.get(firebase.auth(), "currentUser.uid", ""))
      .once('value', snapshot => {
        // console.log("orders", snapshot.val());
        // _.each(snapshot.val(), (order, orderId) => {
        //   if (_.get(order, "status", "") == "accepted" || _.get(order, "status", "") == "cooked" || _.get(order, "status", "") == "dish_ready" || _.get(order, "status", "") == "delivered") {
        //     orders.push({ [orderId] : order})
        //   }
        // })
        orders = _.filter(snapshot.val(), o => o.status != "completed" || o.status != "open" || o.status != "recieved" || o.status != "delivered")
        this.setState({currentOrders: orders})
      })
  }

  getPreviousOrders() {
    var orders  = []
    this.props.firebase.orders()
      .orderByChild('kitchenUserId')
      .equalTo(_.get(firebase.auth(), "currentUser.uid", ""))
      .once('value', snapshot => {
      // console.log("orders", snapshot.val());
      orders = _.filter(snapshot.val(), o => o.status == "complete" || o.status == "recieved" || o.status == "delivered")
      this.setState({previousOrders: orders})
    })
  }

  showPreviousOrders() {
    const classes = this.props
    return   <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Order Number</TableCell>
              <TableCell align="right">Custmer Name </TableCell>
              <TableCell align="right">Location</TableCell>
              <TableCell align="right">Dish Name</TableCell>
              <TableCell align="right">Delivery Option</TableCell>
              <TableCell align="right">OrderStatus</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.orderNumber}
                </TableCell>
                <TableCell align="right">{row.customerName}</TableCell>
                <TableCell align="right">{row.location}</TableCell>
                <TableCell align="right">{row.dishName}</TableCell>
                <TableCell align="right">{row.DeliveryOption}</TableCell>
                <TableCell align="right">{row.OrderStatus}</TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
  }

  declineAcceptOrder(key, action) {
    // console.log("key", key)
    this.props.firebase.order(key).update({status: action})
    .then(ord => {
      // console.log("order ", ord, action);
    })
    .catch(err => console.log("error accepting or declining", err))
  }

  showCurrentOrders() {
    return _.map(this.state.openOrders, (order, orderId) => {

      return <Card style={{margin: 20}}>
        {_.map(order, (val, key) => {
          return <div>{key} : {val}</div>
        })}
        <Button style={{margin: 10}} variant="contained" color="secondary" >Decline</Button>
        <Button style={{margin: 10}} variant="contained" color="primary" onClick={() => {this.declineAcceptOrder(orderId, "accepted")}}>Accept</Button>

    </Card>
    })
  }

  showAllPreviousOrders() {
    return _.map(this.state.previousOrders, (order, orderId) => {

      return <Card style={{margin: 20}}>
        {_.map(order, (val, key) => {
          return <div>{key} : {val}</div>
        })}
    </Card>
    })
  }

  render () {
    const { classes } = this.props;

    return (
      <div style={{width: "100%", marginTop: 60}}>
        <Grid items container spacing={32} style={{width: "100%"}}>
          <Grid item md={7} xs={12} style={{paddingRight: this.state.windowWidth < 700 ? 0 : "auto"}}>
            <div style={{width: "100%", backgroundColor: "#C4C4C4"}}>
              <Tabs pendingOrders={this.state.openOrders} currentOrders={this.state.currentOrders} kitchenDishes={this.state.kitchenDishes}/>
            </div>
          </Grid>
          <Grid item md={5} xs={12} style={{paddingRight: this.state.windowWidth < 700 ? 0 : "auto"}}>
            <PreviousOrders previousOrders={this.state.previousOrders}  kitchenDishes={this.state.kitchenDishes}/>
          </Grid>
        </Grid>
      </div>


    );
  }

}

// <div>
//   <h1>Current Order</h1>
//   {this.showCurrentOrders()}
//   <h1>Previous Orders</h1>
//   {this.showAllPreviousOrders()}
// </div>

const condition = authUser =>
  authUser && !!authUser && _.get(_.get(authUser, "roles", {}), "cook", false);

Orders.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  // console.log("previousOrders state", state)
  return {
    orderDetails : state.kitchenOrder.orderDetails || {},
    orderResponse : state.kitchenOrder.orderResponse || "",
    orderStatus : state.kitchenOrder.orderStatus || "",
  }
}

const mapDispatchToProps = (dispatch) => ({
  setOrderDetails: orderDetails => dispatch({ type: 'GET_ORDER_DETAILS', orderDetails }),
  setOrderResponse: orderResponse => dispatch({ type: 'GET_ORDER_RESPONSE', orderResponse }),
})

export default withStyles(styles)
// (
  // withAuthorization
  // (condition)
    (
      withFirebase
        (withAuthorization(condition)
          (connect(mapStateToProps, mapDispatchToProps)
            (Orders)
          )
        )
    )
  // );
