import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux"
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import { Typography, TextField, CircularProgress } from '@material-ui/core';

import firebase from "firebase";
import { withFirebase } from '../../Firebase';

import PaymentMethod from './PaymentMethod'
import ConfirmAndPlace from './ConfirmAndPlace'

import _ from 'lodash';
import StatusConfirmation from './StatusConfirmation';
import StarRatings from 'react-star-ratings';
import ReviewOrder from './ReviewOrder';
import DeliveryMethod from './DeliveryMethod';

// import { createMuiTheme } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: 'rgb(241, 119, 33)',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#88C603',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
});

const styles = theme => ({
  root: {
    width: '90%',
    padding: 10,
    overflow: "scrolls"
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

function getSteps() {
  return ['Select payment method', 'Delivery Method', 'Place your order', 'Status and confirmation', 'Add a review'];
}

function getShortLabels() {
  return ['Payment', 'Delivery', 'Order', 'Status', 'Review'];
}

function getStepSubmitLabel(step) {
  switch (step) {
    case 0:
      return 'Add payment method';
    case 1:
      return 'Delivery Method'
    case 2:
      return 'Place your order';
    case 3:
      return 'Recieved';
    case 4:
      return 'Review';
    default:
      return 'Unknown step';
  }
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <PaymentMethod/>;
    case 1:
      return <DeliveryMethod/>
    case 2:
      return <ConfirmAndPlace/>;
    case 3:
      return <StatusConfirmation/>;
    case 4:
      return <ReviewOrder/>;
    default:
      return 'Unknown';
  }
}



class OrderSteps extends React.Component {
  state = {
    activeStep: 0,
    completed: {},
    windowWidth: 0,
    windowHeight: 0,
    loading: false
  };

  totalSteps = () => getSteps().length;

  handleNext = () => {
    let activeStep;

    if (this.isLastStep() && !this.allStepsCompleted()) {
      // It's the last step, but not all steps have been completed,
      // find the first step that has been completed
      const steps = getSteps();
      activeStep = steps.findIndex((step, i) => !(i in this.state.completed));
    } else {
      activeStep = this.state.activeStep + 1;
    }
    this.setState({
      activeStep,
    });
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleStep = step => () => {
    this.setState({
      activeStep: step,
    });
  };

  handleComplete = () => {
    const { completed } = this.state;
    completed[this.state.activeStep] = true;



    if (this.state.activeStep == 1) {
      completed[0] = true;
    }
    if (this.state.activeStep == 2) {
      completed[0] = true;
      completed[1] = true;
      this.placeOrder()
    } else if (this.state.activeStep == 3) {
      completed[0] = true;
      completed[1] = true;
      completed[2] = true;
      this.recievedOrder()
    } else if (this.state.activeStep == 4) {
      completed[0] = true;
      completed[1] = true;
      completed[2] = true;
      completed[3] = true;
      this.reviewOrder()
    }
    this.setState({
      completed,
    });
    this.handleNext();
  };

  reviewOrder() {
    // console.log("called reviewOrder");
    var dishes = []
    var kitchenId = ""
    var ratings = 0
    var totalRatings = 0
    this.props.firebase.order(_.get(this.props.currentOrder, "orderId", "")).update({rating: _.get(this.props.review, "rating", 0), comment: _.get(this.props.review, "comment", ""), status: "complete"})
    .then(ord => {
      // console.log("reviewed status", ord, _.get(this.props.currentOrder, "orderId", ""));
      this.props.firebase
      .order(_.get(this.props.currentOrder, "orderId", ""))
      .once('value', snapshot => {
        dishes = _.get(snapshot.val(), "dishes", [])

        // console.log("dishes", dishes, snapshot.val());
        _.each(dishes, dish => {
          this.props.firebase.dish(_.get(dish, "dishId", ""))
          .once('value', snap => {
            // console.log("foudn dish to rate", snap.val(), this.props.review, _.get(dish, "ratings", 0) + _.get(this.props.review, "rating", 0), _.get(dish, "totalRating", 0) + 1);
            ratings = _.get(snap.val(), "ratings", 0) + _.get(this.props.review, "rating", 0)
            totalRatings = _.get(snap.val(), "totalRatings", 0) + 1
            this.props.firebase.dish(_.get(dish, "dishId", ""))
            .update({'ratings': ratings, 'totalRatings': totalRatings})
          })
        })

        this.props.firebase.kitchens()
        .orderByChild('userId')
        .equalTo(_.get(snapshot.val(), "kitchenUserId", ""))
        .limitToFirst(1)
        .once('value', snap => {
          // console.log("found kitchen", snap.val(), _.first(_.values(snap.val())));
          ratings = _.get(_.first(_.values(snap.val())), "ratings", 0) + _.get(this.props.review, "rating", 0)
          totalRatings = _.get(_.first(_.values(snap.val())), "totalRatings", 0) + 1
          // console.log("kitchen ratings", ratings, totalRatings, _.get(snap.val(), "ratings", 0), _.get(this.props.review, "rating", 0), _.get(snap.val(), "totalRatings", 0) );
          this.props.firebase.kitchen(_.first(_.keys(snap.val())))
          .update({'ratings': ratings, 'totalRatings': totalRatings})
        })
        this.props.setCurrentOrder([])

      })
    }).catch(err => {
      // console.log("error updating reviewed status");
    })




  }

  recievedOrder() {

    // console.log("recievedOrder", _.first(_.keys(this.props.currentOrder)));
    this.props.firebase.order(_.get(this.props.currentOrder, "orderId", "")).update({status: "recieved"})
    .then(ord => {
      // console.log("recieved status", ord);
    }).catch(err => {
      // console.log("error updating recieved status");
    })
  }


  placeOrder = () => {
    // console.log("order placed", this.props.totalAmount, typeof parseFloat(this.props.totalAmount).toFixed(2), parseFloat(this.props.totalAmount).toFixed(2))
    var order = {
      userId: _.get(firebase.auth().currentUser, "uid", ""),
      dishes: _.map(this.props.orderDishes, dish =>  {
        return {'dishId': _.get(dish, "id", ""),
          'orderServings': _.get(dish, "orderServings", 1),
          'orderNote': _.get(dish, "orderNote", ""),
          'avatarURL': _.get(dish, "avatarURL", ""),
          'price': parseFloat(_.get(dish, "price", "")).toFixed(2),
          'dishName': _.get(dish, "dishName", "")}
      }),
      kitchenUserId: _.get(_.first(this.props.orderDishes), "userId", ""),
      totalAmount: typeof this.props.totalAmount == "number" ?  parseFloat(this.props.totalAmount).toFixed(2) : 0.00,
      deliveryMethods: this.props.deliveryMethod,
      paymentMethod: this.props.paymentMethod,
      isCreatedAt: new Date().toString(),
      isUpdatedAt: new Date().toString(),
      order_time: new Date().toString(),
      status: "open",
      address: this.props.deliveryAddress
    }

    // console.log("order", order, firebase.auth());
    const addOrder = this.props.firebase.orders().push()
    // console.log("new order", addOrder);
    this.getCurrentOrder(_.get(addOrder, "key", ""))
    addOrder.set(_.merge(order, {"orderId": _.get(addOrder, "key", "")}))
    // this.props.firebase.orders().push().set(order)
    .then(dish => {
      // console.log("dish placed", dish)
      this.props.setMessage({text: "Order placed successfully", variant: 'success'})
      this.props.orderDish([])
    }).catch(err => {
      this.props.setMessage({text: "Error placing order", variant: 'error'})
      // console.log("error placing order", err);
    })
  }

  getCurrentOrder(key) {
    this.props.firebase.order(key).once('value', snapshot => {
      this.props.setCurrentOrder({ [key] : snapshot.val() })
    })
  }

  handleReset = () => {
    this.setState({
      activeStep: 0,
      completed: {},
      status: ""
    });
  };

  completedSteps() {
    return Object.keys(this.state.completed).length;
  }

  isLastStep() {
    return this.state.activeStep === this.totalSteps() - 1;
  }

  allStepsCompleted() {
    return this.completedSteps() === this.totalSteps();
  }

  componentDidMount() {

    // console.log("completedSteps", this.state.completed);
    if (this.state.activeStep === 0) {
      this.setState({ activeStep : this.props.stepNo })
      const { completed } = this.state;

      for (var i = 0; i < this.props.stepNo; i++) {
        completed[i] = true
      }

      this.setState({ completed })
    }

    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions);

    this.setState({ loading: true })
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.windowWidth != this.state.windowWidth) {
      return true
    }
  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  }

  disableButton() {
    var btn = true
    // console.log("avtivestep", this.state.activeStep, _.get(this.props.currentOrder, "status", ""), this.props.status);
    switch (this.state.activeStep) {
      case 0:
        // console.log("activeStep 0", this.props.paymentMethod == "cash" || this.props.paymentStatus == "PaymentSuccess", this.props.paymentStatus);
        if (this.props.paymentMethod == "cash" || this.props.paymentStatus == "PaymentSuccess") {
          btn = false
        }
        break;
      case 1:
        if (this.props.deliveryMethod === "Kitchen Delivery" && !_.size(this.props.deliveryAddress)) {
          btn = true
        } else if (_.size(this.props.deliveryMethod)) {
          btn = false
        }
        break;
      case 2:
        btn = false
        break;
      case 3:
        if (_.get(this.props.currentOrder, "status", "") && (_.get(this.props.currentOrder, "status", "") === "delivered" || _.get(this.props.currentOrder, "status", "") === "recieved")) {
          btn = false
        }
        break;
      case 4:
        btn =  false
        break;
      default:
        btn = true
    }

    // console.log("button disable", btn);

    return btn
  }

  // <Button onClick={this.handleReset}>Reset</Button>

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;

    return this.state.loading ?
      <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <Stepper color="secondary"
            nonLinear={this.state.windowWidth < 700 ? false : true}
            alternativeLabel={this.state.windowWidth < 700 ? true : false}
            activeStep={activeStep}
            style={{padding: this.state.windowWidth < 700 ? 0 : 15}}>
          {steps.map((label, index) => (
            <Step color="secondary"  key={label}>
              <StepButton color="secondary" onClick={this.handleStep(index)} completed={this.state.completed[index]}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          {this.allStepsCompleted() ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed - you&apos;re finished
              </Typography>
            </div>
          ) : (
            <div>
              <div style={{overflowY: "scroll", height: this.state.windowWidth < 700 ? 200 : 250}}>
                <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
              </div>
              <div style={{marginTop: 5, marginLeft: this.state.windowWidth < 700 ? "10%" : "40%"}}>
                <Button
                  disabled={activeStep === 0}
                  onClick={this.handleBack}
                  className={classes.button}
                  color="secondary"
                >
                  Back
                </Button>
                {activeStep !== steps.length &&
                  (this.state.completed[this.state.activeStep] ? (
                    <Typography variant="caption" className={classes.completed}>
                      Step {activeStep + 1} already completed
                    </Typography>
                  ) : (
                    <Button color="primary" variant="contained" onClick={this.handleComplete} disabled={this.disableButton()}>
                      {this.completedSteps() === this.totalSteps() - 1 ? 'Finish' : getStepSubmitLabel(activeStep)}
                    </Button>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </MuiThemeProvider> : <CircularProgress color="secondary"/>
  }
}

OrderSteps.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => {
  // console.log("steps state", state);
  return {
    address: state.findFood.address || {},
    orderDishes: state.findFood.orderDishes || [],
    paymentMethod: state.clientOrder.paymentMethod || "",
    paymentStatus: state.clientOrder.paymentStatus|| "",
    deliveryMethod: state.clientOrder.deliveryMethod || "",
    deliveryAddress: state.clientOrder.deliveryAddress || {},
    currentOrder: state.clientOrder.currentOrder || {},
    review: state.clientOrder.review || {},
    totalAmount: state.clientOrder.totalAmount || {}
  }
}

const mapDispatchToProps = (dispatch) => ({
  // setSearchedDishes: searchedDishes => dispatch({ type: 'GET_SEARCHED_DISHES', searchedDishes }),
  setReview: review => dispatch({ type: 'GET_REVIEW', review }),
  setCurrentOrder: currentOrder => dispatch({ type: 'GET_CURRENT_ORDER', currentOrder }),
  orderDish: orderDishes => dispatch({ type: 'ORDER_DISHES', orderDishes }),
  setMessage: message => dispatch({ type: 'GET_MESSAGE', message })
})


export default
withStyles(styles)
(
  withFirebase(
    connect(mapStateToProps, mapDispatchToProps)
      (OrderSteps)
  )
);
