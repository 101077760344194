import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Navigation from '../Navigation';
import Privacy from '../Privacy';
import LandingPage from '../Landing';
import TastePage from '../Tasting/Taste';
import TradeTastesLanding from "../Trading/TradeTastes";
import Dishes from "../Trading/Dishes";
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Trading/Home';
import Orders from '../Trading/Orders';
import Kitchenaccount from '../Trading/KitchenAccount';
import PrivacyPolicy from '../Privacy';
import SafetyTest from '../Trading/FoodSafteyTest';
import AccountPage from '../Account';
import KitchenProfile from '../Trading/MyKitchen';
import FindFood from '../Tasting/FindFood';
import ClientOrder from '../Tasting/ClientOrder';
import BuildYourKitchen from '../Trading/BuildKitchen';
import SignOut from '../SignOut';
// import Dishes from '../KitchenDishes/Dishes';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import firebase from "firebase";
import _ from 'lodash'
import store from '../../store'
import { Provider }         from "react-redux"
import history from '../../history'
import Snackbar from '../SnackbarMessages/Snackbar'


// const landingRedirect = () => {
//   if (_.get(firebase.auth(), "currentUser", {})) {
//     if (_.get(firebase.auth().currentUser, "roles.cook", false)) {
//       return <KitchenProfile/>
//     } else {
//       return <TastePage/>
//     }
//   } else {
//     return <LandingPage/>
//   }
// }
const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <div>
        <Navigation />
        <div style={{width: "100%", overflow: "scroll"}}>
        <Route exact path={ROUTES.LANDING} component={LandingPage} />
        <Route exact path={ROUTES.KITCHEN_ACCOUNT} component={Kitchenaccount} />
        <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route exact path={ROUTES.SIGN_IN} component={TastePage} />
        <Route exact path={ROUTES.SIGNOUT} component={SignOut} />
        <Route exact path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage}/>
        <Route exact path={ROUTES.HOME} component={HomePage} />
        <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route exact path={ROUTES.KITCHEN_PROFILE} component={KitchenProfile} />
        <Route exact path={ROUTES.FIND_FOOD} component={FindFood} />
        <Route exact path={ROUTES.CLIENT_ORDER} component={ClientOrder} />
        <Route exact path={ROUTES.BUILD_KITCHEN} component={BuildYourKitchen} />
        <Route exact path={ROUTES.KITCHEN_PROFILE} component={KitchenProfile} />
        <Route exact path={ROUTES.TASTE} component={TastePage} />
        <Route exact path={ROUTES.TRADETASTES} component={TradeTastesLanding} />
        <Route exact path={ROUTES.ORDERS} component={Orders} />
        <Route exact path={ROUTES.DISHES} component={Dishes} />
        <Route exact path={ROUTES.FOODTEST} component={SafetyTest} />
        <Route exact path={ROUTES.PRIVACY} component={PrivacyPolicy} />

        </div>
        <Snackbar/>
      </div>
    </Router>
  </Provider>
);

export default withAuthentication(App);
// export default App;
