import _ from 'lodash'

const INITIAL_STATE = {
  searchedDishes: [],
  address: "",
  orderDishes: [],
  dishDetails: [],
  selectedCategories: [],
  searchedKitchens: [],
  coords: {},
  selectedKitchen: null,
  orderUpdated: false,
  scheduleDate: null
};

const getSearchedDishes = (state, action) => ({
  ...state,
  searchedDishes: action.searchedDishes,
});

const getSearchedKitchens = (state, action) => ({
  ...state,
  searchedKitchens: action.searchedKitchens,
});

const getAddress = (state, action) => ({
  ...state,
  address: action.address,
});

const getCoords = (state, action) => ({
  ...state,
  coords: action.coords,
});

const orderDishes = (state, action) => {
  console.log("called order dish", state, action.orderDishes);

  return {
  ...state,
  orderDishes: action.orderDishes,
  orderUpdated: !state.orderUpdated
}};


const getDishDetails = (state, action) => ({
  ...state,
  dishDetails: action.dishDetails,
});

const getSelectedCategory = (state, action) => {
  return {
  ...state,
  selectedCategories: action.selectedCategories,
}};

const getSelectedKitchen = (state, action) => {
  return {
  ...state,
  selectedKitchen: action.selectedKitchen,
}};

const getScheduleDate = (state, action) => {
  return {
  ...state,
  scheduleDate: action.scheduleDate,
}};

function findFoodReducer(state = INITIAL_STATE, action) {
  console.log("action called", action);
  switch (action.type) {
    case 'GET_SEARCHED_DISHES': {
      return getSearchedDishes(state, action);
    }
    case 'GET_SEARCHED_KITCHENS': {
      return getSearchedKitchens(state, action);
    }
    case 'GET_ADDRESS': {
      return getAddress(state, action);
    }
    case 'GET_SELECTED_CATEGORIES': {
      return getSelectedCategory(state, action);
    }
    case 'ORDER_DISHES': {
      return orderDishes(state, action);
    }
    case 'DISH_DETAILS': {
      return getDishDetails(state, action);
    }
    case 'GET_COORDS': {
      return getCoords(state, action);
    }
    case 'GET_SELECTED_KITCHEN': {
      return getSelectedKitchen(state, action);
    }
    case 'GET_SCHEDULE_DATE': {
      return getScheduleDate(state, action);
    }
    default:
      return state;
  }
}

export default findFoodReducer;
