import React from 'react';
import PropTypes from 'prop-types';
import { connect }           from "react-redux"
import { withStyles } from '@material-ui/core/styles';
import { withAuthorization } from '../../Session';
import firebase from 'firebase'
import { withFirebase } from '../../Firebase';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Card, Divider, CircularProgress } from '@material-ui/core';
import _ from 'lodash'
import Checkout from './Checkout';
import OrderSteps from './OrderSteps'
import PreviousOrders from './PreviousOrders'

const styles = theme => ({
  orderSummary: {
    width: '90%',
    marginTop: theme.spacing.unit * 2,
    overflowX: 'auto',
    marginLeft: "2%",
    height: 400
  },
  table: {
    minWidth: 700,
  },
});

class ClientOrder extends React.Component {
  constructor() {
      super();
      this.state = {stripe: null,
                    previousOrders: [],
                    openOrder: [],
                    stepNo : 0,
                    months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
                    windowWidth: 0,
                    windowHeight: 0,
                    loading: false};
    }

  componentDidMount() {

    this.getOpenOrders()
    this.getTotalAmount()
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions);
    this.setState({ loading : true })
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.windowWidth != this.state.windowWidth) {
      return true
    }
  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  }

  getTotalAmount() {
    var total = 0
    // console.log("getTotalAmount");
    if (_.size(this.props.orderDishes)) {
      _.each(this.props.orderDishes, dish => {
        total += (parseFloat(_.get(dish, "price", 0)) * _.parseInt(_.get(dish, "orderServings", 1)))

      })
      // console.log("total", total);
      if (total > 0) {
        this.props.setTotalAmount(total)
      }
    }
  }

  componentWillUpdate(nextProps, nextState) {
    // when isUpdated changed from false to true
    // console.log("update", nextProps.orderUpdated, this.props.orderUpdated);
    if (nextProps.orderUpdated != this.props.orderUpdated) {
      this.getOpenOrders()

      return true
    }

    // if (!_.size(nextProps.currentOrder)) {
    //   // this.getOpenOrders()
    //   return true
    // }

  }

  getOpenOrders() {
    var userOpenOrder = []

    if (!_.size(this.props.orderDishes)) {
      this.props.firebase.orders()
      .orderByChild("userId")
      .equalTo(_.get(firebase.auth().currentUser, "uid", ""))
      .once('value', snapshot => {

        userOpenOrder = _.last(_.filter(snapshot.val(), (o) => o.status != "completed"))
        // console.log("userOpenOrders", userOpenOrder, _.get(userOpenOrder, "status", "") );
        _.get(userOpenOrder, "status", "") == "recieved" ? this.setState({ stepNo : 4}) : this.setState({ stepNo : 3})

        this.setState({openOrder: userOpenOrder })
        this.props.setCurrentOrder(userOpenOrder)
        this.props.setTotalAmount(parseFloat(_.get(userOpenOrder, "totalAmount", "0")).toFixed(2))

      })
    }

  }

  getSummary(dishes) {
    var total = 0.0
    return <div>
        <div>
          <Grid items container spacing={32}>
            <Grid item sm={6}>Dish</Grid>
            <Grid item sm={6}>Price</Grid>
          </Grid>
        </div>
        {_.map(dishes, dish => {
          total = total + (parseFloat(_.get(dish, "price", 0) * parseFloat(_.get(dish, "servings", 1))))
          return <div>
            <Grid items container spacing={32}>
              <Grid item sm={6}>{_.get(dish, "dishName", "")}, {_.get(dish, "orderServings", 1)}</Grid>
              <Grid item sm={6}>${(parseFloat(_.get(dish, "price", 0) * _.get(dish, "orderServings", 1))).toFixed(2)}</Grid>
            </Grid>
          </div>
        })}
        <br/>
        <Divider />
        <br/>
        <Grid items container spacing={32}>
          <Grid item sm={6}>Total</Grid>
          <Grid item sm={6}>${this.props.totalAmount ? this.props.totalAmount : parseFloat(total).toFixed(2)}</Grid>
        </Grid>

        <br/><br/>

      {_.size(this.state.openOrder) > 0 ? <h3>Order placed on {this.state.months[new Date(_.get(this.state.openOrder, "order_time", "")).getMonth()]},
        {new Date(_.get(this.state.openOrder, "order_time", "")).getDate()}
      </h3> : ""}

    </div>
  }

  render () {
    const { classes } = this.props;

    return this.state.loading ?
      <div style={{marginTop: 100}}>
        {
          (_.size(this.props.orderDishes) > 0 || _.size(this.props.currentOrder) > 0) ?
        <Grid items container spacing={8}>

              <Grid item lg={4} md={5} xs={12}>
                <Paper className={classes.orderSummary} style={{marginLeft: "5%", marginRight: "5%", height: this.state.windowWidth < 700 ? 300 : 400}}>
                  <div style={{paddingLeft: 50, marginBottom: 30}}>
                    <h1>Order Summary</h1>
                    {_.size(this.props.orderDishes) > 0 ?
                      this.getSummary(this.props.orderDishes) :
                      _.size(this.state.openOrder) > 0 ? this.getSummary(_.get(this.state.openOrder, "dishes", [])) : ""
                    }
                    <br/>
                  </div>
                </Paper>

              </Grid>

          <Grid item lg={8} md={7} xs={12}>
            <Paper style={{width: "90%", marginTop: 15, height: 400, marginLeft: "5%", marginRight: "5%"}}>
               {_.size(this.state.openOrder) > 0 ?
                 <OrderSteps stepNo={this.state.stepNo} status={this.state.openOrder, "status", ""}/> :
                   <OrderSteps stepNo={0} />}
            </Paper>
          </Grid>
        </Grid> : ""
      }

      <PreviousOrders/>

      </div> : <CircularProgress color="secondary"/>
  }
}

const condition = authUser =>
  authUser && !!authUser && authUser.uid;

ClientOrder.propTypes = {
  classes: PropTypes.object.isRequired,
  orderDishes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  // console.log("clientorders state", state)
  return {
    orderDishes : state.findFood.orderDishes || [],
    orderUpdated : state.findFood.orderUpdated || false,
    currentOrder: state.clientOrder.currentOrder || [],
    totalAmount: state.clientOrder.totalAmount || 0
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentOrder: currentOrder => dispatch({ type: 'GET_CURRENT_ORDER', currentOrder }),
  setTotalAmount: totalAmount => dispatch({ type: 'GET_TOTAL_AMOUNT', totalAmount }),
})

export default withStyles(styles)
(withAuthorization(condition)
  (connect(mapStateToProps, mapDispatchToProps)
    (withFirebase(ClientOrder))
  )
);
