import React from 'react';
import PropTypes from 'prop-types'
import { connect }           from "react-redux"

import { withStyles } from '@material-ui/core/styles';

import { Paper, Grid, InputBase, Divider, IconButton,
          Button, Typography, CircularProgress } from '@material-ui/core'
import LocationIcon from '@material-ui/icons/LocationOn';
import { Category } from '@material-ui/icons';

import firebase from "firebase";
import { withFirebase } from '../../Firebase';

import _ from 'lodash'

import KitchenListItem from './KitchenListItem'

const styles = theme => ({
  // container: {
  //   display: 'flex',
  //   flexWrap: 'wrap',
  // },
  // textField: {
  //   marginLeft: theme.spacing.unit,
  //   marginRight: theme.spacing.unit,
  //   width: "60%",
  // },
  // root: {
  //   padding: '2px 4px',
  //   display: 'flex',
  //   alignItems: 'center',
  //   width: 600,
  // },
})

class KitchensList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {loading : false}
  }

  componentDidMount() {
    this.setState({ loading: true })
  }

  render() {

    const { classes } = this.props

    return this.state.loading ?
      <div style={{marginTop: 120, position: "static"}}>
        {
          _.size(this.props.searchedKitchens) ? <h2 style={{marginLeft: 20}}>Nearby Kitchens</h2> : ""
        }
        <Grid items container spacing={32} style={{width: "100%", margin: 0}}>
          {
            _.map(this.props.searchedKitchens, kitchen => {
              return (
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <KitchenListItem kitchen={kitchen}/>
                </Grid>
              )
            })
          }
        </Grid>
      </div> : <CircularProgress color="secondary"/>
  }
}

KitchensList.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  searchedKitchens: state.findFood.searchedKitchens || [],
  // address: state.findFood.address
})

const mapDispatchToProps = (dispatch) => ({
  // setSelectedKitchen: selectedKitchen => dispatch({ type: "GET_SELECTED_KITCHEN", selectedKitchen })
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(KitchensList))
