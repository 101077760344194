import React, { Component } from "react"
import firebase from "firebase"
import { withRouter } from 'react-router-dom';

import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { withFirebase } from '../../Firebase';
import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';

import _ from 'lodash'

class FirebaseLogin extends Component {
  state = { isSignedIn: false }
  uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      // firebase.auth.EmailAuthProvider.PROVIDER_ID,
      // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccess: () => false
    }
  }


  componentDidMount = () => {
    firebase.auth().onAuthStateChanged(user => {
      this.setState({ isSignedIn: !!user })
      // console.log("signed in", user, this.props.history);
      if (user) {
        // console.log("user found");
        this.props.history.push(ROUTES.FIND_FOOD)
      }
    })
  }

  render() {
    return (
      <div className="x">
        {this.state.isSignedIn ? (
            <span>
              <div>Signed In!</div>
              <h1>Welcome {firebase.auth().currentUser.displayName}</h1>
              {
                // console.log("authuser try taste", firebase.auth(), firebase.auth().currentUser)
              }
              <img
                alt="profile picture"
                src={firebase.auth().currentUser.photoURL}
              />
            </span>
        ) : (
          
          <StyledFirebaseAuth
            uiConfig={this.uiConfig}
            firebaseAuth={firebase.auth()}
          />
        )}
      </div>
    )
  }

}

FirebaseLogin.propTypes = {
  history: PropTypes.array.isRequired
}

export default
(withFirebase
  (withRouter
    (FirebaseLogin)
  )
)
