import React from 'react';

import { AuthUserContext } from '../Session';
import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import { withAuthorization } from '../Session';
import firebase from 'firebase'
import { withFirebase } from '../Firebase';
import {Typography, Button} from '@material-ui/core'
import _ from 'lodash'

class AccountPage extends React.Component {

  state = {currentUser: []}

  getUserInfo() {
    // console.log("userId", _.get(firebase.auth().currentUser, "uid", ""));
    this.props.firebase.user(_.get(firebase.auth().currentUser, "uid", ""))
    .once('value', snapshot => {
      // console.log("found user", snapshot.val());
      this.setState({ currentUser : snapshot.val()})
    })


  }

  componentDidMount() {
    this.getUserInfo()

    if (!_.size(this.state.currentUser)) {
      this.setState({ currentUser: firebase.auth().currentUser})
    }

    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions);

    // console.log("currentUser", this.state.currentUser);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.windowWidth != this.state.windowWidth) {
      return true
    }
  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  }



  render () {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div style={{width: "100%", overflow: "scroll", marginTop: 100, marginLeft: this.state.windowWidth < 700 ? "2%" : "20%"}}>
              <Typography variant="h3" gutterBottom style={{margin: 10}}>
                Profile
              </Typography>
              <Typography variant="display1" gutterBottom style={{margin: 10}}>
                Name: {_.get(firebase.auth().currentUser, "displayName", `${_.get(firebase.auth().currentUser, "first_name", "")} ${_.get(firebase.auth().currentUser, "last_name", "")}`)}
              </Typography>
              <Typography variant="display1" gutterBottom style={{margin: 10}}>
                Email: {_.get(firebase.auth().currentUser, "email", "email not found")}
              </Typography>
              <Button id="btnChangePassword" size="small" style={{backgroundColor: "orange", margin: 10}} onClick={() => document.getElementById("changePassword").style.display = "block"}>Change Password</Button>
              <PasswordChangeForm style={{margin: 20, width: "100%"}}/>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(withFirebase(AccountPage));
