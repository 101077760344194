import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import Categories from '../Tasting/FindFood/categories';

// import serviceAccount from 'serviceAccount.json'

const config = {
  apiKey: "AIzaSyBTv5sJ6rwfxDnNEUNlEt1iAcY60lvpycQ",
  authDomain: "tasteswap.firebaseapp.com",
  databaseURL: "https://tasteswap-default-rtdb.firebaseio.com",
  projectId: "tasteswap",
  storageBucket: "tasteswap.appspot.com",
  messagingSenderId: "934041375152",
  appId: "1:934041375152:web:2ffd25bc3a4b8d5cb1c675",
  measurementId: "G-W6XCNXPMRW"
 };

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();

  }
  //*****************
  // *** Auth API ***
  //*****************

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  doGetUserByEmail = email =>
    this.auth.getUserByEmail(email);

  doSetCustomUserClaims = uid =>
    this.auth.setCustomUserClaims(uid, {cook: true});
  //
  // async function grantCookRole (email) {
  //     const user = await admin.auth().getUserByEmail(email); // 1
  //     console.log("user customClaims", user.customClaims, user);
  //     if (user.customClaims && user.customClaims.cook === true) {
  //         return;
  //     } // 2
  //     return admin.auth().setCustomUserClaims(user.uid, {
  //         cook: true
  //     }); // 3
  // }
  //
  // grantCookRoleByUid = uid =>
  //   admin.auth().setCustomUserClaims(uid, {
  //       cook: true,
  //       admin: true
  //   }); // 3

//   async function grantModeratorRole(email) {
//     const user = await admin.auth().getUserByEmail(email); // 1
//     if (user.customClaims && user.customClaims.moderator === true) {
//         return;
//     } // 2
//     return admin.auth().setCustomUserClaims(user.uid, {
//         moderator: true
//     }); // 3
// }
  // grantCookRole = uid => {
  //   // const user = this.auth.getUserByEmail(email); // 1
  //   // if (user.customClaims && user.customClaims.cook === true) {
  //   //     return;
  //   // } // 2
  //   return this.auth.setCustomUserClaims(uid, {
  //       cook: true
  //   }); // 3
  // }

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  //*****************
  // *** User API ***
  //*****************


  user = uid => this.db.ref(`users/${uid}`);
  userById = id => this.db.ref(`users/${id}`);
  users = () => this.db.ref('users');


  kitchen = (userId) => this.db.ref(`kitchens/${userId}`);

  kitchens = () => this.db.ref('kitchens');

  dish = (id) => this.db.ref(`dishes/${id}`);

  dishesByKitchenId = (userId) => this.db.ref(`dishes/${userId}`);


  // dishByLocationAndCAtegories = (location, category) => this.db.ref(`dishes/${location}/${category}`);

  dishByCategories = (Categories) => this.db.ref('dishes/${category}');


  dishes = () => this.db.ref('dishes');

  dishStorage = (id) => this.storage.ref(`kitchen_pictures/dishes_pictures/${id}`);

  dishesStorage = () => this.storage.ref('kitchen_pictures/dishes_pictures');

  // storage = () => this.storage.ref('kitchen_pictures/dishes_pictures');

  profileStorage = (id) => this.storage.ref(`kitchen_pictures/profile_pictures/${id}`);

  profilesStorage = () => this.storage.ref('kitchen_pictures/profile_pictures');

  orders = () => this.db.ref('orders');
  order = (id) => this.db.ref(`orders/${id}`);

  feedbacks = () => this.db.ref('feedbacks');
  feedback = (id) => this.db.ref(`feedbacks/${id}`);

  applications = () => this.db.ref('applications');
  application = (id) => this.db.ref(`applications/${id}`);

  resumeStorage = (id) => this.storage.ref(`resumes/${id}`);
  resumesStorage = () => this.storage.ref('resumes');

}

export default Firebase;
