import React, { Component } from 'react'

export class chef extends Component {
  render() {
    return (

        <div id="chef">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6"> <img src="img/about.jpg" className="img-responsive" alt=""/> 
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="chef-text">
                <h2>TasteSwap Chef</h2>
                <p>{this.props.data ? this.props.data.paragraph : 'loading...'}</p>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                    <h3>Get Started! </h3>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                    <h3> Safety Measures </h3>
                    </ul>
                  </div>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {this.props.data ? this.props.data.Why.map((d, i) => <li  key={`${d}-${i}`}>{d}</li>) : 'loading'}
                    </ul>

                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                    {this.props.data ? this.props.data.Why2.map((d, i) => <li  key={`${d}-${i}`}> {d}</li>) : 'loading'}

                    </ul>
                    <a
                      href="#getApp"
                      className="btn btn-custom1 btn-lg page-scroll"
                      style={{width: 300}}
                    > Download the app now!
                    </a>
                    
                    <br /> 
                    <br />
                    <a
                      href="trade-main"
                      className="btn btn-custom4 btn-lg page-scroll"
                      style={{width: 300}}
                    > Stay on browser
                    </a>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default chef
