import React       from 'react';
import PropTypes   from 'prop-types';
import { connect } from "react-redux"

import { withRouter }        from 'react-router-dom';
import { withStyles }        from '@material-ui/core/styles';
import { withAuthorization } from '../../Session';
import { withFirebase }      from '../../Firebase';
import firebase              from "firebase";

import classnames  from 'classnames';
import _           from 'lodash'
import StarRatings from 'react-star-ratings';

import { Card, CardHeader, CardContent, CardActions,
                                  Button,Typography }  from '@material-ui/core';

import DishDetails from './DishDetails'

const styles = theme => ({
  card: {
    minWidth:250,
    maxWidth: "100%",
    height: 260
  },
  img: {
    height: 130,
    textAlign: 'center'
  },
  cardHeader: {
    paddingBottom: 0,
    margin: 0
  },
  cardContent: {
    paddingTop: 0,
  },
  cardActions: {
    float: "right",
    paddingTop: 0,
  },
  dishDetails: {
    display: 'none',
    position: 'fixed',
    zIndex: 1,
    paddingTop: 10,
    paddingBottom: 10,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.3)',
  }
});

class Dish extends React.Component {

  constructor() {
    super();
    this.state = { expanded: false, dishDetails: {}, postedDishesIds: [] };
  }

  postDish = () => {
    var post = {}
    var postedDishes = []
    var kitchenId = ""
    var kitchenCategories = []
    if (this.props.dish.isPosted) {     // if true then unpost
      post = {isPosted: false}
    } else {
      post = {isPosted: true}
      this.props.setPostingDish(this.props.dish)
      this.props.setDishDetails(this.props.dish)

      document.getElementById("postDish").style.display = "block"
    }


    this.props.firebase.kitchens()
    .orderByChild('userId')
    .equalTo(_.get(firebase.auth().currentUser, "uid", ""))
    .limitToFirst(1)
    .once('value', snapshot => {
      // console.log("adding posted dishes kitchen", snapshot.val(), this.props.dish);

      this.setState({ postedDishesIds: _.get(_.first(_.values(snapshot.val())), "postedDishesIds", []) })
      kitchenId = _.first(_.keys(snapshot.val()))

      postedDishes = this.state.postedDishesIds
      kitchenCategories = _.get(_.first(_.values(snapshot.val())), "categories", {})

      // console.log("has postedDishes", postedDishes, _.first(_.values(snapshot.val())));


      if (this.props.dish.isPosted) {
        _.pull(postedDishes, this.props.dish.id)
        kitchenCategories[this.props.dish.category] ? kitchenCategories[this.props.dish.category] -= 1 : kitchenCategories[this.props.dish.category] = 0

      } else {
        postedDishes.push(this.props.dish.id)
        // _.concat(postedDishes, this.props.dish.id)
        kitchenCategories[this.props.dish.category] ? kitchenCategories[this.props.dish.category] += 1 : kitchenCategories[this.props.dish.category] = 1
      }

      // console.log("postedDishes", postedDishes, this.props.dish.isPosted, this.props.dish.id);
      // console.log("kitchenCategories", kitchenCategories, this.props.dish.category);

      this.props.firebase.kitchen(kitchenId).update({ postedDishesIds: postedDishes, categories: kitchenCategories })
      .then(postedDish => {
        // console.log("dish is posted");
      }).catch(err => {
        // console.log("dish posting error");
      })

    })

    this.props.firebase.dish(this.props.dish.id).update(post)
    .then(dish => {
      // console.log("dish is posted", dish);
      this.props.postedDish(true)

      this.props.setMessage({text: `Successfully ${this.props.dish.isPosted ? "unposted" : "posted"} dish`, variant: 'success'})
      this.setState({})
    })
    .catch(error => {
      this.props.setMessage({text: `Error ${this.props.dish.isPosted ? "unposting" : "posting" } dish`, variant: 'error'})
      // console.log("error posting dish", error);
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div>
          <Card className={classes.card}>
            <div style={{display: "flex",
                              justifyContent: "center",
                              alignItems: "center"}}>
              <img src={_.get(this.props.dish, "avatarURL", "")} className={classnames(classes.img, "cover")}
                style={{width: "100%", objectFit: "cover"}} alt=""/>
              <span style={{marginTop: 50}}>
                {!_.get(this.props.dish, "avatarURL", "") ? "Image not available" : ""}
              </span>
            </div>
            <CardHeader
              title={_.get(this.props.dish, "dishName", "")}
              className={classes.cardHeader}
              style={{width: "100%", textOverflow: "ellipsis", whiteSpace: "noWrap", overflow: "hidden"}}
            />
            <CardContent className={classes.cardContent}>
              <div style={{float: "right", marginRight: "10px"}}>
                <Typography variant="h4">
                  {_.get(this.props.dish, "price", 0) ? `$${parseFloat(_.get(this.props.dish, "price", 0)).toFixed(2)}` : '$0.00'}
                </Typography>
              </div>
              <Typography variant="subheading">
                {_.get(this.props.dish, "noOrdered", 0) == 1 ? "Ordered once" : `Ordered ${_.get(this.props.dish, "noOrdered", 0)} times`}
              </Typography>
              <StarRatings
                rating={parseFloat(_.get(this.props.dish, "ratings", "0"))/(parseFloat(_.get(this.props.dish, "totalRatings", "1")))}
                starRatedColor="#ffd633"
                numberOfStars={5}
                name='rating'
                starDimension="20px"
                starSpacing="0px"
              />
              {` ${_.get(this.props.dish, "rating", "0")}`}
              <CardActions className={classes.cardActions}>
                <Button size="small" variant="contained" style={{backgroundColor: "#99cc00", width: 100}} onClick={this.postDish}>
                  {_.get(this.props.dish, "isPosted", "") ? "Unpost" : "Post"}
                </Button>
                <Button size="small" variant="contained" style={{backgroundColor: "#99cc00"}}
                  onClick={() => {
                    this.props.setDishDetails(this.props.dish)
                    document.getElementById("dishDetails").style.display = "block"
                  }}>
                  View More
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </div>
    </div>
    );
  }
}

// const condition = authUser => !!authUser;
const condition = authUser =>
  authUser && !!authUser.roles && !!authUser.roles['cook'];

Dish.propTypes = {
  classes: PropTypes.object.isRequired,
  isUpdated: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  // console.log("post dishes state", state)
  return {
    isUpdated : state.dishes.isUpdated || false,
  }
}

const mapDispatchToProps = (dispatch) => ({
  postedDish: isUpdated => dispatch({ type: 'POST_DISH', isUpdated }),
  setDishDetails: dishDetails => dispatch({ type: 'GET_DISH_DETAILS', dishDetails}),
  setPostingDish: postingDish => dispatch({ type: 'GET_POSTING_DISH', postingDish}),
  setMessage: message => dispatch({ type: 'GET_MESSAGE', message })
})


export default withStyles(styles)
(withAuthorization(condition)
  (withRouter
    (withFirebase
      (connect(mapStateToProps, mapDispatchToProps)
        (Dish)
      )
    )
  )
);
