import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withAuthorization } from '../../Session';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Card, Paper, Button, Grid, Typography, Avatar, CircularProgress } from '@material-ui/core';
import { connect }           from "react-redux"

import _ from 'lodash'
import firebase from "firebase";
import { withFirebase } from '../../Firebase';

import OrderDetails from './OrderDetails'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 2,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  avatar: {
    margin: 10,
  },
  addDish: {
     display: 'none',
     position: 'fixed',
     zIndex: 1,
     paddingTop: 10,
     paddingBottom: 10,
     left: 0,
     top: 0,
     width: '100%',
     height: '100%',
     overflow: 'auto',
     backgroundColor: 'rgba(0,0,0,0.3)',
   }
});

class PreviousOrders extends React.Component {

  constructor(props) {
    super(props)

    this.state = {loading: false}
  }

  componentDidMount() {
    if (_.size(this.props.previousOrders)) {
      this.setState({ loading: true })
    }
  }

  componentWillUpdate(nextProps, nextState) {
    // when isUpdated changed from false to true
    // if (nextProps.orderResponse != this.props.orderResponse) {
    //   // get updated list of dishes
    //   this.getOpenOrders()
    //   // set isUpdated to false
    //   this.getCurrentOrders()
    // }

    if (nextProps.orderStatus != this.props.orderStatus) {
      // this.getCurrentOrders()
      // this.getPreviousOrders()
      // console.log("previous orders called");
      return true
    }
  }

  showPreviousOrders(order) {
    var dishNo = 0
    var orderDetails = {}
    // const { classes } = this.props
    // console.log("previous orders", this.props.previousOrders);
    // return _.map(this.props.previousOrders, (order, orderId) => {
    //   console.log("order", order);
    //   dishNo = 0
      const { classes } = this.props

      return <div style={{width: "90%", margin: "5%", minHeight: 100, backgroundColor: "#E9E9E9", borderRadius: 5}}>
        <div style={{width: "90%", margin: "5%"}}>
          <Grid items container spacing={32}>

          <Grid item lg={2} style={{padding: 0}} >
            <Avatar alt="" src={_.get(this.props.kitchenDishes, `${_.first(order.dishesIds)}.avatarURL`, "undefined")}
              className={classes.avatar} style={{width: 50, maxWidth: 150, height: 50, maxHeight: 150, float: "left"}}/>
          </Grid>
          <Grid item lg={4}>
            {_.map(_.get(order, "dishes", []), dish => {
              // console.log("dishId state", dish, this.index);
              dishNo += 1
              return   <Typography variant="subtitle1" gutterBottom>
                  {`${dishNo}. ${_.get(dish, `dishName`, "undefined")}`}
                </Typography>
            })
          }
          </Grid>
            <Grid item lg={3} >
               <Typography variant="body2" gutterBottom >
                  <span style={{overflow: "hidden"}}>
                  {_.get(order, "order_time", "Time not found").substr(0, 25)}
                  </span>
               </Typography>
               <Typography variant="body2" gutterBottom>
                 Delivery Method {_.get(order, "deliveryMethods", "")}
               </Typography>
            </Grid>
            <Grid item lg={3}>

              <Typography variant="h5" gutterBottom>
               ${_.get(order, "totalAmount", 0)}
              </Typography>
              <div>
                <Button size="small" variant="contained" style={{backgroundColor: "rgb(153, 204, 0)", margin: 5}}
                  onClick={(e) => {
                    // console.log("set orderDetails", order, e);
                    // orderDetails =  _.merge({id: _.get(order, "orderId", "")}, order)
                    this.props.setOrderDetails(order)
                    document.getElementById("orderDetails").style.display = "block"
                  }}>Details</Button>
              </div>
            </Grid>
         </Grid>
       </div>
      </div>
    // })
  }

  // <AddDish isEdit={true} dish={this.props.dishDetails}/>


  render() {
    const { classes } = this.props
    return _.size(this.props.previousOrders) ?
      <div>
        <Card style={{width: "100%", height: 540, marginTop: 40}}>
            <Typography variant="h5" gutterBottom style={{marginTop: 10, marginLeft: "30%"}}>
              Previous Orders
            </Typography>
            <div style={{overflowY: "scroll", height: 470}}>
              {
                _.map(this.props.previousOrders, order => {
                  return this.showPreviousOrders(order)
                })
              }
            </div>
        </Card>
        <div id="orderDetails" className={classes.addDish}>
          <OrderDetails/>
        </div>

      </div>
    : <CircularProgress style={{marginTop: 200, marginLeft: "45%", color: "rgb(153, 204, 0)"}}/>
  }
}

const condition = authUser => authUser && !!authUser.roles && !!authUser.roles['cook'];

PreviousOrders.propTypes = {
  classes: PropTypes.object.isRequired,
  orderDetails: PropTypes.object.isRequired,
  setOrderDetails: PropTypes.func.isRequired,
  orderStatus: PropTypes.object.isRequired

};

const mapStateToProps = (state) => {
  // console.log("previousOrders state", state)
  return {
    orderDetails : state.kitchenOrder.orderDetails || {},
    orderStatus : state.kitchenOrder.orderStatus || "",
  }
}

const mapDispatchToProps = (dispatch) => ({
  setOrderDetails: orderDetails => dispatch({ type: 'GET_ORDER_DETAILS', orderDetails }),
})

export default withStyles(styles)
(withFirebase
  (withAuthorization(condition)
    (connect(mapStateToProps, mapDispatchToProps)
      (PreviousOrders)
    )
  )
)
