import React from 'react';
import firebase from "firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { withAuthorization } from '../../Session';
import { withFirebase } from '../../Firebase';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
// import axios from 'axios'
import { useAsync } from "react-async"
import SimpleImageSlider from "react-simple-image-slider";
import FileUploader from 'react-firebase-file-uploader';
import * as ROUTES from '../../../constants/routes';

// import OrdersOverTime from './OrdersOverTime'

// import MoneyOverTime from './MoneyOverTime'

// import DishPopularity from './DishPopularity'

// import RatingsReviews from './RatingsReviews'

// import FrequentCustomers from './FrequentCustomers'
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 480,
    width: "90%",
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
  margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  card: {
    height: 340,
    width: "100%"
  },
  media: {
    objectFit: 'cover',
  },
  img: {
    height: 200
  }
});

class HomePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {  kitchenName: "",
                    city: "",
                    images: [],
                    avatarURL: "",
                    avatar: "",
                    isUploading: false,
                    progress: 0,
                    kitchenId: "",
                    windowWidth: 0,
                    windowHeight: 0
                  }
  }

  componentDidMount() {
    this.getKitchenName()
    this.getImages()
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.windowWidth != this.state.windowWidth) {
      return true
    }
  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  }

  //// Image Uploader ///////

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = progress => this.setState({ progress });

  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = filename => {
    this.setState({
      avatar: filename,
      progress: 100,
      isUploading: false,
    });
    firebase
      .storage()
      .ref('kitchen_pictures/profile_pictures')
      .child(filename)
      .getDownloadURL()
      .then(url => {
        this.setState({ avatarURL: url })
        this.props.firebase.kitchen(this.state.kitchenId).update({ avatarURL: url })
      });


    // console.log("filename", filename, this.state.kitchenId, this.state.avatarURL);
    // console.log("image", _.split(filename, ".")[0]);
    var dishImage = _.split(filename, ".")
    this.setState({ avatar: filename, progress: 100, isUploading: false, image: dishImage });
    // firebase
    //   .storage()
    //   .ref("kitchen_pictures/dishes_pictures")
    //   .child(filename)
    //   .getDownloadURL()
    //   .then(url => this.setState({ avatarURL: url }));



  };

  getKitchenName = async () => {
    const kitchen = await this.props.firebase.kitchens()
                            .orderByChild('userId').equalTo(_.get(firebase.auth().currentUser, "uid", ""))
                            .limitToFirst(1).once('value');

    const kitchenValues = kitchen.val();

    // console.log("kitchen", kitchenValues, _.first(_.keys(kitchenValues)));

    this.setState({kitchenName: _.get(_.first(_.values(kitchenValues)), "kitchen_name", ""),
                    city: _.get(_.first(_.values(kitchenValues)), "city", ""),
                    avatarURL: _.get(_.first(_.values(kitchenValues)), "avatarURL", ""),
                  kitchenId: _.first(_.keys(kitchenValues)) })
  }

  getImages = async () => {
    var dishesList = []

    const dishes = await this.props.firebase.dishes()
                        .orderByChild('userId').equalTo(_.get(firebase.auth().currentUser, "uid", ""))
                        // .orderByChild('isPosted').equalTo(true)
                        .once('value');
    const dishValues = dishes.val()

    _.each(dishValues, (val, key) => {
      if (_.get(val, "isPosted", "")) {
        dishesList.push({url: _.get(val, "avatarURL", "")})
      }
    })

    this.setState({images: dishesList})
  }

  render() {
    const { classes } = this.props;

    return (
    <div style={{marginTop: 65, minHeight: 700}}>
      <div style={{width: "100%", height: 200, backgroundImage: `url(${this.state.avatarURL || "https://img.freepik.com/free-photo/board-amidst-cooking-ingredients_23-2147749529.jpg?size=626&ext=jpg"})`, objectFit: 'cover'}}>
      <br/>
        <div style={{float: "right", marginTop: 10, marginRight: 10}}>
          <label style={{color: 'yellowgreen', padding: 10, borderRadius: 4, pointer: 'cursor', borderStyle: 'solid'}}>
            Profile Picture
          <FileUploader
            hidden
            accept="image/*"
            name="avatar"
            filename={this.state.filename}
            storageRef={firebase.storage().ref("kitchen_pictures/profile_pictures")}
            onUploadStart={this.handleUploadStart}
            onUploadError={this.handleUploadError}
            onUploadSuccess={this.handleUploadSuccess}
            onProgress={this.handleProgress}
            multiple
          />
          </label>
        </div>
      <div style={{marginLeft: this.state.windowWidth < 700 ? 5 : 50, marginTop: 0, width: this.state.windowWidth < 700 ? "90%" : 520, backgroundColor: "rgba(0, 0, 0, 0.3)", padding: 20, overflow: "wrap"}}>
        <Typography variant="h5" component="h1" style={{color: "white"}}>
          {this.state.kitchenName }, {this.state.city}
        </Typography>
        <p style={{color: "white"}}>Welcome {_.get(firebase.auth().currentUser, "displayName", "")} to your kitchen.. Start sharing your tastes and share the love</p>
      </div>

      <br/>
      <Grid container spacing={32} style={{width: "95%", marginLeft: "2.5%", marginRight: "2.5%"}}>
         <Grid item lg={4} sm={6} md={4} xs={12}>
           {/* <OrdersOverTime/> */}
         </Grid>

         <Grid item lg={4} sm={6} md={4} xs={12}>
             {/* <DishPopularity/> */}
         </Grid>

        <Grid item lg={4} sm={6} md={4} xs={12}>
           {/* <MoneyOverTime /> */}
        </Grid>
       </Grid>
       <Grid container spacing={32} style={{width: "95%", marginLeft: "2.5%", marginRight: "2.5%", marginTop: 30}}>
          <Grid item lg={6} xs={12}>
            {/* <RatingsReviews/> */}
          </Grid>
          <Grid item lg={6} xs={12}>
            {/* <FrequentCustomers/> */}
          </Grid>
        </Grid>
      </div>
    </div>
  )
 }
};

// const condition = authUser => !!authUser;
const condition = authUser =>
  authUser && !!authUser.roles && !!authUser.roles['cook'];

HomePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withAuthorization(condition)(withFirebase(HomePage)));
