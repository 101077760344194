import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withAuthorization } from '../../Session';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import ProfileEdit from '@material-ui/icons/Create';

import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import { Typography, Button, TextField, Grid, CircularProgress } from '@material-ui/core';

import { AuthUserContext } from '../../Session';
import { PasswordForgetForm } from '../../PasswordForget';
import PasswordChangeForm from '../../PasswordChange';
import _ from 'lodash'

import firebase from 'firebase'
import { withFirebase } from '../../Firebase';

import FileUploader from 'react-firebase-file-uploader';

import Progress     from 'react-progressbar'

import Fab from '@material-ui/core/Fab';
import { Edit } from '@material-ui/icons'

function KitchenAccount(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>

  );
}

KitchenAccount.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    marginTop: 80,
    backgroundColor: theme.palette.background.paper,
  },
});

class ScrollableTabsButtonForce extends React.Component {
  state = {
    value: 0, currentUser: [],
    kitchenName: "", newKitchenName: "", kitchenId: "",
    kitchenStreet: "", newKitchenStreet: "",
    kitchenCity: "", newKitchenCity: "",
    kitchenCountry: "", newKitchenCountry: "",
    kitchenZipCode: "", newKitchenZipCode: "",
    editKitchenName: false, editStreet: false,
    editCity: false, editCountry: false,
    editZip: false, windowWidth: 0, windowHeight: 0, loading: false
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeKitchenName = (event) => {
    // console.log("kitchenname", event.target.value)
    this.setState({ newKitchenName: event.target.value});
  };
  handleChangeKitchenStreet = (event) => {
    // console.log("kitchen street", event.target.value);
    this.setState({ newKitchenStreet: event.target.value});
  };
  handleChangeKitchenCity = (event) => {
    this.setState({ newKitchenCity: event.target.value});
  };
  handleChangeKitchenCountry = (event) => {
    this.setState({ newKitchenCountry: event.target.value});
  };
  handleChangeKitchenZipCode = (event) => {
    this.setState({ newKitchenZipCode: event.target.value});
  };

  updateName = () => {
    if (this.state.newKitchenName) {
      this.props.firebase.kitchen(this.state.kitchenId).update({kitchen_name: this.state.newKitchenName})
    }
    this.setState({ editKitchenName: false })
  }
  updateStreet = () => {
    if (this.state.newKitchenStreet) {
      this.props.firebase.kitchen(this.state.kitchenId).update({street: this.state.newKitchenStreet})
    }
    this.setState({ editStreet: false})
  }
  updateCity = () => {
    if (this.state.newKitchenCity) {
      this.props.firebase.kitchen(this.state.kitchenId).update({city: this.state.newKitchenCity})
    }
    this.setState({ editCity: false })
  }
  updateCountry = () => {
    if (this.state.newKitchenCountry) {
      this.props.firebase.kitchen(this.state.kitchenId).update({country: this.state.newKitchenCountry})
    }
    this.setState({ editCountry: false })
  }
  updateZipCode = () => {
    if (this.state.newKitchenZipCode) {
      this.props.firebase.kitchen(this.state.kitchenId).update({zipcode: this.state.newKitchenZipCode})
    }
    this.setState({ editZip: false })
  }



  componentDidMount() {
    this.getKitchenName()
    this.getKitchenStreet()
    this.getKitchenCity()
    this.getKitchenCountry()
    this.getKitchenZipCode()
    this.getUserInfo()
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions);
    this.setState({ loading: true })
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.windowWidth != this.state.windowWidth) {
      return true
    }
  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  }

  getUserInfo() {
    // console.log("userId", _.get(firebase.auth().currentUser, "uid", ""));
    this.props.firebase.user(_.get(firebase.auth().currentUser, "uid", ""))
    .once('value', snapshot => {
      // console.log("found user", snapshot.val());
      this.setState({ currentUser : snapshot.val()})
    })


  }

  getKitchenName = async () => {
    const kitchen = await this.props.firebase.kitchens()
                            .orderByChild('userId').equalTo(_.get(firebase.auth().currentUser, "uid", ""))
                            .limitToFirst(1).once('value');

    const kitchenValues = kitchen.val();
    // console.log("kitchen", kitchen.val())

    this.setState({kitchenName: _.get(_.first(_.values(kitchenValues)), "kitchen_name", ""),
    kitchenId: _.first(_.keys(kitchenValues))})
  }

  getKitchenStreet = async () => {
    const kitchen = await this.props.firebase.kitchens()
                            .orderByChild('userId').equalTo(_.get(firebase.auth().currentUser, "uid", ""))
                            .limitToFirst(1).once('value');
    const kitchenValues = kitchen.val();
    this.setState({kitchenStreet: _.get(_.first(_.values(kitchenValues)), "street", ""),
    kitchenId: _.first(_.keys(kitchenValues))})
  }

  getKitchenCity = async () => {
    const kitchen = await this.props.firebase.kitchens()
                            .orderByChild('userId').equalTo(_.get(firebase.auth().currentUser, "uid", ""))
                            .limitToFirst(1).once('value');
    const kitchenValues = kitchen.val();
    this.setState({kitchenCity: _.get(_.first(_.values(kitchenValues)), "city", ""),
    kitchenId: _.first(_.keys(kitchenValues))})
  }

  getKitchenCountry = async () => {
    const kitchen = await this.props.firebase.kitchens()
                            .orderByChild('userId').equalTo(_.get(firebase.auth().currentUser, "uid", ""))
                            .limitToFirst(1).once('value');
    const kitchenValues = kitchen.val();
    this.setState({kitchenCountry: _.get(_.first(_.values(kitchenValues)), "country", ""),
    kitchenId: _.first(_.keys(kitchenValues))})
  }

  getKitchenZipCode = async () => {
    const kitchen = await this.props.firebase.kitchens()
                            .orderByChild('userId').equalTo(_.get(firebase.auth().currentUser, "uid", ""))
                            .limitToFirst(1).once('value');
    const kitchenValues = kitchen.val();
    this.setState({kitchenZipCode: _.get(_.first(_.values(kitchenValues)), "zipcode", ""),
    kitchenId: _.first(_.keys(kitchenValues))})
  }

  toggleElement(hideDivId, showDivId) {
    if (document.getElementById(hideDivId) && document.getElementById(showDivId)) {
      document.getElementById(hideDivId).style.display = "none"
      document.getElementById(showDivId).style.display = "block"
    }
  }

  showProfile() {
    const {classes} = this.props
    return <div style={{marginLeft: this.state.windowWidth < 700 ? "5%" : "15%", width: this.state.windowWidth < 700 ? "90%" : "70%", marginRight: this.state.windowWidth < 700 ? "5%" : "15%"}}>
      <Typography variant="h3" gutterBottom style={{marginTop: 30}}>
        Profile
      </Typography>
      <br/>
      <Grid items container spacing={32}>
        <Grid item md={6} xs={12}>
          <Typography variant="display1" gutterBottom style={{fontSize: 24}}>
            Name: {_.get(this.state.currentUser, "first_name", "")} {_.get(this.state.currentUser, "last_name", "")}
          </Typography>
          <br/>
          <Typography variant="display1" gutterBottom style={{fontSize: 24}}>
            Email: {_.get(firebase.auth().currentUser, "email", "email not found")}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <PasswordChangeForm />
          <Button size="small" id="btnChangePassword" style={{backgroundColor: "#9BD238", marginTop: 30}}
            onClick={() => {
              document.getElementById("changePassword").style.display = "block"
              document.getElementById("btnChangePassword").style.display = "none"
            }}>Change Password</Button>
          <br/>
        </Grid>
        <Grid item md={6} xs={12}>
            <TextField
              id="standard-name"
              label="Kitchen Name"
              className={classes.textField}
              value={this.state.newKitchenName || this.state.kitchenName}
              onChange={this.handleChangeKitchenName}
              margin="normal"
              disabled={!this.state.editKitchenName}
              InputProps={{
                  style: {
                    color: "orange"
                  }
              }}
              style = {{width: "80%"}}
            />
            <Fab size="small" aria-label="Edit" className={classes.fab}
              style={{marginLeft: 20, marginTop: 20, float: "right", display: this.state.editKitchenName ? "none" : "block"}}
              onClick={() => this.setState({ editKitchenName: true })}>
              <Edit style={{color: "rgb(241, 119, 33)"}}/>
            </Fab>
            <Button size="small" style={{backgroundColor: "#9BD238", float: "right", marginTop: 20, display: !this.state.editKitchenName ? "none" : "block"}}
              onClick={this.updateName} >Save</Button>
        </Grid>
        <Grid item md={6} xs={12}>
            <TextField
            id="standard-name"
            label="Street and house number"
            className={classes.textField}
            value={this.state.newKitchenStreet || this.state.kitchenStreet}
            onChange={this.handleChangeKitchenStreet}
            margin="normal"
            disabled={!this.state.editStreet}
            InputProps={{
                style: {
                  color: "orange"
                }
            }}

            style = {{width: "80%"}}
          />
          <Fab size="small" aria-label="Edit" className={classes.fab}
            style={{marginLeft: 20, marginTop: 20, float: "right", display: this.state.editStreet ? "none" : "block"}}
            onClick={() => this.setState({ editStreet: true})}>
            <Edit style={{color: "rgb(241, 119, 33)"}}/>
          </Fab>
          <Button size="small" style={{backgroundColor: "#9BD238", float: "right",  marginTop: 20, display: !this.state.editStreet ? "none" : "block"}}
            onClick={this.updateStreet}>Save</Button>
        </Grid>
        <Grid item md={6} xs={12}>
            <br/>
            <TextField
            id="standard-name"
            label="City"
            className={classes.textField}
            value={this.state.newKitchenCity || this.state.kitchenCity}
            onChange={this.handleChangeKitchenCity}
            margin="normal"
            disabled={!this.state.editCity}
            InputProps={{
                style: {
                  color: "orange"
                }
            }}
            style = {{width: "80%"}}
          />
          <Fab size="small" aria-label="Edit" className={classes.fab}
            style={{marginLeft: 20, marginTop: 20, float: "right", display: this.state.editCity ? "none" : "block"}}
            onClick={() => this.setState({ editCity: true})}>
            <Edit style={{color: "rgb(241, 119, 33)"}}/>
          </Fab>
          <Button size="small" style={{backgroundColor: "#9BD238", float: "right",  marginTop: 20, display: !this.state.editCity ? "none" : "block"}}
            onClick={this.updateCity}>Save</Button>

        </Grid>
        <Grid item md={6} xs={12}>
            <TextField
            id="standard-name"
            label="Country"
            className={classes.textField}
            value={this.state.newKitchenCountry || this.state.kitchenCountry}
            onChange={this.handleChangeKitchenCountry}
            margin="normal"
            disabled={!this.state.editCountry}
            InputProps={{
                style: {
                  color: "orange"
                }
            }}
            style = {{width: "80%"}}
          />
          <Fab size="small" aria-label="Edit" className={classes.fab}
            style={{marginLeft: 20, marginTop: 20, float: "right", display: this.state.editCountry ? "none" : "block"}}
            onClick={() => this.setState({ editCountry: true})}>
            <Edit style={{color: "rgb(241, 119, 33)"}}/>
          </Fab>
          <Button size="small" style={{backgroundColor: "#9BD238", float: "right",  marginTop: 10, display: !this.state.editCountry ? "none" : "block"}}
            onClick={this.updateCountry}>Save</Button>
        </Grid>
        <Grid item md={6} xs={12}>
            <TextField
            id="standard-name"
            label="ZipCode"
            className={classes.textField}
            value={this.state.newKitchenZipCode || this.state.kitchenZipCode}
            onChange={this.handleChangeKitchenZipCode}
            margin="normal"
            disabled={!this.state.editZip}
            InputProps={{
                style: {
                  color: "orange"
                }
            }}
            style = {{width: "80%"}}
          />
          <Fab size="small" aria-label="Edit" className={classes.fab}
            style={{marginLeft: 10, marginTop: 20, float: "right", display: this.state.editZip ? "none" : "block"}}
            onClick={() => this.setState({ editZip: true})}>
            <Edit style={{color: "rgb(241, 119, 33)"}}/>
          </Fab>
          <Button size="small" style={{backgroundColor: "#9BD238", float: "right",  marginTop: 20, float: "right", display: !this.state.editZip ? "none" : "block"}}
            onClick={this.updateZipCode}>Save</Button>
        </Grid>
    </Grid>
    </div>
  }



  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return this.state.loading ?
      <div className={classes.root}>
        {this.showProfile()}
      </div> : <CircularProgress color="secondary"/>
  }
}

const condition = authUser =>
  authUser && !!authUser.roles['cook'];

ScrollableTabsButtonForce.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withAuthorization(condition)(ScrollableTabsButtonForce));
