// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import Stepper from '@material-ui/core/Stepper';
// import Step from '@material-ui/core/Step';
// import StepButton from '@material-ui/core/StepButton';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
//
// const styles = theme => ({
//   root: {
//     width: '90%',
//   },
//   button: {
//     // marginRight: theme.spacing(1),
//   },
//   completed: {
//     display: 'inline-block',
//   },
//   instructions: {
//     // marginTop: theme.spacing(1),
//     // marginBottom: theme.spacing(1),
//   },
// });
//
// function getSteps() {
//   return ['Select campaign settings', 'Create an ad group', 'Create an ad'];
// }
//
// function getStepContent(step) {
//   switch (step) {
//     case 0:
//       return 'Step 1: Select campaign settings...';
//     case 1:
//       return 'Step 2: What is an ad group anyways?';
//     case 2:
//       return 'Step 3: This is the bit I really care about!';
//     default:
//       return 'Unknown step';
//   }
// }
//
// function HorizontalNonLinearStepper() {
//   const classes = styles();
//   const [activeStep, setActiveStep] = React.useState(0);
//   const [completed, setCompleted] = React.useState({});
//   const steps = getSteps();
//
//   function totalSteps() {
//     return steps.length;
//   }
//
//   function completedSteps() {
//     return Object.keys(completed).length;
//   }
//
//   function isLastStep() {
//     return activeStep === totalSteps() - 1;
//   }
//
//   function allStepsCompleted() {
//     return completedSteps() === totalSteps();
//   }
//
//   function handleNext() {
//     const newActiveStep =
//       isLastStep() && !allStepsCompleted()
//         ? // It's the last step, but not all steps have been completed,
//           // find the first step that has been completed
//           steps.findIndex((step, i) => !(i in completed))
//         : activeStep + 1;
//     setActiveStep(newActiveStep);
//   }
//
//   function handleBack() {
//     setActiveStep(prevActiveStep => prevActiveStep - 1);
//   }
//
//   const handleStep = step => () => {
//     setActiveStep(step);
//   };
//
//   function handleComplete() {
//     const newCompleted = completed;
//     newCompleted[activeStep] = true;
//     setCompleted(newCompleted);
//     handleNext();
//   }
//
//   function handleReset() {
//     setActiveStep(0);
//     setCompleted({});
//   }
//
//   // <Step key={label}>
//   //   <StepButton onClick={handleStep(index)} completed={completed[index]}>
//   //     {label}
//   //   </StepButton>
//   // </Step>
//
//   // <div key={label} style={{width: 50, height: 50, backgroundColor: "blue"}}>
//   //
//   // </div>
//   return (
//     <div className={classes.root}>
//       <Stepper nonLinear activeStep={activeStep}>
//         {steps.map((label, index) => (
//
//           <Step key={label} size={30}>
//             <StepButton onClick={handleStep(index)} completed={completed[index]}>
//               {label}
//             </StepButton>
//           </Step>
//         ))}
//       </Stepper>
//       <div>
//         {allStepsCompleted() ? (
//           <div>
//             <Typography className={classes.instructions}>
//               All steps completed - you&apos;re finished
//             </Typography>
//             <Button onClick={handleReset}>Reset</Button>
//           </div>
//         ) : (
//           <div>
//             <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
//             <div>
//               <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
//                 Back
//               </Button>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={handleNext}
//                 className={classes.button}
//               >
//                 Next
//               </Button>
//               {activeStep !== steps.length &&
//                 (completed[activeStep] ? (
//                   <Typography variant="caption" className={classes.completed}>
//                     Step {activeStep + 1} already completed
//                   </Typography>
//                 ) : (
//                   <Button variant="contained" color="primary" onClick={handleComplete}>
//                     {completedSteps() === totalSteps() - 1 ? 'Finish' : 'Complete Step'}
//                   </Button>
//                 ))}
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }
//
// export default HorizontalNonLinearStepper;


import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withAuthorization } from '../../Session';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Card, Paper, Button, Grid } from '@material-ui/core';
import { connect } from 'react-redux'
import _ from 'lodash'
import firebase from "firebase";
import { withFirebase } from '../../Firebase';
import { ArrowRightAltOutlined } from '@material-ui/icons'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 2,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  stepUncomplete: {
    backgroundColor: "white"
  },
  stepCompleted: {
    backgroundColor: "black"
  }
});

const stepUncompleted = {
  width: 80,
  height: 80,
  borderRadius: 43,
  borderStyle: "solid",
  borderColor: "#FD9A37",
  borderWidth: 1,
  backgroundColor: "white",
  fontSize: 14
}

const stepCompleted = {
  width: 75,
  height: 75,
  borderRadius: 40,
  borderStyle: "solid",
  borderColor: "#FD9A37",
  borderWidth: 5,
  backgroundColor: "rgb(153, 204, 0)",
  boxShadow: 10,
  fontSize: 12
}

class Stepper extends React.Component {

  state = {cooked: false, ready: false, delivered: false}

  componentDidMount() {
    if (this.props.status == "cooked") {
      this.setState({cooked: true, ready: false, delivered: false})
    } else if (this.props.status == "dish_ready") {
      this.setState({cooked: true, ready: true, delivered: false})
    } else if (this.props.status == "accepted") {
      this.setState({cooked: false, ready: false, delivered: false})
    }
  }

  declineAcceptOrder(key, action) {
    // console.log("key", key)
    this.props.firebase.order(key).update({status: action})
    .then(ord => {
      // console.log("order ", ord, action);
      if (action === "delivered") {
        this.props.setOrderStatus(!this.props.orderStatus)
      }
    })
    .catch(err => console.log("error accepting or declining", err))
  }

  render() {
    const { classes } = this.props

    return (
      <div>
        <Grid items container spacing={32}>
          <Grid item lg={3} xs={3} style={{marginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 0}}>
            <div>
              <Button size="small" style={this.state.cooked ? stepCompleted : stepUncompleted}
                onClick={() => {
                  // console.log("clicked button", this.state.cooked, this);
                  if (this.state.cooked) {
                    this.setState({cooked: false, ready: false, delivered: false})
                    this.declineAcceptOrder(this.props.orderId, "accepted")

                  } else {
                    this.setState({cooked: true})
                    this.declineAcceptOrder(this.props.orderId, "cooked")
                  }
                }}>Cooking</Button>
            </div>

          </Grid>
          <Grid  item lg={1} xs={1} style={{marginLeft: 0, marginRight: 0, paddingLeft: 5, paddingRight: 0}}>
            <ArrowRightAltOutlined style={{color: this.state.cooked ? "rgb(153, 204, 0)" : "#FFCF9D", height: 27, width: 27, marginTop: 25}}/>
          </Grid>

          <Grid item lg={3} xs={3} style={{paddingRight: 0, marginRight: 0, paddingLeft: 10}}>
            <div>
            <Button  size="small" disabled={!this.state.cooked} style={this.state.ready ? stepCompleted : stepUncompleted}
              onClick={() => {
                if (this.state.ready) {
                  this.setState({ready: false, delivered: false})
                  this.declineAcceptOrder(this.props.orderId, "cooked")

                } else {
                  this.setState({ready: true})
                  this.declineAcceptOrder(this.props.orderId, "dish_ready")
                }
              }}>Ready</Button>
              </div>
          </Grid>
          <Grid  item lg={1} xs={1} style={{marginLeft: 0, marginRight: 0, paddingLeft: 15, paddingRight: 0}}>
            <ArrowRightAltOutlined style={{color: this.state.ready ? "rgb(153, 204, 0)" : "#FFCF9D", height: 27, width: 27, marginTop: 25}}/>
          </Grid>
          <Grid item lg={3} xs={3} style={{marginLeft: 0, marginRight: 0, paddingLeft: 20, paddingRight: 0}}>
            <div>

              <Button size="small" disabled={!this.state.ready} style={this.state.delivered ? stepCompleted : stepUncompleted}
                onClick={() => {
                  if (this.state.delivered) {
                    this.setState({delivered: false})
                    this.declineAcceptOrder(this.props.orderId, "dish_ready")

                  } else {
                    this.setState({delivered: true})
                    this.props.setOrderStatus("delivered")
                    this.declineAcceptOrder(this.props.orderId, "delivered")
                  }
                }}>{this.props.deliveryMethods == "Kitchen Delivery" ? "Delivered" : "Picked-up"}</Button>
              </div>
          </Grid>

        </Grid>
      </div>
    )
  }
}

const condition = authUser => authUser;


Stepper.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  // console.log("previousOrders state", state)
  return {
    orderStatus : state.kitchenOrder.orderStatus || {},
  }
}

const mapDispatchToProps = (dispatch) => ({
  setOrderStatus: orderStatus => dispatch({ type: 'GET_ORDER_RESPONSE', orderStatus }),
})

export default withStyles(styles)
(withFirebase
  (withAuthorization
    (condition)
    (connect(mapStateToProps, mapDispatchToProps)
      (Stepper)
    )
  )
)
