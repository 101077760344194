import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, withTheme, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import  { Card, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import _ from 'lodash'
import PendingOrders from './PendingOrders'
import CurrentOrders from './CurrentOrders'
function TabContainer({ children, dir }) {
  return (
    <Card style={{width: "90%"}}>
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
    </Card>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = theme => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
});

// class CurrentOrders extends React.Component {
//
//   state = {
//     value: 0
//   }
//
//
//   handleChange(event, newValue) {
//     // setValue(newValue);
//
//     this.setState({value: newValue})
//   }
//
//   handleChangeIndex(index) {
//     // setValue(index);
//     this.setState({value: index})
//   }
//
//   render() {
//     const { classes } = this.props;
//     // console.log("theme", useTheme());
//
//     const   { theme } = useTheme();
//     console.log("theme", theme);
//     // const [value, setValue] = this.useState(0);
//
//
//
//     return (
//       <div className={classes.root}>
//       <AppBar position="static" color="default">
//         <Tabs
//           value={this.state.value}
//           onChange={this.handleChange}
//           indicatorColor="primary"
//           textColor="primary"
//           variant="scrollable"
//           scrollButtons="auto"
//         >
//           <Tab label="Item One" />
//           <Tab label="Item Two" />
//         </Tabs>
//       </AppBar>
//       {this.state.value === 0 && <TabContainer>Item One</TabContainer>}
//       {this.state.value === 1 && <TabContainer>Item Two</TabContainer>}
//     </div>
//     );
//   }
//
// }

class OrdersTabs extends React.Component {
  constructor() {
    super()

    this.state = { value: 0 }


    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(event, newValue) {
    // console.log("newValue", newValue);
    this.setState({value: newValue})
  }

  componentDidMount() {
    // console.log("pending orders", this.props.pendingOrders, _.size(this.props.pendingOrders));
    // if (!_.size(this.props.pendingOrders)) {
    //   this.setState({value: 1})
    // } else {
    //   this.setState({value: 0})
    // }
  }

  render() {
  const { classes } = this.props;
  // const [value, setValue] = React.useState(0);


  return (
    <div className={classes.root} style={{backgroundColor: "white"}}>
      <div style={{ width: "94%", height: 500, margin: "2%", marginLeft: 20, paddingTop: "2%", paddingBottom: "2%", backgroundColor: "white"}}>
        <AppBar position="static" color="#C4C4C4" style={{backgroundColor: "white", boxShadow: "none"}}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="white"
            textColor="primary"
          >
            <Tab label="Pending Orders" style={{backgroundColor: this.state.value === 0 ? "#E9E9E9" : "white", borderRadius: "5px 5px 0px 0px"}}/>
            <Tab label="Current Orders" style={{backgroundColor: this.state.value === 0 ? "white" : "#E9E9E9", borderRadius: "5px 5px 0px 0px"}}/>
          </Tabs>
        </AppBar>
        {this.state.value === 0 && <PendingOrders orders={this.props.pendingOrders} kitchenDishes={this.props.kitchenDishes}/>}
        {this.state.value === 1 && <CurrentOrders orders={this.props.currentOrders} kitchenDishes={this.props.kitchenDishes}/>}
      </div>
    </div>
  );
}
}

OrdersTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrdersTabs);
