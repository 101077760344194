const INITIAL_STATE = {
  selectedDishes: null,
  paymentStatus: null,
  paymentMethod: null,
  review: null,
  deliveryMethod: null,
  categoriesMethods: null,
  restrictionsMethods: null,
  totalAmount: null,
  currentOrder: null,
  deliveryAddress: null
};

const getSelectedDishes = (state, action) => ({
  ...state,
  selectedDishes: action.selectedDishes,
});

const setPaymentStatus = (state, action) => ({
  ...state,
  paymentStatus: action.paymentStatus
})

const setPaymentMethod = (state, action) => ({
  ...state,
  paymentMethod: action.paymentMethod
})

const setCurrentOrder = (state, action) => ({
  ...state,
  currentOrder: action.currentOrder
})

const setReview = (state, action) => ({
  ...state,
  review: action.review
})

const getDeliveryMethods = (state, action) => ({
  ...state,
  deliveryMethod: action.deliveryMethod,
});
const getCategoriesMethods = (state, action) => ({
  ...state,
  categoriesMethods: action.categoriesMethods,
});
const getRestrictionsMethods = (state, action) => ({
  ...state,
  restrictionsMethods: action.restrictionsMethods,
});

const getDeliveryAddress= (state, action) => ({
  ...state,
  deliveryAddress: action.deliveryAddress,
});

const getTotalAmount = (state, action) => ({
  ...state,
  totalAmount: action.totalAmount,
});

function clientOrderReducer(state = INITIAL_STATE, action) {
  console.log("action", action);
  switch (action.type) {
    case 'GET_SELECTED_DISHES': {
      return getSelectedDishes(state, action);
    }
    case 'GET_PAYMENT_STATUS': {
      return setPaymentStatus(state, action);
    }
    case 'GET_PAYMENT_METHOD': {
      return setPaymentMethod(state, action);
    }
    case 'GET_CURRENT_ORDER': {
      return setCurrentOrder(state, action);
    }
    case 'GET_REVIEW': {
      return setReview(state, action);
    }
    case 'GET_DELIVERY_METHODS': {
      return getDeliveryMethods(state, action);
    }
    case 'GET_DELIVERY_ADDRESS': {
      return getDeliveryAddress(state, action);
    }
    case 'GET_GATEGORIES_METHODS': {
      return getCategoriesMethods(state, action);
    }
    case 'GET_RESTRICTIONS_METHODS': {
      return getRestrictionsMethods(state, action);
    }
    case 'GET_TOTAL_AMOUNT': {
      return getTotalAmount(state, action);
    }
    default:
      return state;
  }
}

export default clientOrderReducer;
