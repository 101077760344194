import React from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';

import * as ROUTES from '../../constants/routes';

import MenuItem from '@material-ui/core/MenuItem';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';

import {ListItemIcon, ListItemText, Button, IconButton} from "@material-ui/core"

const SignOutButton = ({ firebase }) => (
  <MenuItem component={Link} to="/" onClick={firebase.doSignOut}>

      <IconButton
        aria-label="Account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        color="inherit"
      >
        <PowerSettingsNew/>
      </IconButton>
      <p>Logout</p>
</MenuItem>
);

// <ListItemIcon>
//   <PowerSettingsNew/>
// </ListItemIcon>
// <ListItemText className="pl-0" primary="Logout" />

const SignOut = ({firebase}) => (
  <Button color="inherit">
    <Link to={"/"} style={{textDecoration: "none", color: "white"}} onClick={firebase.doSignOut}> Logout</Link>
  </Button>
)

const SignOutBtn = compose(
  withFirebase,
)(SignOut);

export {SignOutBtn}
export default withFirebase(SignOutButton);
