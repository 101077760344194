import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import { Typography, Button, Grid } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Logo from '../../static/images/logo.svg'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
  {
    label: 'Share your dishes with your neighbors',
    btnLabel: 'Build Kitchen',
    route: '/trade-main',
    btnColor: "rgb(241, 119, 33)",
    textColor: "#90c43b",
    imgPath:
      'https://frontiersinblog.files.wordpress.com/2017/07/frontiers-in-ict-food-bridging.jpg',
  },
  {
    label: 'Find dishes cooked by your neighbors',
    btnLabel: 'Find Food',
    route: '/taste',
    btnColor: "#90c43b",
    textColor: "rgb(241, 119, 33)",
    imgPath:
      'https://www.onlinewerkrooster.be/Blog/image.axd?picture=/Depositphotos_108946720_m-2015.jpg',
  }
];

const styles = theme => ({
  root: {
    width: "100%",
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: 4,
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    display: 'block',
    maxWidth: "100%",
    overflow: 'hidden',
    width: '100%',
  },
});

class SwipeableTextMobileStepper extends React.Component {

  state = {rating: 0, prevActiveStep: 0, step: 0, windowWidth: window.innerWidth, windowHeight: window.innerHeight}
  handleNext = () => {
    this.setState({prevActiveStep: this.state.prevActiveStep + 1 });
  }

  handleBack = () => {
    this.setState({prevActiveStep: this.state.prevActiveStep - 1});
  }

  handleStepChange = step => {
    this.setState({step: step});
  }

  componentDidMount() {
    console.log('width',  this.state.windowWidth);
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.windowWidth != this.state.windowWidth) {
      return true
    }
  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  }

  render () {

    const classes = this.props;
    // const theme = useTheme();
    const {step} = this.state;
    const maxSteps = tutorialSteps.length;
    const activeStep = step

    return (
      <div className={classes.root} style={{position: "fixed", width: this.state.windowWidth}}>
        <AutoPlaySwipeableViews
          axis={'x'}
          index={activeStep}
          onChangeIndex={this.handleStepChange}
          enableMouseEvents
        >
          {tutorialSteps.map((step, index) => (
            <div id="stepper" key={step.label} style={{width: this.state.windowWidth, height: 600, backgroundImage: `url(${step.imgPath})`, backgroundSize: "cover"}}>
              {Math.abs(activeStep - index) <= 2 ? (
                <div style={{width: this.state.windowWidth, height: 600, backgroundColor: "rgb(179, 71, 0, 0.0)", backgroundImage: 'linear-gradient(to bottom right, rgb(0, 0, 0, 0.4), rgb(0, 0, 0, 0.4))'}}>
                  <Grid items container spacing={32} style={{padding: 0, margin: 0}}>
                    <Grid item lg={6} sm={6} xs={12} style={{height: this.state.windowWidth <= 600 ? 300 : 600, padding: 0, margin: 0}}>
                      <div style={{marginLeft: "7%", width: "80%", marginTop: this.state.windowWidth <= 600 ? "10%" : "20%", backgroundColor: "rgb(0, 0, 0, 0.4)"}}>
                        <Typography variant="display1" align="center" style={{fontSize: this.state.windowWidth <= 700 ? 50 : 70, color: step.textColor}}>
                          {step.label}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item lg={6} sm={6} xs={12} style={{height: this.state.windowWidth <= 600 ? 350 : 600, padding: 0, margin: 0}}>
                      <img src={Logo} style={{width: "100%", height: this.state.windowWidth <= 600 ? 200 : 300, marginTop: this.state.windowWidth <= 600 ? 0 : 100}}/>
                      <Button size={this.state.windowWidth <= 600 ? "medium" : "large"}
                        style={{backgroundColor: step.btnColor, marginTop: 10,
                                marginLeft: "7%", padding: this.state.windowWidth <= 750 ? 20 : 30,
                                fontColor: "black", fontSize: this.state.windowWidth <= 750 ? 20 : 30,
                                width: "80%"}}>

                        <Link to={step.route} style={{textDecoration: "none", color: "black"}}>
                          {step.btnLabel}
                        </Link>
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
      </div>
    );
  }
}

SwipeableTextMobileStepper.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SwipeableTextMobileStepper);
