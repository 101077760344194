import React, { Component } from 'react';
import GetAppCard from './GetAppCard';
import getApp from '../getApp.css'
import chefdata from '../data/chefdata'
import Iphone from '../iphone.png'
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Arrow from "./Arrow";
import MobileStoreButton from 'react-mobile-store-button';
import Grid from '@material-ui/core/Grid'
import { Hidden } from '@material-ui/core';
import appStore from '../images/appstore.png'
import googlePlay from '../images/googlePlay.png'



class testimonials extends Component {

  constructor(props){
    super(props);
    this.state = {
      properties: chefdata.properties,
      property: chefdata.properties[2]
    }
  }
  

  nextProperty = () => {
    const newIndex = this.state.property.index+1;
    this.setState({
      property: chefdata.properties[newIndex]
    })
  }

  prevProperty = () => {
    const newIndex = this.state.property.index-1;
    this.setState({
      property: chefdata.properties[newIndex]
    })
  }

  render() {
    const {properties, property} = this.state;
    const iOSUrl = 'https://apps.apple.com/us/app/tasteswap-chef/id1558336648';
    const androidUrl =  '';

    return (
      

      <div id="getApp" style={{position: "relative"}} className="getApp">
      <div className="section-title1" style={{textAlign: "center"}}>
        <h2>TasteSwap Chef App</h2>
      </div>        
        <div className="page" style={{height: 700}}>
       
            <Grid container spacing={32}>
              <Grid item xs={6} style={{maxHeight: 100}}>
              {/* <MobileStoreButton
            store="ios"
            url={iOSUrl}
            linkProps={{ title: 'iOS Store Button' }}
            style={{width: 170, marginTop: 12, float: "right"}}
            /> */}
              <a href={'https://apps.apple.com/us/app/tasteswap-chef/id1558336648'} target="_blank" style={{float: "right", background: "white", borderColor: "none", borderStyle: "none"}}  >
              <img  style={{width: 170, marginTop: 12, height: 60}} 
              src={appStore} alt="my image"  
              />
             
              </a>
              </Grid>
              <Grid item xs={6} style={{maxHeight: 100}}>
              {/* <MobileStoreButton
            store="android"
            url={androidUrl}
            linkProps={{ title: 'Android Store Button' }}
            /> */}
              <a href={''} target="_blank" style={{float: "left", background: "white", borderColor: "none", borderStyle: "none"}}  >
              <img  style={{width: 200, marginTop: -2, height: 90}} 
              src={googlePlay} alt="my image"  
              />
             
              </a>

              </Grid>
              <Grid item xs={2} md={1}>
            
              <Arrow
                    onClick={() => this.prevProperty()} 
                    disabled={property.index === 0}
                    direction="left"
                    style={{color: "black", marginTop: 250}}
                  />
              </Grid>
              <Grid item xs={8} md={10} >
              <div className="col" style={{zIndex: -1000, marginLeft: -75}}>
              <div className={`cards-slider active-slide-${property.index}`}>
                <div className="cards-slider-wrapper" style={{
                  'transform': `translateX(-${property.index*(100/properties.length)}%)`
                }}> 
                
                  {
      
                    properties.map(property => 
                      
                    <GetAppCard key={property._id} property={property} />
                    )                    
                  }
                </div>

              </div>
            </div>
              </Grid>
              <Grid item xs={2} md={1}>
              <Arrow
                onClick={() => this.nextProperty()}  
                disabled={property.index === chefdata.properties.length-1}
                direction="right"
                style={{color: "black", marginTop: 250, alignItems: "flex-end", float: "right"}}

              />
              </Grid>

            </Grid>
            
        </div>
    
      </div>
    );
  }
}


{/* <button 
onClick={() => this.nextProperty()} 
disabled={property.index === data.properties.length-1}
>Next</button>
<button 
onClick={() => this.prevProperty()} 
disabled={property.index === 0}
>Prev</button> */}

export default testimonials;