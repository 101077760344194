import React from 'react';
import PropTypes from 'prop-types';
import { connect }           from "react-redux"

import { withStyles } from '@material-ui/core/styles';
import { withAuthorization } from '../../Session';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Card, Paper, Button, Grid, Typography, CircularProgress } from '@material-ui/core';
import { useAsync }          from "react-async"

import _ from 'lodash'
import firebase from "firebase";
import { withFirebase } from '../../Firebase';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 2,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

class PendingOrders extends React.Component {

  state = {dishId: "", loading: false}

  componentWillMount() {
    // if (_.size(this.props.orders)) {
    //   this.setState({ loading : true })
    // }
  }

  componentDidMount() {
    this.getDishName()
    // this.setState({ loading : true })
  }

  // componentDidUpdate() {
  //
  // }

  componentWillUpdate(nextProps, nextState) {
    // when isUpdated changed from false to true
    if (nextProps.orderResponse != this.props.orderResponse) {
      // get updated list of dishes
      // this.getOpenOrders()
      // // set isUpdated to false
      // this.getCurrentOrders()
      return true
    }

  }


  getDishName =  async () => {
    // console.log("getting dish name", this.state.dishId, this.props.dishes);
    var dishName = ""
    const dish = await this.props.firebase.dish("-LfiCMqnwsMwD1ObAAbK")
                          // .orderByChild('id').equalTo(this.state.dishId)
                          // .limitToFirst(1)
                          .once('value');
    //
    // console.log("dishes", dish.val());
    return _.get(dish.val(), "dishName", "Undefined")

  }

  declineAcceptOrder(key, action) {
    // console.log("key", key, this.props.orders)
    this.props.firebase.order(key).update({status: action})
    .then(ord => {
      // console.log("order ", ord, action);
      this.props.setOrderResponse(!this.props.orderResponse)
    })
    .catch(err => console.log("error accepting or declining", err))
  }

  showOrder() {
    var dishNo = 0

    return _.size(this.props.orders) ? _.map(this.props.orders, (order) => {
      // console.log("pending orders", order);
      dishNo = 0
      return <div style={{width: "90%", margin: "5%", minHeight: 100, backgroundColor: "white", borderRadius: 5}}>
        <div style={{width: "90%", margin: "5%"}}>
          <Grid items container spacing={32}>
            <Grid item lg={4} sm={4} xs={12}>
              {_.map(_.get(order, "dishes", []), dish => {
                // console.log("dish state", dish, this.index);
                dishNo += 1
                return   <Typography variant="subtitle1" gutterBottom>
                    {`${dishNo}.
                      ${_.get(dish, 'dishName', "undefined")}
                      ${_.get(dish, 'orderServings', "")}
                      ${_.get(dish, 'orderNote', "") ? `(${_.get(dish, 'orderNote', "note: ")})` : ``}`}
                  </Typography>
              })
            }
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
               <Typography variant="subtitle1" gutterBottom >
                  <span style={{overflow: "hidden"}}>
                  {_.get(order, "order_time", "Time not found").substr(0, 25)}
                  </span>
               </Typography>
               <Typography variant="subtitle1" gutterBottom>
                 Delivery Method: {_.get(order, "deliveryMethods", "")}
               </Typography>
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>

              <Typography variant="h5" gutterBottom>
               ${parseFloat(_.get(order, "totalAmount", 0)).toFixed(2)}
              </Typography>
              <div>
                <Button size="small" variant="contained" style={{backgroundColor: "red", margin: 5}}
                onClick={() => {
                  // this.props.setOrderResponse("declined")
                  this.declineAcceptOrder(_.get(order, "orderId", ""), "declined")
                }}>Decline</Button>
                <Button size="small" variant="contained" style={{backgroundColor: "rgb(153, 204, 0)",  margin: 5}}
                onClick={() => {
                  // this.props.setOrderResponse(true)
                  this.declineAcceptOrder(_.get(order, "orderId", ""), "accepted")
                }}>Accept</Button>
              </div>
            </Grid>
         </Grid>
       </div>
      </div>
    }) : this.state.loading ?
            <div style={{marginTop: 200, marginLeft: "30%"}}> <h3>No Pending Orders</h3></div>
            : <CircularProgress style={{marginTop: 200, marginLeft: "45%", color: "rgb(153, 204, 0)"}}/>
  }

  render() {
    return (
      <div>
        <Card style={{width: "100%", height: 500, backgroundColor: "#E9E9E9"}}>
          <div style={{overflowY: "scroll", height: 460, marginBottom: 10}}>
            {this.showOrder()}
          </div>
        </Card>
      </div>
    )
  }
}

PendingOrders.propTypes = {
  classes: PropTypes.object.isRequired,
  // dishes: PropTypes.array.isRequired,
  // isUpdated: PropTypes.bool.isRequired,
  // dishDetails: PropTypes.object.isRequired,
  // editDish: PropTypes.object.isRequired
};

// condition checks if user is cook
const condition = authUser => authUser && !!authUser.roles && !!authUser.roles['cook'];


const mapStateToProps = (state) => {
  // console.log("previousOrders state", state)
  return {
    orderDetails : state.kitchenOrder.orderDetails || {},
    orderResponse : state.kitchenOrder.orderResponse || null,
    orderStatus : state.kitchenOrder.orderStatus || "",

  }
}

const mapDispatchToProps = (dispatch) => ({
  setOrderDetails: orderDetails => dispatch({ type: 'GET_ORDER_DETAILS', orderDetails }),
  setOrderResponse: orderResponse => dispatch({ type: 'GET_ORDER_RESPONSE', orderResponse }),
  setMessage: message => dispatch({ type: 'GET_MESSAGE', message })
})

export default withStyles(styles)
(connect
  (mapStateToProps, mapDispatchToProps)
    (withFirebase
      (withAuthorization(condition)
        (PendingOrders)
      )
    )
)
