import React from 'react'
import PropTypes from 'prop-types';
import { connect }           from "react-redux"
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { FormLabel, CircularProgress } from '@material-ui/core';
import Checkout from './Checkout'
import _ from 'lodash'
import { withFirebase } from '../../Firebase';
import { useAsync } from "react-async"

const styles = theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
});

class ConfirmAndPlace extends React.Component {

  state = {
    value: 'cash',
    kitchens: [],
    loading: false
  };

  handleChange = event => {
    this.setState({ value: event.target.value });
  };

  getKitchens = async () => {
    var dishesByKitchen = _.groupBy(this.props.currentOrder, "userId")
    var orderedKitchens = []
    // console.log("dishesByKitchen", dishesByKitchen, this.props.currentOrder);
    await _.each(dishesByKitchen, async (dishes, kitchenId) => {
      await this.props.firebase.kitchens().orderByChild('userId').equalTo(kitchenId).once('value', snapshot => {
        // console.log("kitchen found", snapshot.val(), _.values(snapshot.val()))
        orderedKitchens.push(_.values(snapshot.val())[0])

        this.setState({kitchens: orderedKitchens})

      })
      // .then(kitchen => console.log("found kitchen", kitchen)).catch(err => console.log("errir", err))
    })
    // console.log("kitchens", this.state.kitchens);
  }

  componentDidMount() {
    this.getKitchens()
    this.setState({ loading: true })
  }

  showAddress(kitchen) {
    return <div><h1>{kitchen.kitchen_name}</h1><p>{kitchen.street}</p><p>{kitchen.city, kitchen.zipcode}</p></div>
  }
  render() {
    const { classes } = this.props;

    return this.state.loading ? <div style={{marginLeft: "10%", height: 200, overflowY: "scroll", width: "80%"}}>
        <h2>Kitchen: {_.get(this.props.selectedKitchen, "kitchen_name", "")}</h2>
        <h3>Payment Method: {this.props.paymentMethod}</h3>
        {this.props.deliveryMethod === "Kitchen Delivery" &&
          <div>
            <h3>Delivery Address</h3>
            <p>{_.get(this.props.deliveryAddress, "street", "")},&nbsp;
              {_.get(this.props.deliveryAddress, "city", "")},&nbsp;
              {_.get(this.props.deliveryAddress, "zipcode", "")}</p>
          </div>
        }

        {this.props.deliveryMethod === "Self-pickup" &&
          <div>
            <h3>Pick-up Address</h3>
            <p>{_.get(this.props.selectedKitchen, "street", "")}, &nbsp;
              {_.get(this.props.selectedKitchen, "city", "")}, &nbsp;
              {_.get(this.props.selectedKitchen, "zipcode", "")}</p>
          </div>
        }

    </div> : <CircularProgress color="secondary"/>
  }
}

ConfirmAndPlace.propTypes = {
  classes: PropTypes.object.isRequired,
  currentOrder: PropTypes.array.isRequired
};

const mapStateToProps = ( state ) => ({
  paymentStatus: state.clientOrder.paymentStatus || "",
  currentOrder: state.findFood.orderDishes || [],
  paymentMethod: state.clientOrder.paymentMethod || "",
  deliveryMethod: state.clientOrder.deliveryMethod || "",
  deliveryAddress: state.clientOrder.deliveryAddress || {},
  selectedKitchen: state.findFood.selectedKitchen || []
})

const mapDispatchToProps = ( dispatch ) => ({
  setPaymentStatus: paymentStatus => dispatch({ type: "GET_PAYMENT_STATUS", paymentStatus})
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withFirebase(ConfirmAndPlace)));
