import {combineReducers} from 'redux';
import dishesReducer from './dishesReducer'
import navReducer from './navReducer'
import clientOrderReducer from './clientOrderReducer'
import kitchenOrderReducer from './kitchenOrderReducer'
import findFoodReducer from './findFoodReducer'
import snackbarReducer from './snackbarReducer'

const rootReducer = combineReducers({
    dishes: dishesReducer,
    nav: navReducer,
    clientOrder: clientOrderReducer,
    findFood: findFoodReducer,
    kitchenOrder: kitchenOrderReducer,
    snackbar: snackbarReducer
});

export default rootReducer;
