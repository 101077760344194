import React from 'react'
import PropTypes from 'prop-types';
import { connect }           from "react-redux"
import { withStyles } from '@material-ui/core/styles';
import { Radio, CircularProgress } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkout from './Checkout'
import _ from 'lodash'
import { withFirebase } from '../../Firebase';
import { useAsync } from "react-async"

import Steps from '../../Trading/Orders/Steps'

const styles = theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
});

class StatusConfirmation extends React.Component {

  state = {
    value: 'cash',
    kitchen: [],
    windowWidth: 0,
    windowHeight: 0,
    loading: false
  };

  componentDidMount() {
    // console.log('width',  this.state.windowWidth);
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions);
    this.getKitchens()
    this.setState({ loading: true })
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.windowWidth != this.state.windowWidth) {
      return true
    }
  }

  getKitchens() {
    this.props.firebase.kitchens()
    .orderByChild('userId')
    .equalTo(_.get(this.props.currentOrder, "kitchenUserId", _.get(this.props.selectedKitchen, "userId", "")))
    .limitToFirst(1)
    .once('value', snapshot => {
      // console.log("kitchen found", snapshot.val());
      this.setState({ kitchen: _.first(_.values(snapshot.val()))})
    })
  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  }

  // getOpenOrders() {
  //   var userOpenOrders = []
  //   this.props.firebase.orders()
  //   .orderByChild("userId")
  //   .equalTo(_.get(firebase.auth().currentUser, "uid", ""))
  //   .once('value', snapshot => {
  //     console.log("snapshot userOrders", firebase.auth().currentUser.uid, snapshot.val());
  //     _.each(snapshot.val(), order => {
  //       if (order.status != "delivered") {
  //         userOpenOrders.push(order)
  //       }
  //     })
  //     console.log("userOpenOrders", userOpenOrders);
  //     // this.setState({openOrders: userOpenOrders})
  //     this.props.setCurrentOrder(_.last(userOpenOrders))
  //   })
  // }

  status(status) {
    var stepNo = 4
    if (status == "cooked") {
      stepNo = 1
    } else if (status == "dish_ready") {
      stepNo = 2
    } else if (status == "delivered") {
      stepNo = 3
    }

    // console.log("statusss", stepNo, status);
    return <div style={{height: 100, display: "flex"}}>
      <div style={{float: "left", width: 90, height: 90, borderRadius: 50,
                    backgroundColor: stepNo <= 3 ? "rgb(241, 119, 33)" : "white", borderStyle: "solid", borderColor: "#88C603", borderWidth: 3}}>
        <p style= {{float: "left", margin: 25, marginTop: 36, textAlign: "center"}}>Cooked</p>
      </div>
      <div style={{float: "left", width: 100, height: 10, backgroundColor: stepNo <= 3 && stepNo >= 1 ? "#88C603" : "grey", marginTop: 45}}></div>
      <div style={{float: "left", width: 90, height: 90, borderRadius: 50,
                    backgroundColor: stepNo >= 2 && stepNo <= 3 ? "rgb(241, 119, 33)" : "white", borderStyle: "solid", borderColor: "#88C603", borderWidth: 3}}>
        <p style={{float: "left", margin: 20, marginTop: 20, textAlign: "center"}}>Ready for delivery</p>
      </div>
      <div style={{float: "left", width: 100, height: 10, backgroundColor: stepNo == 3 ? "#88C603" : "grey", marginTop: 45}}></div>

      <div style={{float: "left", width: 90, height: 90, borderRadius: 50,
                    backgroundColor: stepNo == 3 ? "rgb(241, 119, 33)" : "white", borderStyle: "solid", borderColor: "#88C603", borderWidth: 3}}>
        <p style={{float: "left", margin: 20, marginTop: 36, textAlign: "center"}}>Delivered</p>
      </div>
    </div>
  }

  showStatus() {
    // console.log("status", this.props.currentOrder, _.get(_.first(_.values(this.props.currentOrder)), "status", "undefined"));
    return this.status(_.get(this.props.currentOrder, "status", "") || _.get(_.first(_.values(this.props.currentOrder)), "status", "undefined"))
    // return <Steps orderId={_.first(_.keys(this.props.currentOrder))} status={_.get(_.first(_.values(this.props.currentOrder)), "status", "undefined")}/>
  }


  render() {
    const { classes } = this.props;

    return this.state.loading ? <div style={{marginLeft: this.state.windowWidth < 700 ? 0 : "10%"}}>
        <h3 style={{margin: 5}}>Status and confirmation</h3>
        {this.showStatus()}
        <div>
          <p><b>Contact:</b> {_.get(this.state.kitchen, "email", "")}, {_.get(this.state.kitchen, "phone", "")}</p>
          {_.get(this.props.currentOrder, "deliveryMethods", this.props.deliveryMethod) == "Self-pickup" &&
              <p><b>Pick-up Address:</b> {_.get(this.state.kitchen, "street")}, {_.get(this.state.kitchen, "city")}, {_.get(this.state.kitchen, "zipcode")}</p>}

        </div>
    </div> : <CircularProgress color="secondary"/>
  }
}

StatusConfirmation.propTypes = {
  classes: PropTypes.object.isRequired,
  currentOrder: PropTypes.array.isRequired
};

const mapStateToProps = ( state ) => ({
  paymentStatus: state.clientOrder.paymentStatus || "",
  currentOrder: state.clientOrder.currentOrder || {},
  paymentMethod: state.clientOrder.paymentMethod || "",
  deliveryMethod: state.clientOrder.deliveryMethod || "",
  selectedKitchen: state.findFood.selectedKitchen || ""
})

const mapDispatchToProps = ( dispatch ) => ({
  setPaymentStatus: paymentStatus => dispatch({ type: "GET_PAYMENT_STATUS", paymentStatus}),
  setCurrentOrder: currentOrder => dispatch({ type: 'GET_CURRENT_ORDER', currentOrder })
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withFirebase(StatusConfirmation)));
