import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect }           from "react-redux"
import PropTypes             from 'prop-types';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import _ from 'lodash'

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          // if (!condition(authUser)) {
          //   this.props.history.push(ROUTES.SIGN_IN);
          // }
          if (authUser) {
            this.props.firebase
              .user(authUser.uid)
              .once('value')
              .then(snapshot => {
                const dbUser = snapshot.val();

                // default empty roles
                if (!_.get(dbUser, "roles", {})) {
                  dbUser.roles = [];
                }

                // merge auth and db user
                authUser = {
                  uid: authUser.uid,
                  email: authUser.email,
                  ...dbUser,
                };

                // console.log("sign in usertype", this.props.userType);

                if (!condition(authUser)) {
                  // console.log("if not condition try taste", condition(authUser));
                  // console.log("authUser condition", authUser);
                  if (this.props.userType == "tradeTaste") {
                    this.props.history.push(ROUTES.HOME);
                  } else {
                    this.props.history.push(ROUTES.FIND_FOOD);
                  }
                }
              });
          } else {
            // console.log("if not authuser", authUser, this.props.userType);
            // if (this.props.userType === "tryTaste") {
            //   this.props.history.push(ROUTES.TASTE);
            // } else {
              this.props.history.push(ROUTES.TASTE);
            // }
            // this.props.history.push(ROUTES.TASTE);
          }
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            condition(authUser) ? <Component {...this.props} /> : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  WithAuthorization.propTypes = {
    userType: PropTypes.string.isRequired
  }

  const mapStateToProps = (state) => {
    return {
      userType : state.nav.userType || ""
    }
  }

  return compose(
    withRouter,
    withFirebase,
  )(connect(mapStateToProps, null)(WithAuthorization));
};

export default withAuthorization;
