import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux"
import {geolocated} from 'react-geolocated'
import { withFirebase } from '../../Firebase';
import firebase from "firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import logo from '../../../static/images/logo.svg'

import { Card, CardActions, CardContent, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash'
import { Field, reduxForm } from 'redux-form'
import * as ROUTES from '../../../constants/routes';
import * as ROLES from '../../../constants/roles';

// import * as admin from 'firebase-admin'
//
// admin.initializeApp()


const styles = theme => ({
  card: {
    width: "60%",
    marginLeft: "20%",
    // backgroundColor: "rgb(143, 185, 57, 0.2)",
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "70%",
    marginLeft: "15%",
  },
  cartTitles: {
    fontSize: "20px",
    marginLeft: "15%",
    marginTop: "30px"
  }
});

const step1 = [
    {
      name: "kitchen_name",
      type: "",
      label: "Kitchen Name",
      style: {}
    },
    {
      name: "email",
      type: "",
      label: "Email",
      style: {}
    },
    {
      name: "password",
      type: "password",
      label: "Password",
      style: {width: "30%"}
    },
    {
      name: "confirm_password",
      type: "password",
      label: "Confirm Password",
      style: {width: "28%", marginLeft: "10%"}
    }
  ]

const step2 = [
    {
      name: "first_name",
      type: "",
      label: "First Name",
      style: {}
    },
    {
      name: "last_name",
      type: "",
      label: "Last Name",
      style: {}
    },
    {
      name: "phone",
      type: "number",
      label: "Phone Number",
      style: {}
    }
  ]

const step3 = [
  {
    name: "street",
    type: "",
    label: "Street",
    style: {}
  },
  {
    name: "city",
    type: "",
    label: "City",
    style: {width: "30%"}
  },
  {
    name: "state",
    type: "",
    label: "State",
    style: {width: "28%", marginLeft: "10%"}
  },
  {
    name: "country",
    type: "",
    label: "Country",
    style: {width: "30%"}
  },
  {
    name: "zipcode",
    type: "",
    label: "Zipcode",
    style: {width: "28%", marginLeft: "10%"}
  }
]

const step4 = [{name: "test_code", type:"", label: "Food Test Code", style: {}}]

// const renderTextField = ({
//   input,
//   label,
//   name,
//   style,
//   errorText,
//   meta: { touched, error },
// }) => (
//   <TextField
//     hintText={label}
//     name={name}
//     style={style}
//     floatingLabelText={label}
//     errorText={touched && errorText}
//   />
// )

class BUILD_KITCHEN extends React.Component {

  constructor() {
    super();
    this.state = {
     kitchen_name: "",
     first_name:"",
     last_name:"",
     phone: "",
     street: "",
     city: "",
     state: "",
     country: "",
     zipcode: "",
     food_options: "",
     email: "",
     password: "",
     confirm_password: "",
     test_code: "",
     lat: 0,
     lngt: 0,

     kitchen_name_error: "",
     first_name_error:"",
     last_name_error:"",
     phone_error: "",
     street_error: "",
     city_error: "",
     state_error: "",
     country_error: "",
     zipcode_error: "",
     food_options_error: "",
     email_error: "",
     password_error: "",
     confirm_password_error: "",
     test_code_error: "",
     windowWidth: 0,
     windowHeight: 0
    };
  }

  updateInput = e  => {
    // console.log("updating input", e.target.name, e.target.value);
    // console.log("state", this.state);
    this.setState({
      [e.target.name]: e.target.value

    });

    this.setState({
      [`${e.target.name}_error`]: this.validate(e.target.name, e.target.value)
    })

  }

  validate = (field, value) => {
    var error = ""
    // console.log("called validate", field, value);
    if (value && value.length) {
      switch (field) {
        case "kitchen_name":
          if (value.length <= 2 || value.length >= 64) {
            // console.log("first_name error", field, value);
            error = "Kitchen name should be 3 - 64 characters long"
            break;
          // } else if (!this.isUniqKitchen()) {
          //   error = "Kitchen with this name already exists"
          //   break;
          } else {
            error = ""
            break;
          }
        case "email":
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            error = "Invalid email"
            break;
          } else {
            error = ""
            break;
          }
        case "password":
          // console.log("called password", value == this.state.confirm_password);
          if (value.length <= 6) {
            // console.log("error less than 6");
            error = "Password should be at least 6 characters long"
            break;

          } else if (value.length >= 64) {
            error = "Password should be less than 64 characters long"
            break;

          } else if (value !== this.state.confirm_password) {
            error = "Passwords do not match"
            this.setState({confirm_password_error: error})
            break;
          } else {
            error = ""
            this.setState({confirm_password_error: error})
            break;
          }
        case "confirm_password":
          if (value !== this.state.password) {
            error = "Passwords do not match"
            this.setState({password_error: error})
            break;
          } else {
            error = ""
            this.setState({password_error: error})
            break;
          }
        case "first_name":
          if (value.length <= 2 || value.length >= 64) {
            // console.log("first_name error", field, value);
            error = "First name should be 6 - 64 characters long"
            break;
          } else {
            error = ""
            break;
          }
        case "last_name":
          if (value.length <= 2 || value.length >= 64) {
            // console.log("first_name error", field, value);
            error = "Last name should be 6 - 64 characters long"
            break;
          } else {
            error = ""
            break;
          }
        case "phone":
          if (value <= 999999999 || value > 9999999999) { // 10 digits
            error = "Invalid phone number"
            break;
          } else {
            error = ""
            break;
          }
        case "street":
          if (value.length < 3) {
            error = "Street name should be at least 3 characters long"
            break;
          } else if (value.length >= 128) {
            error = "Street name should be less than 128 characters long"
            break;
          } else {
            error = ""
            break;
          }
        case "city":
          if (value.length < 3) {
            error = "City name should be at least 3 characters long"
            break;
          } else if (value.length >= 128) {
            error = "City name should be less than 128 characters long"
            break;
          } else {
            error = ""
            break;
          }
        case "state":
          if (value.length < 2) {
            error = "State name should be at least 2 characters long"
            break;
          } else if (value.length >= 128) {
            error = "State name should be less than 128 characters long"
            break;
          } else {
            error = ""
            break;
          }
        case "country":
          if (value.length < 3) {
            error = "Country name should be at least 3 characters long"
            break;
          } else if (value.length >= 128) {
            error = "Country name should be less than 128 characters long"
            break;
          } else {
            error = ""
            break;
          }
        case "zipcode":
          if (value.length <= 2 || value.length >= 7) {
            error = "Invalid zipcode"
            break;
          } else {
            error = ""
            break;
          }
        case "test_code":
          if (value.length <= 6 || value.length >= 64) {
            // console.log("first_name error", field, value);
            error = "Test code should be 6 - 64 characters long"
            break;
          } else {
            error = ""
            break;
          }
        default:
          error = ""
      }
    } else {
      error = "Required"
    }

    return error
  }

  disableNext = (stepId) => {
    if (stepId == 1 ) {
      return !this.state.kitchen_name || this.state.kitchen_name_error.length ||
      !this.state.email || this.state.email_error.length ||
      !this.state.password || this.state.password_error.length
    } else if (stepId == 2) {
      return !this.state.first_name || this.state.first_name_error.length ||
      !this.state.last_name || this.state.last_name_error.length ||
      !this.state.phone || this.state.phone_error.length
    } else if (stepId == 3) {
      return !this.state.street || this.state.street_error.length ||
      !this.state.city || this.state.city_error.length ||
      !this.state.state || this.state.state_error.length ||
      !this.state.country || this.state.country_error.length ||
      !this.state.zipcode || this.state.zipcode_error.length
    } else {
      return true
    }
  }

  form = (classes, submit, update, inputs, stepId) => {
    // label, name, type
    var showBackBtn = stepId == 1 ? "none" : "block"
    var showNextBtn = stepId == 4 ? "none" : 'block'
    var showBuildBtn = stepId == 4 ? "block" : 'none'
    return (
      <form className={classes.container} id={`formStep${stepId}`} autoComplete="off">
        <div style={{width: "100%"}}>
        {_.map(inputs, ({ label, name, type, style}) => {
          return (
            <TextField
              id="standard-name"
              label={label}
              name={name}
              type={type}
              className={classes.textField}
              value={_.get(this.state, name, "")}
              onChange={update}
              margin="normal"
              style={style}
              error={_.get(this.state, `${name}_error`, "") ? true : false}
              helperText={_.get(this.state, `${name}_error`, "")}
              required
            />
          )
        })}
        </div>
        <br/><br/>
        <div style={{width: "70%", marginLeft: "15%", marginTop: this.state.windowWidth < 700 ? 10 : "30px", marginBottom: this.state.windowWidth < 700 ? 5 :"20px"}}>
          <Button variant="contained" className={classes.button} style={{display: showBackBtn, float: "left", backgroundColor: "darkorange", color: "black"}}
            onClick={() => {

              // hide current step form
              if (document.getElementById(`step${stepId}`) ) {
                document.getElementById(`step${stepId}`).style.display = "none"
              }

              // show previous step
              if (document.getElementById(`step${stepId-1}`)) {
                document.getElementById(`step${stepId-1}`).style.display = "block"
              }
            }}>
            Back
          </Button>
          <Button type="submit" variant="contained" className={classes.button} style={{display: showNextBtn, float: "right", backgroundColor: "yellowgreen", color: "black"}}
            onClick={(e) => {
              e.preventDefault()
              // hide current step
              if ( document.getElementById(`step${stepId}`) ) {
                document.getElementById(`step${stepId}`).style.display = "none"
              }
              // show next step form
              if (document.getElementById(`step${stepId+1}`)) {
                document.getElementById(`step${stepId+1}`).style.display = "block"
              }
            }}
            disabled={this.disableNext(stepId) ? true : false}>
            Next
          </Button>
          <Button variant="contained" className={classes.button} style={{display: showBuildBtn, float: "right", backgroundColor: "yellowgreen", color: "black"}}
            onClick={() => {
              this.addKitchen()
              // hide current step
              // if ( document.getElementById(`step${stepId}`) ) {
              //   document.getElementById(`step${stepId}`).style.display = "none"
              // }
              // // show next step form
              // if (document.getElementById(`step${stepId+1}`)) {
              //   document.getElementById(`step${stepId+1}`).style.display = "block"
              // }
            }}
            disabled={!this.state.test_code || this.state.test_code_error.length}>
            Build Now
          </Button>
        </div>
      </form>
    )
  }

  // isUniqKitchen = () => {
  //   var isUniq = true
  //   this.props.firebase.kitchens().on('value', snapshot => {
  //     _.each(snapshot.val(), (val, key) => {
  //       console.log('values', val, this.state.kitchen_name, _.get(val, "kitchen_name", ""), _.get(val, "kitchen_name", "") == this.state.kitchen_name);
  //       if (_.get(val, "kitchen_name", "") == this.state.kitchen_name) {
  //         isUniq = false
  //         return isUniq
  //       }
  //     })
  //     return isUniq
  //   })
  // }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.windowWidth != this.state.windowWidth) {
      return true
    }
  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div style={{width: "100%", height: 650, backgroundImage: "url(https://fupping.com/wp-content/uploads/2018/10/Home-7.jpg)", backgroundSize: "cover"}}>
          <div  style={{marginLeft: this.state.windowWidth < 700 ? '40%' : "45%", marginTop: 0}}>
            <img src={logo} width={this.state.windowWidth < 700 ? "50%" : "20%" } height={this.state.windowWidth < 700 ? "50%" : "20%" } style={{marginTop: this.state.windowWidth < 700 ? 20 : 0}}/>
          </div>
          <div className={classes.card} style={{backgroundColor: "rgb(255, 255, 255, 0.5)", width: this.state.windowWidth < 700 ? "90%" : "60%", marginLeft: this.state.windowWidth < 700 ? "5%" : "20%"}}>
            <div style={{padding: 10}}>
            <div id="step1" style={{display: "block"}}>
              <Typography variant="title" gutterBottom className={classes.cartTitles}>
                Please enter your kitchen name, email, password
              </Typography>
              {this.form(classes, "", this.updateInput, step1, 1)}
            </div>
            <div id="step2" style={{display: "none"}}>
              <Typography variant="title" gutterBottom className={classes.cartTitles}>
                Please enter your name and phone number
              </Typography>
              {this.form(classes, "", this.updateInput, step2, 2)}
            </div>
            <div id="step3" style={{display: "none"}}>
              <Typography variant="title" gutterBottom className={classes.cartTitles}>
                Please enter your address
              </Typography>
              {!this.props.isGeolocationAvailable
                ? <div>Your browser does not support Geolocation</div>
                : !this.props.isGeolocationEnabled
                  ? <div>Geolocation is not enabled</div> : console.log("props", this.props.coords)
                }
              {this.form(classes, "", this.updateInput, step3, 3)}
            </div>
            <div id="step4" style={{display: "none"}}>
              <Typography variant="title" gutterBottom className={classes.cartTitles}>
                Please enter your food test code
              </Typography>
              {this.form(classes, "", this.updateInput, step4, 4)}
              <div style={{width: "70%", marginLeft: "15%", marginTop: this.state.windowWidth < 700 ? 0 : "50px", marginBottom: this.state.windowWidth < 700 ? 0 : "20px"}}>
                <Typography variant="title" gutterBottom style={{fontSize: "25px", marginTop: this.state.windowWidth < 700 ? 5 : "20px"}}>
                  Haven't completed the food safety test?
                </Typography>
                <Link to="/safety-test" target="_blank"> 
              <Button onclick="myFunction()" variant="contained" color="primary" style={{width: this.state.windowWidth < 700 ? "100%" : "auto", marginTop: "5px", marginLeft:  this.state.windowWidth < 700 ? "5%" : "30%"}}>
                  Take Food Safety Test Now!
                </Button>
                </Link> 
              </div>

            </div>
          </div>
        </div>
      </div>
      </div>
        );
      }

      addKitchen = e => {
        // e.preventDefault();
        // console.log("adding kitchen", this.state);

        const { first_name,last_name, phone, email, password} = this.state;
        var roles = {}

        this.props.firebase
          .doCreateUserWithEmailAndPassword(email, password)
          .then(authUser => {
            // Create a user in your Firebase realtime database
            roles[ROLES.COOK] = true

            this.props.firebase
              .user(authUser.user.uid)
              .set({
                first_name,
                last_name,
                phone,
                email,
                roles
              })
              .then((user) => {
                if (authUser.user.uid) {
                  var coords = {latitude: _.get(this.props.coords, "latitude", 0), longitude: _.get(this.props.coords, "longitude", 0)}
                  // console.log("coords", coords);
                  this.props.firebase.kitchens().push().set({
                     kitchen_name: this.state.kitchen_name,
                     email: email,
                     userId: authUser.user.uid,
                     street: this.state.street,
                     city: this.state.city,
                     country: this.state.country,
                     zipcode: this.state.zipcode,
                     test_code: this.state.test_code,
                     food_options: this.state.food_options,
                     coords: coords,
                     postedDishesIds: []
                 }).then(kitchen => {
                   // console.log("Successfully built kitchen");
                   this.setState({
                     kitchen_name: "",
                     first_name:"",
                     last_name:"",
                     phone:"",
                     email: "",
                     street: "",
                     city: "",
                     country: "",
                     zipcode: "",
                     food_options: "",
                     password: "",
                     test_code: ""
                   });
                   this.props.history.push(ROUTES.HOME);
                 }).catch(error => {
                   // console.log("Error building kitchen");
                   this.props.setMessage({text: "Something went wrong", variant: "error"})
                 })
                }
              })
              .catch(error => {
                this.setState({ error });
                // console.log("error firebase", error);
                this.props.setMessage({text: "Something went wrong", variant: "error"})
              });
          })
          .catch(error => {
            this.setState({ error });
            // console.log("error", error);
            this.props.setMessage({text: "Something went wrong", variant: "error"})
          });
        };
   }
   const BuildKitchenLink = () => (
     <p>
       "Build your kitchen now! "<Link to={ROUTES.BUILD_KITCHEN}> Build Kitchen</Link>
     </p>
   );


 BUILD_KITCHEN.propTypes = {
   classes: PropTypes.object.isRequired,
 };

 const mapDispatchToProps = (dispatch) => ({
   setMessage: message => dispatch({ type: 'GET_MESSAGE', message })
 })

export default withStyles(styles)(withRouter(withFirebase(geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(connect(null, mapDispatchToProps)(BUILD_KITCHEN)))))
