import React, { Component } from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types';
import { withAuthorization } from '../../Session';

import { connect }           from "react-redux"
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import green from '@material-ui/core/colors/green';
import Radio from '@material-ui/core/Radio';

import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash'

const options = [
  { value: 'asian', label: 'Asian' },
  { value: 'american', label: 'American' },
  { value: 'latin', label: 'Latin' },
  { value: 'mediterranean', label: 'Mediterranean' },
  { value: 'european', label: 'European' },
  { value: 'indian', label: 'Indian' },
  { value: 'other', label: 'Other' },
]

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
});

 class Categories extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        selectedOption: null,
        selectedValue: "",
        checkedAsian: false,
        checkedAmerican: false,
        checkedEuropean: false,
        checkedIndian: false,
        checkedMediterranean: false,
        checkedLatin: false,
        checkedAfrican: false,
        checkedOther: false,
      };
    }

    handleChange = (selectedOption) => {
      this.setState({ selectedOption });
      // console.log(`Option selected:`, selectedOption, this.handleMultiSelect());
    }

    handleChangeCheckBox = name => event => {
      this.setState({ [name]: event.target.checked });
      if (event.target.checked) {
        this.props.setSelectedCategories(_.concat(this.props.selectedCategories, [event.target.value]))
      } else if (!event.target.checked) {
        this.props.setSelectedCategories(_.pull(this.props.selectedCategories, event.target.value))
      }
    };



    render() {
      const { selectedOption } = this.state;
      const { classes } = this.props;

      return (
        <div className={classes.root}>
        <br/>
        <Typography variant="h5">Choose Categories</Typography>
        <br/>
        <Grid container spacing={24}>
          {
            _.map(options, ({ value, label }) => {
            return <Grid item xs={3}>
              <Paper  className={classes.paper}>
                <Checkbox
                  checked={_.get(this.state, `checked${label}`, false)}
                  onChange={this.handleChangeCheckBox(`checked${label}`)}
                  value={value}
                  color="default"
                  className={classes.button}
                />

                {label}

              </Paper>
            </Grid>
          })}
        </Grid>
      </div>
      );
    }
  }

  Categories.propTypes = {
    classes: PropTypes.object.isRequired,
    selectedCategories: PropTypes.array.isRequired
  };

  const mapStateToProps = (state) => {
    // console.log("searched food state", state, state.findFood.selectedCategories)
    return {
      selectedCategories : state.findFood.selectedCategories || []
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    setSelectedCategories: selectedCategories => dispatch({ type: 'GET_SELECTED_CATEGORIES', selectedCategories }),
  })

  const condition = authUser => authUser && !!authUser;

  export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Categories))

  // export default withStyles(styles)(withAuthorization(condition)(connect(mapStateToProps, mapDispatchToProps)(Categories)));
