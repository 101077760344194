const INITIAL_STATE = {
  userType: null,
};

const getUserType = (state, action) => ({
  ...state,
  userType: action.userType,
});

function navReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'GET_USER_TYPE': {
      return getUserType(state, action);
    }
    default:
      return state;
  }
}

export default navReducer;
