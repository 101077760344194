import React from 'react';
// define proptypes
import PropTypes             from 'prop-types';

// connect to store
import { connect }           from "react-redux"
import { Paper, withStyles, Grid, TextField, Button,
            FormControlLabel, Checkbox, Typography, Card } from '@material-ui/core';
import FirebaseLogin from './FirebaseLogin.js';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import firebase from "firebase"


import { SignUpLink } from '../../SignUp';
import { PasswordForgetForm, PasswordForgetLink } from '../../PasswordForget';
import { withFirebase } from '../../Firebase';
import * as ROUTES from '../../../constants/routes';

import _ from 'lodash'


const SignInPage = () => (
  <div>
    <h1>SignIn</h1>
    <SignInForm />
    <PasswordForgetLink />
    <SignUpLink />
  </div>
);
const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  windowWidth: 0,
  windowHeight: 0
};


const styles = theme => ({
    margin: {
        margin: theme.spacing.unit * 2,

    },
    padding: {
        padding: theme.spacing.unit * 2 ,
        marginTop: 500,
    },
    root: {
      flexGrow: 1,
      width: "100%",
      height: "100%"
    },
    form: {
      textAlign: 'center',
      // maxWidth: 450,
      // minWidth: 300,
      // marginLeft: "35%",
      // padding: 20,
      marginTop: 100,
      width: "80%",
      margin: "5%",
      marginLeft: "10%",
      marginRight: "10%",
    },
    font: {
      variant: 'h1',
    },
});

class Taste extends React.Component {


      constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
      }

      componentDidMount() {
        this.props.setUserType("tryTaste")
        // console.log("firebase auth", _.get(firebase.auth(), "currentUser", {}), this.props.firebase);
        if (_.get(firebase.auth(), "currentUser", {})) {
          // console.log("curren user found");
          this.props.history.push(ROUTES.FIND_FOOD);
        }
      }

       onSubmit = event => {
        const { email, password } = this.state;

        // console.log("login submit", email, password)

        this.props.firebase
          .doSignInWithEmailAndPassword(email, password)
          .then(() => {
            this.setState({ ...INITIAL_STATE });
            this.props.history.push(ROUTES.FIND_FOOD);
          })
          .catch(error => {
            this.setState({ error });
          });

        event.preventDefault();
      };

      componentDidMount() {
        // console.log('width',  this.state.windowWidth);
        this.updateDimensions()
        window.addEventListener("resize", this.updateDimensions);
      }

      componentWillUpdate(nextProps, nextState) {
        if (nextState.windowWidth != this.state.windowWidth) {
          return true
        }
      }

      updateDimensions = () => {
        this.setState({
          windowHeight: window.innerHeight,
          windowWidth: window.innerWidth
        });
      }


      onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
      };

       render() {
        const { classes } = this.props;
        const { email, password, error } = this.state;

        const isInvalid = password === '' || email === '';

         return (
          <div className={classes.root} style={{width: "100%", height: 700, overflowY: "scroll"}}>
            <Grid items container spacing={32}>
              <Grid item lg={7} md={7} sm={5} xs={0} style={{padding: 0, display: this.state.windowWidth <= 600 ? "none" : "block"}}>
                <div style={{width: "100%", height: this.state.windowHeight + 20, backgroundImage: "url(https://www.foodethicscouncil.org/app/uploads/2019/01/sharing-plates-rawpixel-754045-unsplash-600x400.jpg)", backgroundSize: "cover"}}>
                  <div style={{width: "100%", height: this.state.windowHeight + 20, backgroundColor: "rgb(179, 71, 0, 0.4)"}}>
                    <Typography variant="display1" align='center' style={{fontSize: this.state.windowWidth <= 900 ? 40 : 70, paddingTop: 200, paddingLeft: 50, paddingRight: 50, color: "white"}}>
                      Find homemade food near you
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item lg={5} md={5} sm={7} xs={12} style={{padding: 0}}>
                <Card style={{width: "100%", height: this.state.windowHeight + 20, overflowY: "scroll"}}>
                  <div>
                    <div className={classes.form} id="clientSignIn" style={{display: "block"}}>
                        <h2 style={{textAlign: "center"}}>Sign in </h2>
                        <TextField
                          id="username"
                          label="Username"
                          type="email"
                          name="email"
                          value={email}
                          onChange={this.onChange}
                          fullWidth autoFocus required />
                        <TextField
                          id="password"
                          label="Password"
                          type="password"
                          name="password"
                          value={password}
                          onChange={this.onChange}
                          fullWidth required />
                        <FormControlLabel control={
                             <Checkbox
                                color="primary"
                             />
                         } label="Remember me" />
                         <Button disableFocusRipple disableRipple style={{ textTransform: "none" }} variant="text" color="primary"
                           onClick={() => {
                             if(document.getElementById("clientPasswordForgot")) {
                               document.getElementById("clientPasswordForgot").style.display = "block"
                             }
                             if(document.getElementById("clientSignIn")) {
                               document.getElementById("clientSignIn").style.display = "none"
                             }
                           }}  >
                           Forgot password?
                           </Button>
                           <br/>
                           <Button onClick={this.onSubmit} variant="outlined" color="primary" style={{ textTransform: "none" }} disabled={isInvalid} type="submit" >Login</Button>
                             <Typography>New user?
                                <Button disableFocusRipple disableRipple style={{ textTransform: "none" }} variant="text" color="primary"> <Link to="/signup" style={{textDecoration: 'none'}}>Sign up here!</Link></Button>
                              </Typography>
                          <h3 style={{textAlign: "center"}}>Or</h3>
                          <div className={classes.margin} >
                              <FirebaseLogin />
                          </div>
                          {error && <p style={{color: "red"}}>{error.message}</p>}
                      </div>

                      <div id="clientPasswordForgot" style={{display: "none"}}>
                        <PasswordForgetForm signInId="clientSignIn" forgotId="clientPasswordForgot"/>
                      </div>
                    </div>
                  </Card>
                </Grid>
                </Grid>
            </div>
        );

       }


}



const SignInForm = compose(
  withRouter,
  withFirebase,
)(Taste);

Taste.propTypes = {
  userType: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  return {
    userType : state.nav.userType || ""
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUserType: userType => dispatch({ type: 'GET_USER_TYPE', userType }),
})

export default withStyles(styles)
(connect(mapStateToProps, mapDispatchToProps)
  (SignInForm)
);
