import React, { Component } from "react"
import { Link } from 'react-router-dom';
import { connect }           from "react-redux"
import firebase from "firebase";

import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import { Grid, Button, TextField, Fab } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import logo from './images/logo.jpeg';
import FindFoodSlide from './findFoodSlide'
import { withFirebase } from '../Firebase';
import { Close } from '@material-ui/icons'

import Stepper from './Stepper'
import StarRatings from 'react-star-ratings';
import FileUploader from 'react-firebase-file-uploader';
import enableInlineVideo from 'iphone-inline-video';
import HowItWorks from './HowItWorks'
// import About from './About'
// import Cover from 'react-video-cover';
import phoneVideo from './video.mp4';
import appGIF from './appGIF.gif';
import appStore from './appstore.png'
import playStore from './google-play-badge.png'
import _ from 'lodash'
import Navigation from './components/navigation';
import Header from './components/header';
import Chef from './components/chef';
import howitworks, { HowitWorks } from './components/How';
import GetApp from './components/GetApp';
import Testimonials from './components/testimonials';
import Team from './components/Team';
import Contact from './components/contact';
import JsonData from './data/data.json';
import './index.css';
import './App.css';
import TryTaste from "./components/TryTaste";
import How from './components/How';
import HowChef  from "./components/HowChef";



const styles = {
  // background: {
  //   backgroundImage: `url(${backgroundImage})`,
  //   backgroundColor: '#7fc7d9', // Average color of the background image.
  //   backgroundPosition: 'center',
  // },
  card: {
    maxWidth: 300,
    marginLeft: "40%"
  },
  media: {
    height: 300,
  },
  popup : {
    display: 'none',
    position: 'fixed',
    zIndex: 1,
    paddingTop: 10,
    paddingBottom: 10,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  videoStyle : {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: -1,
  },

};

class Landing extends React.Component {
  constructor (props) {
      super(props);
  }
  state = { landingPageData: {}, comment: "", rating: 0, progress: 0, isUploading: false, name: "", resumeURL: "", is_visible: false}

  componentDidMount() {
    // document.getElementById("myVideo").play()
    // enableInlineVideo(phoneVideo);
    var scrollComponent = this;
    document.addEventListener("scroll", function(e) {
      scrollComponent.toggleVisibility();
    });
    this.getlandingPageData();

    
  }
  getlandingPageData() {
    this.setState({landingPageData : JsonData})
  }

  handleChange = e => {
    this.setState({ [e.target.name] : e.target.value})
  }

  changeRating = ( newRating, name ) => {
    this.setState({
      [name]: newRating
    });
  }

  handleSubmit = () => {
    document.getElementById("feedback").style.display = "none"

    if (this.state.comment) {
      this.props.firebase.feedbacks().push()
      .set({comment: this.state.comment, rating: this.state.rating, timestamp: new Date().toString()})
      .then(feedback => console.log("feedback added successfully"))
      .catch(err => console.log("error adding feedback"))
    }
  }

  handleApplicationSubmit = () => {
    document.getElementById("joinus").style.display = "none"

    if (this.state.name) {
      this.props.firebase.applications().push()
      .set({name: this.state.name, profileURL: this.state.profileURL, timestamp: new Date().toString()})
      .then(feedback => console.log("joined successfully"))
      .catch(err => console.log("error joining"))
    }
  }

  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }


  //// Image Uploader ///////

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = progress => this.setState({ progress });

  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = filename => {
    this.setState({
      // avatar: filename,
      progress: 100,
      isUploading: false,
    });
    firebase
      .storage()
      .ref('resumes')
      .child(filename)
      .getDownloadURL()
      .then(url => {
        console.log("url", url)
        this.setState({ profileURL: url })
        // this.props.firebase.kitchen(this.state.kitchenId).update({ avatarURL: url })
      });

    // console.log("filename", filename, this.state.kitchenId, this.state.avatarURL);
    // console.log("image", _.split(filename, ".")[0]);
    // var dishImage = _.split(filename, ".")
    // this.setState({ avatar: filename, progress: 100, isUploading: false, image: dishImage });
    // firebase
    //   .storage()
    //   .ref("kitchen_pictures/dishes_pictures")
    //   .child(filename)
    //   .getDownloadURL()
    //   .then(url => this.setState({ avatarURL: url }));



  };

  render() {
      const { classes } = this.props;
      const { is_visible } = this.state;

        return (
//             <div >
//               <div>
//                 <Stepper/>
//                 <div style={{position: "absolute", top: _.get(document.getElementById("stepper"), "style.height", 600), width: "100%"}}>
//                   <HowItWorks/>
//                     <Grid items container spacing={32} style={{ width: "100%", height: "auto", backgroundColor: "#F0F0F0", padding: 0, margin: 0}}>
//                       <Grid item lg={4} sm={6} xs={12} style={{marginLeft: '20%'}}>
//                         <div style={{width: "100%", height: 600, position: "relative"}}>
//                           <img src="https://mockuphone.com/static/images/devices/apple-iphone7-gold-portrait.png" style={{objectFit: "cover", width: 300, height: 600, position: "absolute"}}/>
//                             <img src={appGIF} alt="app introduction" width={213} height="auto" style={{float: "left", position: "absolute", marginLeft: 43.5, marginTop: 110}}/>
//                           {/* <video autoplay playsinline mute src={phoneVideo} loop id="myVideo" width={213} height="auto" style={{float: "left", position: "absolute", marginLeft: 43.5, marginTop: 110}}/> */}
//                         </div>
//                       </Grid>
//                       <Grid item lg={4} sm={6} xs={12}>
//                         <div style={{marginTop: 10, marginRight: 10, alignContent:'center'}}>
//                         <h1>TradingTastes App</h1>
//                         <a target="_blank" href="https://apps.apple.com/us/app/trading-tastes/id1491768785?ls=1">
//                           <img src={appStore} alt="TRADINGTASTESAPPLE" style={{width: "70%", heights: "70%"}} />
//                         </a>
//                         <a target="_blank" href="https://play.google.com/store/apps/details?id=com.tradingtastes.tradingtastes&hl=en_US">
//                           <img src={playStore} alt="TRADINGTASTESANDROID" style={{width: "80%", marginLeft: -20, heights: "70%"}} />
//                         </a>
//                         </div>
//                       </Grid>
//                     </Grid>
// {/*
//                       <div lassName={classes.videoStyle}>
//                         <Cover
//                           videoOptions={videoOptions}
//                           remeasureOnWindowResize
//                           getResizeNotifier={resizeNotifier => {
//                             this.setState({
//                               resizeNotifier,
//                             });
//                           }}
//                         />
//                      </div> */}

//                   <div style={{width: "100%", height: 300, backgroundColor: "black"}}>
//                     <Button size="large"
//                       style={{width: "25%", marginLeft: "23%", marginTop: 100,
//                           backgroundColor: "rgb(241, 119, 33)", color: "black"}}
//                       onClick={() => document.getElementById("feedback").style.display = "block"}>
//                       Leave Feedback
//                     </Button>

//                     <Button size="large"
//                       style={{width: "25%", marginLeft: "5%", marginTop: 100,
//                           backgroundColor: "yellowgreen", color: "black"}}
//                       onClick={() => document.getElementById("joinus").style.display = "block"}>
//                       Join team
//                     </Button>

//                   </div>

//                   <div id="feedback" className={classes.popup}>

//                     <div style={{backgroundColor: "rgb(255, 255, 255, 0.7)", width: "60%", marginLeft: "20%", marginTop: 100}}>
//                       <Fab aria-label="Close" className={classes.fab} size="small"
//                       style={{float: "right", marginRight: 10, zIndex: 1000, backgroundColor: "white",
//                       position: "relative", marginTop: 10}}
//                         onClick={() => {
//                           var toggleDetails = document.getElementById("feedback")
//                           toggleDetails.style.display === "none" ? toggleDetails.style.display = "block" : toggleDetails.style.display = "none"
//                         }}>
//                         <Close />
//                       </Fab>
//                       <div style={{padding: 50}}>
//                         <div style={{marginLeft: 30}}>
//                           <h3>Ratings</h3>
//                           <StarRatings
//                             rating={this.state.rating}
//                             starRatedColor="#90c43b"
//                             changeRating={this.changeRating}
//                             numberOfStars={5}
//                             name='rating'
//                             starHoverColor="rgb(241, 119, 33)"
//                             starDimension="40px"
//                           />
//                         </div>
//                         <br/>
//                         <TextField
//                           name="comment"
//                           value={this.state.comment}
//                           label="Comment"
//                           placeholder="Comment"
//                           style={{width: "100%", margin: 20}}
//                           multiline
//                           maxRow={4}
//                           onChange={this.handleChange} />
//                         <br/>
//                         <Button style={{backgroundColor: "#90c43b", marginLeft: "40%"}} disabled={!this.state.rating} onClick={this.handleSubmit}>Submit</Button>
//                       </div>
//                     </div>
//                   </div>

//                   <div id="joinus" className={classes.popup}>

//                     <div style={{backgroundColor: "rgb(255, 255, 255, 0.7)", width: "60%", marginLeft: "20%", marginTop: 100}}>
//                       <Fab aria-label="Close" className={classes.fab} size="small"
//                       style={{float: "right", marginRight: 10, zIndex: 1000, backgroundColor: "white",
//                       position: "relative", marginTop: 10}}
//                         onClick={() => {
//                           var toggleDetails = document.getElementById("joinus")
//                           toggleDetails.style.display === "none" ? toggleDetails.style.display = "block" : toggleDetails.style.display = "none"
//                         }}>
//                         <Close />
//                       </Fab>
//                       <div style={{padding: 50}}>
//                         <TextField
//                           name="name"
//                           value={this.state.name}
//                           label="Name"
//                           placeholder="Name"
//                           style={{width: "100%", margin: 20}}
//                           onChange={this.handleChange} />

//                         <div style={{marginTop: 10, marginLeft: 20}}>
//                           <label style={{color: 'black', padding: 10, borderRadius: 4, pointer: 'cursor', backgroundColor: "darkorange"}}>
//                             Resume
//                           <FileUploader
//                             hidden
//                             accept="*"
//                             name="avatar"
//                             filename={this.state.filename}
//                             storageRef={firebase.storage().ref("resumes")}
//                             onUploadStart={this.handleUploadStart}
//                             onUploadError={this.handleUploadError}
//                             onUploadSuccess={this.handleUploadSuccess}
//                             onProgress={this.handleProgress}
//                             multiple
//                           />
//                           </label>
//                           {this.state.profileURL && <p>Successfully uploaded</p>}
//                         </div>
//                         <br/>
//                         <Button style={{backgroundColor: "#90c43b", marginLeft: "40%"}} disabled={!this.state.profileURL} onClick={this.handleApplicationSubmit}>Submit</Button>
//                       </div>
//                     </div>
//                   </div>

//                 </div>
//               </div>
//             </div>
             <div className="textStyle">
             <Navigation />
             <Header data={this.state.landingPageData.Header} />
             <TryTaste data={this.state.landingPageData.TryTaste} />
             <Chef data={this.state.landingPageData.Chef} />
             <How data={this.state.landingPageData.How} />
             <HowChef data={this.state.landingPageData.HowChef} />
             <GetApp />
             <Testimonials data={this.state.landingPageData.Testimonials} />
             <Team data={this.state.landingPageData.Team} />
             <Contact data={this.state.landingPageData.Contact} />
           </div>

        );
    }
}

// <h1 style={{textAlign: "center"}}> Welcome to Trading Tastes!</h1>
// <h1 style={{marginLeft: "10%", float: "left"}}> <Link to="/taste"> Try a taste </Link> </h1>
// <h1 style={{marginRight: "10%", float: "right"}}><Link to="/trade-main"> Trade a taste </Link></h1>
//
// <Card className={classes.card} >
//   <CardMedia className={classes.media} image= {logo}  title="Logo"/>
// </Card>


Landing.propTypes = {
  classes: PropTypes.object.isRequired,
  userType: PropTypes.object.isRequired
};


const mapStateToProps = (state) => {
  // console.log("dishes state", state)
    return {
    userType : state.nav.userType || ""
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUserType: userType => dispatch({ type: 'GET_USER_TYPE', userType }),
})

export default withStyles(styles)
(connect(mapStateToProps, mapDispatchToProps)
  (withFirebase(Landing))
);
