import React from 'react';
import PropTypes from 'prop-types';
import { connect }           from "react-redux"
import { withStyles } from '@material-ui/core/styles';
import { withAuthorization } from '../../Session';
import firebase from 'firebase'
import { withFirebase } from '../../Firebase';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Card, Divider, Grid, Avatar, CircularProgress } from '@material-ui/core';
import _ from 'lodash'
import Checkout from './Checkout';
import OrderSteps from './OrderSteps'
import { useAsync } from "react-async"

/// show order summary on side
// other side
  // on the top show status
    // on payment method, get status on payment if successful adding card enable complete step
    // show order details, payment method, address, time, enable place your order,
        // push order to orders collection -- one order could have many kitchens
            // clientOrders, cookOrders
            // id, userId, dishIds, total_amount, servings, status --- clientOrders -
            // id, cookId, dishIds, #clientOrder, servings, status

    // Show Status

const styles = theme => ({
  orderSummary: {
    width: '100%',
    marginTop: theme.spacing.unit * 2,
    overflowX: 'auto',
    marginLeft: "2%",
    height: 400
  },
  table: {
    minWidth: 700,
  },
});

let id = 0;
function createData(orderNumber, customerName, location, dishName, DeliveryOption, OrderStatus) {
  id += 1;
  return { orderNumber, customerName, location, dishName, DeliveryOption, OrderStatus };
}

const rows = [
  createData('1','Ahmed', '21 Celeveland Blvd Caldwell ID', 24, 4.0, 'deliverd'),
  createData('2','Kanchan', '12 Celeveland Blvd Caldwell ID', 37, 4.3,'delivered'),
  createData('3','Granzow', '120 Celeveland Blvd Caldwell ID', 24, 6.0,'delivered'),
  createData('4','Moha' , '1211 Celeveland Blvd Caldwell ID', 67, 4.3,'delivered'),
  createData('5','Alaa', '2009 Celeveland Blvd Caldwell ID', 49, 3.9,'delivered'),

];

let loadedStripe = false;


// <Table className={classes.table}>
//   <TableHead>
//     <TableRow>
//       <TableCell>Order Number</TableCell>
//       <TableCell align="right">Custmer Name </TableCell>
//       <TableCell align="right">Location</TableCell>
//       <TableCell align="right">Dish Name</TableCell>
//       <TableCell align="right">Delivery Option</TableCell>
//       <TableCell align="right">OrderStatus</TableCell>
//     </TableRow>
//   </TableHead>
//
//   <TableBody>
//     {rows.map(row => (
//       <TableRow key={row.id}>
//         <TableCell component="th" scope="row">
//           {row.orderNumber}
//         </TableCell>
//         <TableCell align="right">{row.customerName}</TableCell>
//         <TableCell align="right">{row.location}</TableCell>
//         <TableCell align="right">{row.dishName}</TableCell>
//         <TableCell align="right">{row.DeliveryOption}</TableCell>
//         <TableCell align="right">{row.OrderStatus}</TableCell>
//
//       </TableRow>
//     ))}
//   </TableBody>
// </Table>


class PreviousOrders extends React.Component {
  constructor() {
      super();
      this.state = {previousOrders: [], dishesInfo: [], kitchenName: "", address: "",
                    months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
                    loading: false };
    }

  componentDidMount() {

    // if (window.Stripe) {
    //   this.setState({stripe: window.Stripe('pk_test_FnGbY0qD0mRH5bM9fssEyKiE00sqBowRGQ')});
    // } else {
    //   document.querySelector('#stripe-js').addEventListener('load', () => {
    //     // Create Stripe instance once Stripe.js loads
    //     this.setState({stripe: window.Stripe('pk_test_FnGbY0qD0mRH5bM9fssEyKiE00sqBowRGQ')});
    //   });
    // }
    var dishInfo = []
    this.getKitchenName(_.get(this.props.order, "kitchenUserId", "undefined"))
    _.each(_.get(this.props.order, "dishes", {}), async (dish) => {
      dishInfo = await this.getDishValues(_.get(dish, "dishId", ""))
      this.setState({ dishesInfo : _.concat(this.state.dishesInfo, dishInfo) })
    })
    this.setState({ loading: true })
    // this.getPreviousOrders()
    // console.log("order previous", this.props.order);

  }

  componentWillUpdate(nextProps, nextState) {
    // console.log("dishImage changed", nextState.dishImages);
    // if (nextState.dishImages != this.state.dishImages) {
    //   console.log("dishImages", this.state.dishImages);
    // }
  }


  // getDishes() {
  //   var orders = [],
  //       dishId = "",
  //       dish = []
  //
  //   this.props.firebase.orders()
  //   .orderByChild('userId')
  //   .equalTo(_.get(firebase.auth().currentUser, "uid", ""))
  //   .once('value', snapshot => {
  //     orders = _.map(snapshot.val(), (val, key) => {
  //       setTimeout(this.getKitchenName(_.get(val, "kitchenUserId", "")), 1000)
  //       return _.merge({'orderId' : key, 'kitchenName' : this.state.kitchenName}, val)
  //     })
  //     orders = _.filter(orders, 'dishes')
  //     console.log("orders", orders);
  //     _.each(orders, async (order) => {
  //       console.log("order", order, _.get(order, "dishes", []));
  //
  //       await _.each(_.get(order, "dishes", []), async (dish) => {
  //
  //         dishId = await _.get(dish, 'dishId', "")
  //
  //         if (dishId ) {
  //           dish = await this.getDishValues(dishId)
  //           console.log("info", dish);
  //           this.setState({ dishesInfo: _.concat(this.state.dishesInfo, dish ) })
  //         }
  //       })
  //     })
  //     this.setState({ previousOrders: orders})
  //   })
  // }


  getKitchenName = async (userId) => {
    if (userId) {
      const kitchen = await this.props.firebase.kitchens()
                              .orderByChild('userId').equalTo(userId)
                              .limitToFirst(1).once('value');

      const kitchenValues = kitchen.val();

      this.setState({kitchenName: _.get(_.first(_.values(kitchenValues)), "kitchen_name", ""),
                      address: `${_.get(_.first(_.values(kitchenValues)), "city", "")}, ${_.get(_.first(_.values(kitchenValues)), "zipcode", "")}` })
    }
  }

  getDishValues = async (dishId) => {
    const dish = await this.props.firebase.dish(dishId).once('value')

    const dishObj = dish.val()

    return [{ [dishId] : {'avatarURL': _.get(dishObj, "avatarURL", ""),
                          'price': _.get(dishObj, "price", "0"),
                          "dishName" : _.get(dishObj, "dishName", "")}}]
  }

  dish (dish) {
    const { classes } = this.props
    var dishInfo = []
    // var dishInfo =  _.get(_.find(this.state.dishesInfo, dish.dishId), dish.dishId, {})

    if (dish.dishId) {
      // dishInfo = await this.getDishValues()
    }

    // console.log("dishesInfo", this.state.dishesInfo);

    var dishI = _.get(_.find(this.state.dishesInfo, dish.dishId), dish.dishId, {})

    // console.log("dishInfo", dishInfo);
    // console.log("dishI", dishI);
    return this.state.loading ? <div>
              <Grid items container spacing={32}>
                <Grid item xs={6}>
                  <Avatar alt="" src={_.get(dishI, "avatarURL", "")}
                    className={classes.bigAvatar}
                    style={{width: 130, height: 130}} />
                </Grid>
                <Grid item xs={6}>
                  <p>{_.get(dishI, "dishName", "")}, ${parseFloat(_.get(dishI, "price", 0)).toFixed(2)}</p>
                  <p>Servings: {_.get(dish, "servings", "")}, Note: {_.get(dish, "orderNote", "")}</p>
                </Grid>
              </Grid>
          </div> : <CircularProgress color="secondary"/>

  }

  // getKitchensName = async (userId) => {
  //   var kithenName = ""
  //
  //
  //
  //   // await this.props.kitchen(userId).once('value', snapshot => {
  //   //   console.log("found ordered kitchen", userId, snapshot.val());
  //   // })
  // }

  render () {
    const { classes } = this.props;

    return (
      <Card>
          <div style={{width: "100%", padding: 10, backgroundColor: "rgb(230, 230, 230)"}}>
            <Grid items container spacing={32}>
              <Grid item lg={6} xs={12}>
                <h3>{`Order Placed on ${this.state.months[new Date(_.get(this.props.order, "order_time", "")).getMonth()]} ${new Date(_.get(this.props.order, "order_time", "")).getDate()}`}</h3>
                <p>Order #{this.props.orderId}</p>
              </Grid>
              <Grid item lg={4} xs={8}>
                <h3>{this.state.kitchenName || "Kitchen not found"}</h3>
                <p>{this.state.address}</p>
              </Grid>
              <Grid item lg={2} xs={4}>
                <h3>Total</h3>
                <p>${_.get(this.props.order, "totalAmount", "0")}</p>
              </Grid>
            </Grid>
          </div>

          <div style={{backgroundColor: "white", padding: 30}}>
            <Grid items container spacing={32}>

                {
                  _.map(_.get(this.props.order, "dishes", []), dish => {
                    return <Grid item lg={4} md={6} sm={6} xs={12}>
                      {this.dish(dish)}
                    </Grid>
                  })
                }

            </Grid>
            {
              // this.getKitchensName(_.get(order, "kitchenUserId", ""))
            }
          </div>
      </Card>
    );
  }

}

const condition = authUser =>
  authUser && !!authUser;

PreviousOrders.propTypes = {
  classes: PropTypes.object.isRequired,
  orderDishes: PropTypes.object.isRequired
};

// Dishes.propTypes = {
//   classes: PropTypes.object.isRequired,
//   dishes: PropTypes.array.isRequired,
//   isUpdated: PropTypes.bool.isRequired,
//   dishDetails: PropTypes.object.isRequired,
//   editDish: PropTypes.object.isRequired
// };

const mapStateToProps = (state) => {
  // console.log("orders state", state)
  return {
    orderDishes : state.findFood.orderDishes || []
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentOrder: currentOrder => dispatch({ type: 'GET_CURRENT_ORDER', currentOrder }),
  // postDishes: selectedDishes => dispatch({ type: 'GET_SELECTED_DISHES', selectedDishes }),
})

export default withStyles(styles)
(withAuthorization(condition)
  (connect(mapStateToProps, mapDispatchToProps)
    (withFirebase(PreviousOrders))
  )
);
