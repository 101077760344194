import React                 from 'react';

// define proptypes
import PropTypes             from 'prop-types';

// connect to store
import { connect }           from "react-redux"

// wrapping main component with "withStyles" and passing "styles" const
import { withStyles }        from '@material-ui/core/styles';

// wrapping main component with "withAuthorization" and passing "condition" const
import { withAuthorization } from '../../Session';

// wrapping main component with "withFirebase"
import { withFirebase }      from '../../Firebase';
import firebase              from "firebase";

// needed npm packages
import classnames            from 'classnames';
import _                     from 'lodash'
import { useAsync }          from "react-async"

// material-ui components
import { Grid, Button }      from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';


// sub-components
import AddDish from './AddDish'
import Dish    from './Dish'
import DishDetails from './DishDetails'
import PostForm from './PostForm'

// style
import './style.css';

const styles = theme => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
     transform: 'rotate(180deg)',
  },
  gridList: {
    // height: 450
    width: "90%",
    marginLeft: "5%"
  },
 dishesGrid: {
   // marginLeft: "5%",
    marginTop: 10
 },
 dishesActions: {
    marginTop: 5,
    marginLeft: "50%"
 },
 addDish: {
    display: 'none',
    position: 'fixed',
    zIndex: 1,
    paddingTop: 10,
    paddingBottom: 10,
    left: 0,
    top: 50,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.3)',
  }
});


class Dishes extends React.Component {

  constructor(props) {
    super(props);

    this.state = {windowWidth: 0, windowHeight: 0, loading: false};
  };

  // isUpdated is set to true whenever dish is posted, updated, added, or deleted
  componentWillUpdate(nextProps, nextState) {
    // when isUpdated changed from false to true
    if (nextProps.isUpdated == true && this.props.isUpdated == false) {
      // get updated list of dishes
      this.getDishes()
      // set isUpdated to false
      this.props.postedDish(false)
    }

    if (nextState.windowWidth != this.state.windowWidth) {
      return true
    }
  }

  // called when component renders for the first time
  componentDidMount() {
    this.getDishes()  // gives current list of dishes for current user
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions);
    if (_.size(this.props.dishes)) {
      this.setState({ loading: true })
    }
  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  }

  getDishes = async () => {
    var dishesList = []

    const dishes = await this.props.firebase.dishes()
                        .orderByChild('userId').equalTo(_.get(firebase.auth().currentUser, "uid", ""))
                        .once('value');
    const dishValues = dishes.val()


    _.each(dishValues, (val, key) => {
      dishesList.push(_.merge(val, {id: key})) // adding key as id to each dish
    })

    // sets "dishes" state to "dishesList"
    this.props.setDishes(dishesList)
  }


  render() {
    const { classes } = this.props;

    return (
      <div style={{marginTop: 100}}>
        <div className={classes.dishesActions}>
          <Button variant="contained"  style={{backgroundColor: "#99cc00",
            float: "right", marginRight: this.state.windowWidth < 700 ? "20%" : "12%"}} size="medium"
            onClick={() => {
              document.getElementById("addDish").style.display = "block"}
            }>
            Add new dish
          </Button>
        </div>
        <div className={classes.dishesGrid} style={{height: 700}}>
          <Grid container spacing={32} alignItems="center" className={classes.gridList}>
            {_.map(this.props.dishes, dish => (
              <Grid item xs={12} sm={6} md={4} lg={4}  style={{height: 290}}>
                <Dish dish={dish}/>
              </Grid>
            ))}
          </Grid>
          {
            !_.size(this.props.dishes) && <CircularProgress style={{marginTop: 200, marginLeft: "45%", color: "rgb(153, 204, 0)"}}/>
          }
        </div>
        <div id="addDish" className={classes.addDish}>
          <AddDish isEdit={false}  />
        </div>
        <div id="dishDetails" className={classes.addDish}>
          <DishDetails dishDetails={this.props.dishDetails}  />
        </div>
        <div id="editDish" className={classes.addDish}>
          <AddDish isEdit={true} dish={this.props.dishDetails}/>
        </div>
        <div id="postDish" className={classes.addDish}>
          <PostForm dish={this.props.dishDetails}/>
        </div>
      </div>
    );
  }
}

// condition checks if user is cook
const condition = authUser => authUser && !!authUser.roles && !!authUser.roles['cook'];

Dishes.propTypes = {
  classes: PropTypes.object.isRequired,
  dishes: PropTypes.array.isRequired,
  isUpdated: PropTypes.bool.isRequired,
  dishDetails: PropTypes.object.isRequired,
  editDish: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  // console.log("dishes state", state)
  return {
    dishes : state.dishes.dishes || [],
    isUpdated: state.dishes.isUpdated || false,
    dishDetails: state.dishes.dishDetails || {},
    editDish: state.dishes.editDish || {}
  }
}

const mapDispatchToProps = (dispatch) => ({
  setDishes: dishes => dispatch({ type: 'GET_DISHES', dishes }),
  postedDish: isUpdated => dispatch({ type: 'POST_DISH', isUpdated }),
})

export default withStyles(styles)
(withAuthorization(condition)
  (withFirebase
    (connect(mapStateToProps, mapDispatchToProps)
      (Dishes)
    )
  )
)
