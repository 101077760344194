const INITIAL_STATE = {
  orderDetails: null,
  orderResponse: null,
  orderStatus: null
};

const getOrderDetails = (state, action) => ({
  ...state,
  orderDetails: action.orderDetails,
});

const getOrderResponse = (state, action) => ({
  ...state,
  orderResponse: action.orderResponse,
});

const getOrderStatus = (state, action) => ({
  ...state,
  orderStatus: action.orderStatus,
});

function kitchenOrderReducer(state = INITIAL_STATE, action) {
  console.log("action", action);
  switch (action.type) {
    case 'GET_ORDER_DETAILS': {
      return getOrderDetails(state, action);
    }
    case 'GET_ORDER_RESPONSE': {
      return getOrderResponse(state, action);
    }
    case 'GET_ORDER_STATUS': {
      return getOrderStatus(state, action);
    }
    default:
      return state;
  }
}

export default kitchenOrderReducer;
