import React, { Component, Button } from "react";
// import { Link } from 'react-router-dom';
// import * as ROUTES from '../../../constants/routes';
import { connect }           from "react-redux"
import MobileStoreButton from 'react-mobile-store-button';



class TryTaste extends React.Component {
  render() {
    const iOSUrl = 'https://itunes.apple.com/us/app/all-of-the-lights/id959389722?mt=8';
    const androidUrl =  'https://itunes.apple.com/us/app/all-of-the-lights/id959389722?mt=8';

    return (
      <div id="tryTaste" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2 href="www.tradingtastes.firebase.com/taste">TASTESWAP</h2>
          </div>
          <div className="row">
            {this.props.data
              ? this.props.data.map((d,i) => (
                  <div  key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                    {" "}
                    <i className={d.icon}></i>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
        <br />
        <br />
        <a
          href="#getApp"
          className="btn btn-custom btn-lg page-scroll" style={{width: 300}}
        > Download the app now!
        </a>

        <br /> 
        <br />
        <a
          href="taste"
          className="btn btn-custom5 btn-lg page-scroll"
          style={{width: 300}}
        > Stay on browser

        </a>

      </div>
      
    );
  }
}
const mapStateToProps = (state) => {
  // console.log("dishes state", state)
    return {
    userType : state.nav.userType || ""
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUserType: userType => dispatch({ type: 'GET_USER_TYPE', userType }),
})



export default (connect(mapStateToProps, mapDispatchToProps)(TryTaste));