import React, { Component } from "react";
import {Grid, Paper, Typography, Button } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';
import { palette, spacing, typography } from '@material-ui/system';
import locationIcon from '../Landing/images/location.png';
import timeIcon from '../Landing/images/time.svg';
import deliveryIcon from '../Landing/images/scooter.svg';
import orderIcon from '../Landing/images/order.svg';
import buildIcon from '../Landing/images/build.svg';
import addIcon from '../Landing/images/add.svg';
import tradeIcon from '../Landing/images/trade.svg';
import manageIcon from '../Landing/images/manage.svg';

const styles = theme => ({
  rootMain: {
    padding: theme.spacing(3, 2),
    width: "100%"
  },
  root: {
    // flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    paperShadow: 0,
  },
  button: {
    marginLeft: '20%',
  },
});

class HowItWorks extends Component {
  render() {
    const classes = this.props;
    return (
      <div className={classes.root} style={{width: "100%"}}>
      <Paper className={classes.rootMain}>
      <br/>
      <br/>
        <Typography variant="h4" align="center"> HOW IT WORKS </Typography>
        <br/>
        <br/>
        <Button size="large" variant="outlined" className={classes.button} style={{marginLeft: 40, color: "yellowgreen", borderColor: "yellowgreen"}}>
          Try Tastes
      </Button>
        <br/>
        <br/>
        <br/>
        <br/>
        <Grid container spacing={3} style={{padding: 0, margin: 0}}>
          <Grid item xs>
            <img
            src={locationIcon}
            style={{width: "60%", height: "auto", marginLeft: "20%"}}/>
              <Typography align="center" variant="subtitle1">
                Enter your current location <br/>
              </Typography>
              {/* <Typography variant="subtitle1" align="center">
              You could enter your current location by clicking on the location icon,
               which will prompt you to allow us for access to your location.
               You could also enter your city name or zipcode in the box
              </Typography> */}
          </Grid>
          <Grid item xs>
            <img
            src={timeIcon}
            style={{width: "60%", height: "auto", marginLeft: "20%"}}/>
              <Typography align="center" variant="subtitle1">
               Choose a time
              </Typography>
              {/* <Typography variant="subtitle1" align="center">
                Get a fresh dish from your neighbor's kitchen by selecing the
                As Soon As Possible (ASAP) option or schedule your order by
                selecting the Schedule option.
              </Typography> */}
          </Grid>
          <Grid item xs>
            <img
            src={orderIcon}
            style={{width: "60%", height: "auto", marginLeft: "20%"}}/>
              <Typography align="center" variant="subtitle1">
                Order from your local cuisine <br/>
              </Typography>
              {/* <Typography variant="subtitle1" align="center">
                Ordering is very simple. Just choose a kitchen that offers great tastes from
                your favorite cuisine. Then follow the simple steps of the checkout method provided.
              </Typography> */}
          </Grid>
          <Grid item xs>
            <img
            src={deliveryIcon}
            style={{width: "60%", height: "auto", marginLeft: "20%"}}/>
              <Typography align="center" variant="subtitle1">
                Get your order delivered <br/>
              </Typography>
              {/* <Typography variant="subtitle1" align="center">
                Every kitchen is different. Some will offer you a delivery option and others
                will ask you to pick your order up. Watch for your order status, as it gets
                updated instantly.
              </Typography> */}
          </Grid>
        </Grid>
        <br/>
        <br/>
        <br/>
            <Button size="large" variant="outlined"  className={classes.button} style={{marginLeft: 40, color: "darkorange", borderColor: "darkorange"}}>
              Trade Tastes
          </Button>
        <br/>
        <br/>
        <br/>
        <br/>
        <Grid container spacing={3} style={{padding: 0, margin: 0}}>
          <Grid item xs>
            <img
            src={buildIcon}
            style={{width: "60%", height: "auto", marginLeft: "20%"}}/>
              <Typography align="center" variant="subtitle1">
                Build a virtual kitchen <br/>
              </Typography>
              {/* <Typography variant="subtitle1" align="center">
              You could enter your current location by clicking on the location icon,
               which will prompt you to allow us for access to your location.
               You could also enter your city name or zipcode in the box
              </Typography> */}

          </Grid>
          <Grid item xs>
            <img
            src={addIcon}
            style={{width: "60%", height: "auto", marginLeft: "20%"}}/>
              <Typography align="center" variant="subtitle1">
               Add your dishes
              </Typography>
              {/* <Typography variant="subtitle1" align="center">
                Get a fresh dish from your neighbor's kitchen by selecing the
                As Soon As Possible (ASAP) option or schedule your order by
                selecting the Schedule option.
              </Typography> */}
          </Grid>
          <Grid item xs>
            <img
            src={tradeIcon}
            style={{width: "60%", height: "auto", marginLeft: "20%"}}/>
              <Typography align="center" variant="subtitle1">
                Post your dish to trade your taste  <br/>
              </Typography>
              {/* <Typography variant="subtitle1" align="center">
                Ordering is very simple. Just choose a kitchen that offers great tastes from
                your favorite cuisine. Then follow the simple steps of the checkout method provided.
              </Typography> */}
          </Grid>
          <Grid item xs>
            <img
            src={manageIcon}
            style={{width: "60%", height: "auto", marginLeft: "20%"}}/>
              <Typography align="center" variant="subtitle1">
                Manage your orders <br/>
              </Typography>
              {/* <Typography variant="subtitle1" align="center">
                Every kitchen is different. Some will offer you a delivery option and others
                will ask you to pick your order up. Watch for your order status, as it gets
                updated instantly.
              </Typography> */}
          </Grid>
        </Grid>
        <br/>
        <br/>
      </Paper>
    </div>
    );
  }
}

export default HowItWorks;
