import React from 'react'
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';
import { connect }           from "react-redux"
import PropTypes from 'prop-types'
import STRIPE_PUBLISHABLE from '../../../constants/stripe';
import PAYMENT_SERVER_URL from '../../../constants/server';
import Button from '@material-ui/core/Button'

const CURRENCY = 'USD';

const fromEuroToCent = amount => amount * 100;

const successPayment = data => {
  alert('Payment Successful');
};

const errorPayment = data => {
  alert('Payment Error');
};

const onToken = (amount, description) => token =>
  axios.post(PAYMENT_SERVER_URL,
    {
      description,
      source: token.id,
      currency: CURRENCY,
      amount: fromEuroToCent(amount)
    })
    .then(successPayment)
    .catch(errorPayment);

class Checkout extends React.Component {
  onToken = (amount, description) => token =>
    axios.post(PAYMENT_SERVER_URL,
      {
        description,
        source: token.id,
        currency: CURRENCY,
        amount: fromEuroToCent(amount)
      })
      .then(this.props.setPaymentStatus("PaymentSuccess"))
      .catch(errorPayment);

  componentDidMount() {
    if (this.props.paymentMethod == "card") {
      document.getElementById("openStripe").click();
      // console.log("openStripe");
    }
  }

  render() {
    return (<StripeCheckout
      name={this.props.name}
      description={this.props.description}
      amount={fromEuroToCent(this.props.amount)}
      token={this.onToken(this.props.amount, this.props.description)}
      currency={CURRENCY}
      stripeKey={STRIPE_PUBLISHABLE}>

      <Button id="openStripe" className="btn btn-primary" style={{backgroundColor: '#2ab22a'}}>
        Add Card
      </Button>
    </StripeCheckout>
    )
  }
}
// = ({ name, description, amount }) =>
//   <StripeCheckout
//     name={name}
//     description={description}
//     amount={fromEuroToCent(amount)}
//     token={onToken(amount, description)}
//     currency={CURRENCY}
//     stripeKey={STRIPE_PUBLISHABLE}
//   />

Checkout.propTypes = {
  paymentStatus: PropTypes.string.isRequired
}

const mapStateToProps = ( state ) => ({
  paymentStatus: state.clientOrder.paymentStatus || "",
  paymentMethod: state.clientOrder.paymentMethod || ""
})

const mapDispatchToProps = ( dispatch ) => ({
  setPaymentStatus: paymentStatus => dispatch({ type: "GET_PAYMENT_STATUS", paymentStatus})
})

export default (connect(mapStateToProps, mapDispatchToProps)(Checkout));
