import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import {Grid, Button, Typography, TextField, Card} from '@material-ui/core'

const styles = theme => ({
    margin: {
        margin: theme.spacing.unit * 2,

    },
    padding: {
        padding: theme.spacing.unit * 2 ,
        marginTop: 500,
    },
    root: {
      flexGrow: 1,
      width: "100%",
      height: "100%"
    },
    form: {
      textAlign: 'center',
      // maxWidth: 450,
      // minWidth: 300,
      // marginLeft: "35%",
      // padding: 20,
      marginTop: 100,
      width: "80%",
      margin: "5%",
      marginLeft: "10%",
      marginRight: "10%",
    },
    font: {
      variant: 'h1',
    },
});

const SignUpPage = () => (

    <SignUpForm />
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { username, email, passwordOne } = this.state;
    const roles = {}
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        roles[ROLES.CONSUMER] = true
        // console.log("authuser", authUser, roles);
        this.props.firebase
          .user(authUser.user.uid)
          .set({
            username,
            email,
            roles
          })
          .then((user) => {
            // console.log("signup with email", authUser.user);
            this.setState({ ...INITIAL_STATE });
            this.props.history.push(ROUTES.HOME);
          })
          .catch(error => {
            this.setState({ error });
            // console.log("error", error);
          });
      })
      .catch(error => {
        this.setState({ error });
        // console.log("error", error);

      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const { classes } = this.props;


    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

    return (

      <div className={classes.root} style={{width: "100%", height: 700}}>
        <Grid items container spacing={32}>
          <Grid item lg={7} md={6} sm={5} xs={0} style={{padding: 0}}>
            <div style={{width: "100%", height: 700, backgroundImage: "url(https://www.foodethicscouncil.org/app/uploads/2019/01/sharing-plates-rawpixel-754045-unsplash-600x400.jpg)", backgroundSize: "cover"}}>
              <div style={{width: "100%", height: 700, backgroundColor: "rgb(179, 71, 0, 0.4)"}}>
                <Typography variant="display1" style={{fontSize: 70, paddingTop: 200, paddingLeft: 50, color: "white"}}>
                  Find Homemade food near you
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item lg={5} md={6} sm={7} xs={12} style={{padding: 0}}>
            <Card style={{width: "100%", height: 700, marginTop: 30}}>
              <div className={classes.form}>
                  <h2 style={{textAlign: "center"}}>Sign up </h2>
                  <TextField
                      id="username"
                      name="username"
                      value={username}
                      onChange={this.onChange}
                      type="text"
                      placeholder="Username"
                      style={{margin: 20}}
                      required
                      fullWidth required
                    />
                    <TextField
                      name="email"
                      value={email}
                      onChange={this.onChange}
                      type="text"
                      style={{margin: 20}}
                      placeholder="Email Address"
                      required
                      fullWidth required
                    />
                  <TextField
                      name="passwordOne"
                      value={passwordOne}
                      onChange={this.onChange}
                      type="password"
                      placeholder="Password"
                      style={{margin: 20}}
                      required
                      fullWidth required
                    />
                  <TextField
                      name="passwordTwo"
                      value={passwordTwo}
                      onChange={this.onChange}
                      type="password"
                      placeholder="Confirm Password"
                      required
                      style={{margin: 20}}
                      fullWidth required
                    />
                  <Grid items container spacing={32}>
                    <Grid item xs={6}>
                      <Button style={{backgroundColor: "orange", width: "90%"}}>
                          Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button disabled={isInvalid} type="submit" onClick={this.onSubmit} style={{backgroundColor: "#9BD238",  width: "90%"}}>
                        Sign Up
                      </Button>
                    </Grid>
                  </Grid>

                {error && <p style={{color: "red"}}>{error.message}</p>}

                </div>
              </Card>
            </Grid>
            </Grid>


        </div>
    );
  }
}

// <form onSubmit={this.onSubmit}>
//   <input
//     name="username"
//     value={username}
//     onChange={this.onChange}
//     type="text"
//     placeholder="Full Name"
//   />
//   <input
//     name="email"
//     value={email}
//     onChange={this.onChange}
//     type="text"
//     placeholder="Email Address"
//   />
//   <input
//     name="passwordOne"
//     value={passwordOne}
//     onChange={this.onChange}
//     type="password"
//     placeholder="Password"
//   />
//   <input
//     name="passwordTwo"
//     value={passwordTwo}
//     onChange={this.onChange}
//     type="password"
//     placeholder="Confirm Password"
//   />
//   <button disabled={isInvalid} type="submit">
//     Sign Up
//   </button>
//
//   {error && <p>{error.message}</p>}
// </form>

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

SignUpFormBase.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

const SignUpForm = (withStyles(styles))(withRouter(withFirebase(SignUpFormBase)));

export default SignUpPage;

export { SignUpForm, SignUpLink };
