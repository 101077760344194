import React from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux'
import PropTypes             from 'prop-types';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const options = [
  'Self-pickup',
  'Kitchen Delivery',
  '3rd Party(i.e UberEats)',
];

function getStyles(name, deliveryOption, theme) {
  return {
    fontWeight:
      deliveryOption.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function MultipleSelect(props) {
  const [deliveryOption, setDeliveryOption] = React.useState([]);

  function handleChange(event) {
    // console.log("multiselect", event.target.value)

    setDeliveryOption(event.target.value);

    props.setDeliveryMethods(event.target.value)
  }

  function handleChangeMultiple(event) {
    const { options } = event.target;
    // console.log("multiselect", event.target)
    const value = props.values;
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setDeliveryOption(value);
  }

  return (
    <div>

      <FormControl>
        <InputLabel htmlFor="select-multiple-checkbox">Delivery</InputLabel>
        <Select
          multiple
          value={deliveryOption}
          onChange={handleChange}
          input={<Input id="select-multiple-checkbox" />}
          renderValue={selected => selected.join(', ')}
          MenuProps={MenuProps}
          style={{width: 350}}
        >
          {props.options.map(name => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={deliveryOption.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

// AddDish.propTypes = {
//   classes: PropTypes.object.isRequired,
//   // isUpdated: PropTypes.bool.isRequired,
//   history: PropTypes.object.isRequired
// };

const mapStateToProps = (state) => {
  // console.log("post dishes state", state)
  return {
    // isUpdated : state.dishes.isUpdated || false,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setDeliveryMethods: deliveryMethods => dispatch({ type: 'GET_DELIVERY_METHODS', deliveryMethods }),
})

export default (connect(mapStateToProps, mapDispatchToProps)(MultipleSelect));
