import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withAuthorization } from '../../Session';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Card, Paper, Button, Grid, Typography, Avatar, Fab } from '@material-ui/core';
import { connect }           from "react-redux"
import { Close } from '@material-ui/icons'
import _ from 'lodash'
import firebase from "firebase";
import { withFirebase } from '../../Firebase';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 2,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  card: {
    width: "70%",
    // minHeight: 500,
    marginLeft: "15%",
    marginTop: 30
  },
  close: {
    color: '#aaaaaa',
    float: 'right',
    fontSize: 40,
    fontWeight: 'bold',
    marginRight: 20,
    marginTop: 10,
    '&:hover': {
      color: '#000',
      textDecoration: 'none',
      cursor: 'pointer',
    }

  },
});

class OrderDetails extends React.Component {

  constructor(props) {
    super(props)

    this.state = {windowWidth: 0, windowHeight: 0}
  }

  componentDidMount() {
    // console.log("orderDetails called", this.props.orderDetails);
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUpdate(nextProps, nextState) {
    // console.log("orderDetails updated", _.size(nextProps.orderDetails), _.size(this.props.orderDetails));
    if (_.size(nextProps.orderDetails) != _.size(this.props.orderDetails)) {
      // document.getElementById("orderDetails").style.display = "block"
      return true
    }
    if (nextState.windowWidth != this.state.windowWidth) {
      return true
    }
  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  }


  orderDetails() {
    return <div style={{padding: 10, overflowY: "scroll", height: 420}}>
      <Grid items container spacing={32}>
        <Grid item lg={6} md={6} sm={12}>
          <Typography variant="subheading">
            Order id: {_.get(this.props.orderDetails, "orderId", "")}
          </Typography>
          <Typography variant="subheading">
            Ordered at {_.get(this.props.orderDetails, "order_time", 0)}
          </Typography>
          <br/>
          <Typography variant="subheading">
            Pay Method: {_.get(this.props.orderDetails, "paymentMethod", 0)}
          </Typography>
          <Typography variant="subheading">
            {_.get(this.props.orderDetails, "deliveryAddress", "") ? _.get(this.props.orderDetails, "deliveryAddress", "") : ""}
          </Typography>
          <Typography variant="subheading">
            Rating: {_.get(this.props.orderDetails, "rating", "")}
          </Typography>
          <Typography variant="subheading">
            Comment : {_.get(this.props.orderDetails, "comment", "")}
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12}>
          <Typography variant="subheading">
            Total: ${_.get(this.props.orderDetails, "totalAmount", "")}
          </Typography>
          <br/>
          <Grid items container spacing={32}>
            <Grid item lg={6}>
            { _.map(_.get(this.props.orderDetails, "dishes", []), (dish, key) => {
              // console.log("dish value", dish);
              return <Grid items container spacing={32}>
                <Grid item lg={6}>
                  <Avatar src={_.get(dish, "avatarURL", "")} style={{width: 100, height: 100}}/>
                </Grid>
                <Grid item lg={6}>
                  <Typography variant="subheading">
                    {_.get(dish, "dishName", "")}, ({_.get(dish, "orderServings", "1")}), ${_.get(dish, "price", "")}
                  </Typography>
                </Grid>
              </Grid>
            })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  }

  render() {
    const { classes } = this.props

    return (
      <div style={{marginTop: 100}}>
        <Card className={classes.card} style={{width: this.state.windowWidth < 700 ? "90%" : "70%", marginLeft: this.state.windowWidth < 700 ? "5%" : "15%"}}>
          <Fab aria-label="Close" className={classes.fab} size="small"
            style={{float: "right", backgroundColor: "white",
            position: "relative", marginTop: 10, marginRight: 10}}
            onClick={() => {
              document.getElementById("orderDetails").style.display = "none"
              this.props.setOrderDetails({})
            }}>
            <Close />
          </Fab>
        <h3 style={{padding: 10}}>Order Details</h3>
        {this.orderDetails()}
      </Card>
      </div>
    )
  }
}

const condition = authUser => authUser && !!authUser.roles && !!authUser.roles['cook'];


OrderDetails.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  // console.log("previousOrders state", state)
  return {
    orderDetails : state.kitchenOrder.orderDetails || {},
    orderStatus : state.kitchenOrder.orderStatus || "",
  }
}

const mapDispatchToProps = (dispatch) => ({
  setOrderDetails: orderDetails => dispatch({ type: 'GET_ORDER_DETAILS', orderDetails }),
})

export default withStyles(styles)
(withFirebase
  (withAuthorization(condition)
    (connect(mapStateToProps, mapDispatchToProps)
      (OrderDetails)
    )
  )
)

// export default withStyles(styles)(OrderDetails)
