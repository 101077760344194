// import React from 'react';
// import { Link } from 'react-router-dom';
// import SignOutButton from '../SignOut';
// import { SignOutBtn } from '../SignOut';
//
// import * as ROUTES from '../../constants/routes';
// import * as ROLES from '../../constants/roles';
// import { connect }           from "react-redux"
// import { AuthUserContext } from '../Session';
// import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
// import Icon from '@material-ui/core/Icon';
// import MenuIcon from '@material-ui/icons/Menu';
// import Landing from '../Landing/index'
// import _ from 'lodash'
// import { withFirebase } from '../Firebase';
// import firebase from 'firebase'
//
//
// import MenuItem from '@material-ui/core/MenuItem';
// import Menu from '@material-ui/core/Menu';
// import AccountCircle from '@material-ui/icons/AccountCircle';
// import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
// import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
// import Badge from '@material-ui/core/Badge';
//
//
// import Avatar from '@material-ui/core/Avatar';
// import {ListItemIcon, ListItemText, Popover} from "@material-ui/core"
// import { useAsync } from "react-async"
//
// import dishIcon from '../../static/images/dish.png'
// import cookIcon from '../../static/images/cook.png'
//
// import Logo from '../../static/images/logo.svg'
//
// const styles = theme => ({
//   root: {
//     flexGrow: 1,
//   },
//   grow: {
//     flexGrow: 1,
//   },
//   menuButton: {
//     marginLeft: -5,
//     marginRight: 20,
//   },
//   link: {
//     textDecoration: "none",
//     color: "white"
//   },
//   avatar: {
//     margin: 10,
//   },
//   padding: {
//     padding: `0 ${theme.spacing.unit * 2}px`,
//   },
//   sectionDesktop: {
//     display: 'none',
//     [theme.breakpoints.up('md')]: {
//       display: 'flex',
//     },
//   },
// });
//
// // <Link to={_.get(authUser.roles, `${ROLES.COOK}`, false) ?
// //     ROUTES.HOME : ROUTES.TASTE }  style={{textDecoration: "none", color: "white"}}>
//
//
// class Navigation  extends React.Component {
//
//
//     constructor(props) {
//       super(props)
//
//       this.state = {
//         appbarColor: "rgb(241, 119, 33)", orders: 0
//       };
//     }
//
//   componentWillUpdate(nextProps, nextState) {
//     if (nextProps.userType != this.props.userType) {
//       if (nextProps.userType === "tryTaste") {
//         this.setState({ appbarColor: "rgba(42, 178, 42, 0.1)"})
//       } else {
//         this.setState({ appbarColor: "rgb(241, 119, 33)"})
//       }
//     }
//
//     if (nextProps.orderUpdated != this.props.orderUpdated) {
//       this.setState({ orders: _.size(nextProps.orderDishes)})
//     }
//   }
//
//   render () {
//     const { classes } = this.props;
//     return (
//       <div className={classes.root}>
//         <AuthUserContext.Consumer>
//               {
//                 authUser =>
//                      <AppBar position="fixed"
//                        style={{backgroundColor: authUser &&  _.get(_.get(authUser, "roles", {}), `${ROLES.COOK}`, false) ? "rgb(241, 119, 33)" : "rgba(42, 178, 42, 0.0)", boxShadow: "none"}}>
//                        <Toolbar>
//                          <Button color="inherit" style={{float: "left"}}>
//                            <div style={{width: 30, height: 30, marginRight: 5}}>
//                              <img src={Logo} style={{width: 30, height: 30}}/>
//                            </div>
//                            <Link to={"/"} style={{textDecoration: "none", color: "black"}}>
//                              Trading Tastes
//                            </Link>
//                          </Button>
//                          <div className={classes.selectionDesktop}>
//
//                          {console.log("userType", this.props.userType, authUser, _.get(_.get(authUser, "roles", {}), `${ROLES.COOK}`, false))}
//
//                           {
//                             authUser ?
//                                _.get(_.get(authUser, "roles", {}), `${ROLES.COOK}`, false) ?
//                                  <NavigationAuth
//                                    menuItems={[{route: ROUTES.HOME, name: "My Kitchen"},
//                                                {route: ROUTES.DISHES, name: "DISHES"},
//                                                {route: ROUTES.ORDERS, name: "Orders"}]}
//                                    profileRoute={ROUTES.KITCHEN_ACCOUNT}
//                                    user={authUser} />
//                                  :
//                                  <NavigationAuth
//                                     menuItems={[{route: ROUTES.FIND_FOOD, name: "Find Kitchen"},
//                                                 {route: ROUTES.CLIENT_ORDER, name: "ORDER", badge: this.state.orders }]}
//                                     profileRoute={ROUTES.ACCOUNT}/>
//                               :
//                               this.props.userType === "tryTaste" ?
//                                 <NavigationTryNonAuth/>
//                                 :
//                                 this.props.userType === "tradeTaste" ?
//                                   <NavigationTradeNonAuth/>
//                                   :
//                                   <NavigationTryOrTrade/>
//                             }
//                           </div>
//                        </Toolbar>
//                      </AppBar>
//                }
//
//         </AuthUserContext.Consumer>
//
//       </div>
//     );
//   }
// }
//
//
//
//
// // class NavigationTradeAuth extends React.Component {
// //
// //   constructor(props) {
// //     super(props)
// //
// //     this.state = {
// //       anchorEl: null,
// //       first_name: "",
// //       last_name: ""
// //     };
// //   }
// //
// //   handleMenu = event => {
// //     this.setState({ anchorEl: event.currentTarget });
// //   };
// //
// //   handleClose = () => {
// //     this.setState({ anchorEl: null });
// //   };
// //
// //   componentDidMount() {
// //     // this.getUserName()
// //   }
// //
// //   // getUserName = async () => {
// //   //   console.log("user id", _.get(firebase.auth().currentUser, "uid", ""));
// //   //   const user = await this.props.firebase
// //   //                       .user(_.get(firebase.auth().currentUser, "uid", ""))
// //   //                       .once('value');
// //   //
// //   //   const userValues = _.get(user, "val", []);
// //   //   console.log("user", userValues);
// //   //
// //   //   this.setState({first_name: _.get(userValues, "first_name", ""),
// //   //                   last_name: _.get(userValues, "last_name", "")})
// //   // }
// //   render () {
// //     const { classes } = this.props;
// //     const { anchorEl } = this.state;
// //     const open = Boolean(anchorEl);
// //     return (
// //       <div>
// //           <Button color="inherit">
// //             <Link to={ROUTES.HOME} style={{textDecoration: "none", color: "white"}}>My Kitchen </Link>
// //           </Button>
// //
// //           <Button color="inherit">
// //             <Link to={ROUTES.DISHES} style={{textDecoration: "none", color: "white"}}>Dishes</Link>
// //           </Button>
// //
// //           <Button color="inherit">
// //             <Link to={ROUTES.ORDERS} style={{textDecoration: "none", color: "white"}}>Orders</Link>
// //           </Button>
// //           <Button color="inherit" onClick={this.handleMenu}>
// //             {
// //               _.get(firebase.auth().currentUser, "photoURL", "") ?
// //               <Avatar alt="Remy Sharp" src={_.get(firebase.auth().currentUser, "photoURL", "http://cdn.onlinewebfonts.com/svg/img_403083.png")}/> :
// //               <AccountCircle />
// //             }
// //             &nbsp;&nbsp;
// //             <div className="hidden md:flex flex-col ml-12 items-start">
// //               <Typography component="span" style={{color: "white", fontFamily:  "sans-serif"}}>
// //                 {this.state.first_name} {this.state.last_name}
// //               </Typography>
// //             </div>
// //             <KeyboardArrowDown/>
// //           </Button>
// //           <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={this.handleClose}
// //             anchorOrigin={{ vertical : "bottom", horizontal : "center" }}
// //             transformOrigin={{ vertical : "top", horizontal : "center" }} classes={{ paper : "py-8" }}>
// //             <React.Fragment>
// //               <MenuItem component={Link} to={ROUTES.KITCHEN_ACCOUNT}>
// //                 <ListItemIcon>
// //                   <AccountCircle />
// //                 </ListItemIcon>
// //                 <ListItemText className="pl-0" primary="Profile" />
// //               </MenuItem>
// //               <SignOutButton />
// //             </React.Fragment>
// //           </Popover>
// //       </div>
// //   );
// //   }
// // }
//
//
// // <ListItemText className="pl-0" primary="Logout" />
//
// class NavigationAuth extends React.Component {
//
//   constructor(props) {
//     super(props)
//
//     this.state = {
//       anchorEl: null,
//       first_name: "",
//       last_name: ""
//     };
//   }
//
//   handleMenu = event => {
//     this.setState({ anchorEl: event.currentTarget });
//   };
//
//   handleClose = () => {
//     this.setState({ anchorEl: null });
//   };
//
//   componentDidMount() {
//     this.getUserName()
//   }
//
//   getUserName = async () => {
//     console.log("user id", _.get(firebase.auth().currentUser, "uid", ""), firebase.auth().currentUser);
//     // const user = await this.props.firebase
//     //                     .user(_.get(firebase.auth().currentUser, "uid", ""))
//     //                     .once('value');
//     //
//     // const userValues = _.get(user, "val", []);
//     // console.log("user", userValues);
//
//     this.setState({first_name: _.get(this.props.user, "first_name", ""),
//                     last_name: _.get(this.props.user, "last_name", "")})
//   }
//
//   render () {
//     const { classes } = this.props;
//     const { anchorEl } = this.state;
//     const open = Boolean(anchorEl);
//     return (
//       <div>
//           {_.map(this.props.menuItems, menuItem => {
//             if (menuItem.badge) {
//               return <Button color="inherit">
//                 <Link to={menuItem.route} style={{textDecoration: "none", color: "white"}}>
//                   <Badge badgeContent={menuItem.badge} color="secondary">
//                     {menuItem.name}
//                   </Badge>
//                 </Link>
//               </Button>
//             } else {
//               return <Button color="inherit">
//                 <Link to={menuItem.route} style={{textDecoration: "none", color: "white"}}> {menuItem.name} </Link>
//               </Button>
//             }
//           })}
//
//           <Button color="inherit" onClick={this.handleMenu}>
//             {
//               _.get(firebase.auth().currentUser, "photoURL", "") ?
//               <Avatar alt="Remy Sharp" src={_.get(firebase.auth().currentUser, "photoURL", "http://cdn.onlinewebfonts.com/svg/img_403083.png")}/> :
//               <AccountCircle />
//             }
//             &nbsp;&nbsp;
//             <div className="hidden md:flex flex-col ml-12 items-start">
//               <Typography component="span" style={{color: "white", fontFamily:  "sans-serif"}}>
//                 {this.state.first_name} {this.state.last_name}
//               </Typography>
//             </div>
//             <KeyboardArrowDown/>
//           </Button>
//           <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={this.handleClose}
//             anchorOrigin={{ vertical : "bottom", horizontal : "center" }}
//             transformOrigin={{ vertical : "top", horizontal : "center" }} classes={{ paper : "py-8" }}>
//             <React.Fragment>
//               <MenuItem component={Link} to={this.props.profileRoute}>
//                 <ListItemIcon>
//                   <AccountCircle />
//                 </ListItemIcon>
//                 <ListItemText className="pl-0" primary="Profile" />
//               </MenuItem>
//               <SignOutButton />
//             </React.Fragment>
//           </Popover>
//       </div>
//   );
//   }
// }
//
//
// const NavigationTradeNonAuth = () => {
//   return (
//   <div>
//     <Button color="inherit">
//       <Link to={ROUTES.TASTE}  style={{textDecoration: "none", color: "white"}}>Login</Link>
//     </Button>
//     <Button color="inherit">
//       <Link to={ROUTES.BUILD_KITCHEN} style={{textDecoration: "none", color: "white"}}> Build Your Kitchen </Link>
//     </Button>
//   </div>
// );
// }
//
// // const Landing = () => {
// //   return (
// //   <div>
// //     <Button color="inherit">
// //       <Link to={ROUTES.TASTE}  style={{textDecoration: "none", color: "white"}}>Login</Link>
// //     </Button>
// //     <Button color="inherit">
// //       <Link to={ROUTES.BUILD_KITCHEN} style={{textDecoration: "none", color: "white"}}> Build Your Kitchen </Link>
// //     </Button>
// //   </div>
// // );
// // }
//
// const NavigationTryAuth = (props) => {
//   return (
//   <div>
//     <Button color="inherit">
//       <Link to={ROUTES.FIND_FOOD}  style={{textDecoration: "none", color: "white"}}>Find Food</Link>
//     </Button>
//     <Button color="inherit">
//       <Link to={ROUTES.FIND_FOOD} style={{textDecoration: "none", color: "white"}}>
//         <Badge badgeContent={props.badgeNo} color="primary">
//           Orders
//         </Badge>
//       </Link>
//     </Button>
//     <Button color="inherit">
//       <Link to={ROUTES.ACCOUNT} style={{textDecoration: "none", color: "white"}}> My Account </Link>
//     </Button>
//   </div>
// );
// }
//
// const NavigationTryNonAuth = () => {
//   return (
//   <div>
//     <Button color="inherit">
//       <Link to={ROUTES.TASTE}  style={{textDecoration: "none", color: "white"}}>Login</Link>
//     </Button>
//     <Button color="inherit">
//       <Link to={ROUTES.SIGN_UP} style={{textDecoration: "none", color: "white"}}> Signup </Link>
//     </Button>
//   </div>
// );
// }
//
// const NavigationTryOrTrade = () => {
//   return (
//   <div>
//     <Button color="inherit">
//       <div style={{width: 30, height: 30, marginRight: 5}}>
//         <img src={dishIcon} style={{width: 30, height: 30}}/>
//       </div>
//       <Link to={ROUTES.TASTE}  style={{textDecoration: "none", color: "black"}}>Try Taste</Link>
//     </Button>
//     <Button color="inherit">
//       <div style={{width: 45, height: 45, marginRight: 5}}>
//         <img src={cookIcon} style={{width: 45, height: 45}}/>
//       </div>
//       <Link to={ROUTES.TRADETASTES} style={{textDecoration: "none", color: "black"}}> Trade Taste</Link>
//     </Button>
//   </div>
// );
// }
//
//
//
// const NavigationTryOrTradeAuth = () => {
//   return (
//   <div>
//     <Button color="inherit">
//       <Link to={ROUTES.TASTE}  style={{textDecoration: "none", color: "white"}}>Try Taste</Link>
//     </Button>
//     <Button color="inherit">
//       <Link to={ROUTES.TRADETASTES} style={{textDecoration: "none", color: "white"}}> Trade Taste</Link>
//     </Button>
//     <SignOutBtn/>
//   </div>
// );
// }
//
// Navigation.propTypes = {
//   classes: PropTypes.object.isRequired,
//   userType: PropTypes.string.isRequired,
//   orderDishes: PropTypes.array.isRequired
// };
//
// const mapStateToProps = (state) => {
//   console.log("dishes state", state)
//   return {
//     userType : state.nav.userType || "",
//     orderDishes: state.findFood.orderDishes || [],
//     orderUpdated: state.findFood.orderUpdated || null,
//
//   }
// }
//
// const mapDispatchToProps = (dispatch) => ({
//   setDishes: userType => dispatch({ type: 'GET_USER_TYPE', userType }),
// })
//
// export default withStyles(styles)
// (withFirebase
//   (connect(mapStateToProps, mapDispatchToProps)
//     (Navigation)
//   )
// )

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { fade } from '@material-ui/core/styles/colorManipulator';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { AuthUserContext } from '../Session';

import { Link } from 'react-router-dom';
import SignOutButton from '../SignOut';
import { SignOutBtn } from '../SignOut';

import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { connect }           from "react-redux"
import Button from '@material-ui/core/Button';
import Landing from '../Landing/index'
import _ from 'lodash'
import { withFirebase } from '../Firebase';
import firebase from 'firebase'
import {ListItemIcon, ListItemText, Popover} from "@material-ui/core"
import dishIcon from '../../static/images/dish.png'
import cookIcon from '../../static/images/cook.png'

import Logo from '../../static/images/logo.svg'

import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Avatar from '@material-ui/core/Avatar';

import findFood from '../../static/images/howItWorks/findFood.png'
import clientOrder from '../../static/images/howItWorks/clientOrder.ico'

import cook from '../../static/images/howItWorks/cook.png'
import cooking from '../../static/images/howItWorks/cooking.png'
import header from '../Landing/components/header'


const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 2,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: 7,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: 3,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

class NavigationAuth extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      anchorEl: null,
      first_name: "",
      last_name: "",
      is_visible: false,

    };
  }

  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }



  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  componentDidMount() {

    var scrollComponent = this;
    document.addEventListener("scroll", function(e) {
      scrollComponent.toggleVisibility();
    });
    this.getUserName()
  }

  getUserName = async () => {
    // console.log("user id", _.get(firebase.auth().currentUser, "uid", ""), firebase.auth().currentUser);
    // const user = await this.props.firebase
    //                     .user(_.get(firebase.auth().currentUser, "uid", ""))
    //                     .once('value');
    //
    // const userValues = _.get(user, "val", []);
    // console.log("user", userValues);

    this.setState({first_name: _.get(this.props.user, "first_name", ""),
                    last_name: _.get(this.props.user, "last_name", "")})
  }

  render () {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const { is_visible } = this.state;

    const open = Boolean(anchorEl);
    return (
      <div>
          {_.map(this.props.menuItems, menuItem => {
            if (menuItem.badge) {
              return <Button color="inherit">
                <div style={{width: 30, height: 30, marginRight: 5}}>
                  <img src={menuItem.icon} style={{width: 30, height: 30}}/>
                </div>
                <Link to={menuItem.route} style={{textDecoration: "none", color: "black"}}>
                  <Badge badgeContent={menuItem.badge} color="secondary">
                    {menuItem.name}
                  </Badge>
                </Link>
              </Button>
            } else {
              return <Button color="inherit">
                <div style={{width: 30, height: 30, marginRight: 5}}>
                  <img src={menuItem.icon} style={{width: 30, height: 30}}/>
                </div>
                <Link to={menuItem.route} style={{textDecoration: "none", color: "black"}}> {menuItem.name} </Link>
              </Button>
            }
          })}

          <Button color="inherit" onClick={this.handleMenu}>
            {
              _.get(firebase.auth().currentUser, "photoURL", "") ?
              <Avatar alt="Remy Sharp" src={_.get(firebase.auth().currentUser, "photoURL", "http://cdn.onlinewebfonts.com/svg/img_403083.png")}/> :
              <AccountCircle style={{color: "black"}}/>
            }
            &nbsp;&nbsp;
            <div className="hidden md:flex flex-col ml-12 items-start">
              <Typography component="span" style={{color: "black", fontFamily:  "sans-serif"}}>
                {this.state.first_name} {this.state.last_name}
                {!this.state.first_name && !this.state.last_name && _.get(firebase.auth().currentUser, "displayName", "") }
              </Typography>
            </div>
            <KeyboardArrowDown style={{color: "black"}}/>
          </Button>
          <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={this.handleClose}
            anchorOrigin={{ vertical : "bottom", horizontal : "center" }}
            transformOrigin={{ vertical : "top", horizontal : "center" }} classes={{ paper : "py-8" }}>
            <React.Fragment>
              <MenuItem component={Link} to={this.props.profileRoute}>

                  <IconButton
                    aria-label="Account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <p>Profile</p>
              </MenuItem>
              <SignOutButton />
            </React.Fragment>
          </Popover>
      </div>
  );
  }
}

const NavigationTradeNonAuth = () => {
  return (
  <div>
    <Button color="inherit">
      <Link to={ROUTES.TASTE}  style={{textDecoration: "none", color: "white"}}>Login</Link>
    </Button>
    <Button color="inherit">
      <Link to={ROUTES.BUILD_KITCHEN} style={{textDecoration: "none", color: "white"}}> Build Your Kitchen </Link>
    </Button>
  </div>
);
}
const NavigationTryAuth = (props) => {
  return (
  <div>
    <Button color="inherit">

      <Link to={ROUTES.FIND_FOOD}  style={{textDecoration: "none", color: "white"}}>Find Food</Link>
    </Button>
    <Button color="inherit">
      <Link to={ROUTES.FIND_FOOD} style={{textDecoration: "none", color: "white"}}>
        <Badge badgeContent={props.badgeNo} color="primary">
          Orders
        </Badge>
      </Link>
    </Button>
    <Button color="inherit">
      <Link to={ROUTES.ACCOUNT} style={{textDecoration: "none", color: "white"}}> My Account </Link>
    </Button>
  </div>
);
}
const NavigationTryNonAuth = () => {
  return (
  <div>
    <Button color="inherit">
      <Link to={ROUTES.TASTE}  style={{textDecoration: "none", color: "white"}}>Login</Link>
    </Button>
    <Button color="inherit">
      <Link to={ROUTES.SIGN_UP} style={{textDecoration: "none", color: "white"}}> Signup </Link>
    </Button>
  </div>
);
}
const NavigationTryOrTrade = () => {
  return (
  <div>
    <Button color="inherit">
      <div style={{width: 30, height: 30, marginRight: 5}}>
        <img src={dishIcon} style={{width: 30, height: 30}}/>
      </div>
      <Link to={ROUTES.TASTE}  style={{textDecoration: "none", color: "yellowgreen", fontWeight: "bold"}}>Try Taste</Link>
    </Button>
    <Button color="inherit">
      <div style={{width: 45, height: 45, marginRight: 5}}>
        <img src={cookIcon} style={{width: 45, height: 45}}/>
      </div>
      <Link to={ROUTES.TRADETASTES} style={{textDecoration: "none", color: "darkorange", fontWeight: "bold"}}> Trade Taste</Link>
    </Button>
  </div>
);
}
const NavigationTryOrTradeAuth = () => {
  return (
  <div>
    <Button color="inherit">
      <Link to={ROUTES.TASTE}  style={{textDecoration: "none", color: "white"}}>Try Taste</Link>
    </Button>
    <Button color="inherit">
      <Link to={ROUTES.TRADETASTES} style={{textDecoration: "none", color: "white"}}> Trade Taste</Link>
    </Button>
    <SignOutBtn/>
  </div>
);
}

// badge
// icon
// label

const RenderMobileMenu = (props) => (
  <Menu
    anchorEl={props.mobileMoreAnchorEl}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    id={props.mobileMenuId}
    keepMounted
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    open={props.isMobileMenuOpen}
    onClose={props.handleMobileMenuClose}
  >
    {
      _.map(props.menu, menuItem => {
        // console.log("menu ", props.menu, menuItem)
        return <MenuItem component={Link} to={menuItem.route}>
          <IconButton aria-label={menuItem.label} color="inherit">
            <img src={menuItem.icon} style={{width: 30, height: 30}}/>
          </IconButton>
          <p>{menuItem.label}</p>
        </MenuItem>
      })
    }
    {
      props.auth &&
      <div>
      <MenuItem component={Link} to={props.profileRoute}>
        <IconButton
          aria-label="Account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <SignOutButton/>
      </div>
    }
  </Menu>
);


class PrimarySearchAppBar extends React.Component {

  state = {anchorEl: null,
            mobileMoreAnchorEl: null,
            appbarColor: "rgb(241, 119, 33, 0)",
            orders: 0}

  componentDidMount() {
    if (this.props.userType) {
      this.setState({appbarColor: "rgb(241, 119, 33)"})
    }
  }
  componentWillUpdate(nextProps, nextState) {
    if (nextProps.userType != this.props.userType) {
      if (nextProps.userType === "tryTaste") {
        this.setState({ appbarColor: '#88C603'})
      } else if (nextProps.userType === "tradeTaste") {
        this.setState({ appbarColor: "rgb(241, 119, 33)"})
      }
      // else {
      //   this.setState({ appbarColor: "rgb(241, 119, 33)"})
      // }
    }

    if (nextProps.orderUpdated != this.props.orderUpdated) {
      this.setState({ orders: _.size(nextProps.orderDishes)})
    }
  }

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget})
    // setMobileMoreAnchorEl(event.currentTarget);
  }

  handleProfileMenuOpen = (event) => {
    // setAnchorEl(event.currentTarget);
    this.setState({ anchorEl: event.currentTarget})
  }


  handleMobileMenuClose = () => {
      // setMobileMoreAnchorEl(null);
    this.setState({ mobileMoreAnchorEl: null})
  }

  handleMenuClose = () => {
    // setAnchorEl(null);
    this.setState({anchorEl: null})
    this.handleMobileMenuClose();
  }

  render() {
  const { classes } = this.props;
  const { is_visible } = this.state;

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(this.state.anchorEl);
  const isMobileMenuOpen = Boolean(this.state.mobileMoreAnchorEl);


  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={this.state.anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={this.handleMenuClose}
    >
      <MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={this.handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';

  return (
    
    <div className={classes.grow}>
      <AuthUserContext.Consumer>
        {authUser =>
          <div>
      <AppBar position="fixed"
        style={{backgroundColor: authUser ?
                                    _.get(_.get(authUser, "roles", {}), `${ROLES.COOK}`, false) ?
                                            "rgb(241, 119, 33)" : "#88C603"
                                    : "rgb(241, 119, 33, 0)"

          , boxShadow: "none",
          display: authUser ? "flex": "flex",
          width: authUser ? "":"10%",
          marginRight: authUser? "": 1300,
          backgroundImage: authUser ?
                              _.get(_.get(authUser, "roles", {}), `${ROLES.COOK}`, false) ?
                                  'linear-gradient(transparent, orange)' : 'linear-gradient(yellowgreen, transparent)'
                                  : ''
        }}>

        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Open drawer"
            style={{backgroundColor: authUser ? "rgb(0, 0, 0, 0.2)" : "none"}}
          >
            <Link onClick={() => this.scrollToTop()} href="#page-top" to={ROUTES.LANDING} style={{textDecoration: "none", color: authUser ? "#262626" : "", fontWeight: "bold"}}> 
            {/* <img  src={Logo} href="#page-top" style={{width: authUser? (this.props.userType ? "none" : 50): (this.props.userType ? 20 : 100) , 
              height: authUser? (this.props.userType ? 20 : 50) : (this.props.userType ? 20 : 100) }}/> */}
              {this.props.userType ? (
                <img src={Logo} style={{width: this.props.userType ? 30 : 50, height: this.props.userType ? 30 : 50 }}/>
            ) : null}
            
            </Link>

          </IconButton>
          <Typography className={classes.title} variant="h4" noWrap >
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {
            authUser ?
               _.get(_.get(authUser, "roles", {}), `${ROLES.COOK}`, false) ?
                 <NavigationAuth
                   menuItems={[{route: ROUTES.HOME, name: "My Kitchen", icon: cook},
                               {route: ROUTES.DISHES, name: "DISHES", icon: cooking},
                               {route: ROUTES.ORDERS, name: "Orders", icon: clientOrder}]}
                   profileRoute={ROUTES.KITCHEN_ACCOUNT}
                   user={authUser} />
                 :
                 <NavigationAuth
                    menuItems={[{route: ROUTES.FIND_FOOD, name: "Find Kitchen", icon: findFood},
                                {route: ROUTES.CLIENT_ORDER, name: "ORDER", badge: this.state.orders, icon: clientOrder }]}
                    profileRoute={ROUTES.ACCOUNT}/>
              : 
              // <NavigationTryOrTrade/>
              <header /> 
                }

          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="Show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={this.handleMobileMenuOpen}
              color="black"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {
        // renderMobileMenu
      }

      {
      authUser ?
         _.get(_.get(authUser, "roles", {}), `${ROLES.COOK}`, false) ?
           <RenderMobileMenu
             menu={[{route: ROUTES.HOME, label: "My Kitchen", icon: cook},
                         {route: ROUTES.DISHES, label: "DISHES", icon: cooking},
                         {route: ROUTES.ORDERS, label: "Orders", icon: clientOrder}]}
             profileRoute={ROUTES.KITCHEN_ACCOUNT}
             auth={true}
            isMenuOpen={isMenuOpen}
            isMobileMenuOpen={isMobileMenuOpen}
            mobileMoreAnchorEl={this.state.mobileMoreAnchorEl}
            handleMobileMenuClose={this.handleMobileMenuClose}
            handleProfileMenuOpen={this.handleProfileMenuOpen}
            mobileMenuId={mobileMenuId}
            username={`${_.get(this.props.user, "first_name", _.get(firebase.auth().currentUser, "displayName", ""))} ${_.get(this.props.user, "last_name", "")}`}/>
           :
           <RenderMobileMenu
              menu={[{route: ROUTES.FIND_FOOD, label: "Find Kitchen", icon: findFood},
                          {route: ROUTES.CLIENT_ORDER, label: "ORDER", icon: clientOrder, badge: this.state.orders }]}
              profileRoute={ROUTES.ACCOUNT}
              auth={true}
             isMenuOpen={isMenuOpen}
             isMobileMenuOpen={isMobileMenuOpen}
             mobileMoreAnchorEl={this.state.mobileMoreAnchorEl}
             handleMobileMenuClose={this.handleMobileMenuClose}
             mobileMenuId={mobileMenuId}
            username={`${_.get(this.props.user, "first_name", _.get(firebase.auth().currentUser, "displayName", ""))} ${_.get(this.props.user, "last_name", "")}`}/>
        :
        <RenderMobileMenu
             menu={[{route: ROUTES.TASTE, label: "Try Taste", icon: dishIcon},
                         {route: ROUTES.TRADETASTES, label: "Trade Taste", icon: cookIcon}]}
             auth={false}
            isMenuOpen={isMenuOpen}
            isMobileMenuOpen={isMobileMenuOpen}
            mobileMoreAnchorEl={this.state.mobileMoreAnchorEl}
            handleMobileMenuClose={this.handleMobileMenuClose}
            mobileMenuId={mobileMenuId}/>
            // <NavigationTryOrTrade/>
            //
            //   <Button color="inherit">
            //     <div style={{width: 30, height: 30, marginRight: 5}}>
            //       <img src={dishIcon} style={{width: 30, height: 30}}/>
            //     </div>
            //     <Link to={ROUTES.TASTE}  style={{textDecoration: "none", color: "black"}}>Try Taste</Link>
            //   </Button>
            //   <Button color="inherit">
            //     <div style={{width: 45, height: 45, marginRight: 5}}>
            //       <img src={cookIcon} style={{width: 45, height: 45}}/>
            //     </div>
            //     <Link to={ROUTES.TRADETASTES} style={{textDecoration: "none", color: "black"}}> Trade Taste</Link>
            //   </Button>
      }

      {renderMenu}
      </div>
      }
      </AuthUserContext.Consumer>
    </div>
  );
}
}

PrimarySearchAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired,
  orderDishes: PropTypes.array.isRequired
};

// Navigation.propTypes = {
//   classes: PropTypes.object.isRequired,
//   userType: PropTypes.string.isRequired,
//   orderDishes: PropTypes.array.isRequired
// };
//
const mapStateToProps = (state) => {
  // console.log("dishes state", state)
  return {
    userType : state.nav.userType || "",
    orderDishes: state.findFood.orderDishes || [],
    orderUpdated: state.findFood.orderUpdated || null,

  }
}
//
const mapDispatchToProps = (dispatch) => ({
  setDishes: userType => dispatch({ type: 'GET_USER_TYPE', userType }),
})
//
// export default withStyles(styles)
// (withFirebase
//   (connect(mapStateToProps, mapDispatchToProps)
//     (Navigation)
//   )
// )

export default withStyles(styles)(withFirebase(connect(mapStateToProps, mapDispatchToProps)(PrimarySearchAppBar)));
