import React from 'react';
import PropTypes from 'prop-types'
import { connect }           from "react-redux"

import { withStyles } from '@material-ui/core/styles';

import { Paper, Grid, InputBase, Divider, IconButton, Fab, TextField,
          Button, Typography, AppBar, Tabs, Tab, Card, CardContent, Avatar, CircularProgress } from '@material-ui/core'
import LocationIcon from '@material-ui/icons/LocationOn';
import { Category } from '@material-ui/icons';

import StarRatings from 'react-star-ratings';

import geolib from 'geolib'

import { Link } from 'react-router-dom';

import * as ROUTES from '../../../constants/routes'

import { ExpandMore, ExpandLess, Close, Add, Remove } from '@material-ui/icons'

import firebase from "firebase";
import { withFirebase } from '../../Firebase';

import _ from 'lodash'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "60%",
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 600,
  },
})

class DishDetails extends React.Component {

  constructor() {
    super()

    this.state = {removedDishes: [],
                  orderDishes: [],
                  detailsChanged: false,
                  instructions: "",
                  servings: 0,
                  servingsChanged: false,
                  windowWidth: window.innerWidth,
                  windowHeight: window.innerHeight,
                  loading: false}
  }

  componentDidMount() {
    // console.log("orderDishes componentDidMount", this.props.orderDishes);

    if (this.state.servings === 0) {
      this.setState({ servings: _.parseInt(_.get(this.props.dishDetails, "servings", 1)), servingsChanged: false})
    }

    window.addEventListener("resize", this.updateDimensions);
    this.setState({ removedDishes: [], orderedDishes: this.props.orderDishes,
      instructions: _.get(this.props.dishDetails, "orderNote", ""), detailsChanged: false, loading: true})
  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  }



  componentWillUpdate(nextProps, nextState) {
    // when isUpdated changed from false to true
    if (nextProps.orderUpdated != this.props.orderUpdated) {
      return true
    }

    if (nextState.detailsChanged!= this.props.detailsChanged) {
      return true
    }

    if (nextState.windowWidth != this.state.windowWidth) {
      return true
    }
  }


  render() {
    const classes = this.props;

    var prevOrderedDishes = []
    return this.state.loading ?
      <Card style={{height: 450, width: this.state.windowWidth < 700 ? "90%" : "80%",
        marginLeft: this.state.windowWidth < 700 ? "5%" : "10%", marginRight: this.state.windowWidth < 700 ? "5%" : "10%",
        marginTop: 130, position: "relative"}}>

        <Fab aria-label="Close" className={classes.fab} size="small"
        style={{float: "right", backgroundColor: "white",
        position: "relative", marginTop: 10, marginRight: 10}}
          onClick={() => {
            var toggleDetails = document.getElementById("dishViewMore")
            toggleDetails.style.display === "none" ? toggleDetails.style.display = "block" : toggleDetails.style.display = "none"
          }}>
          <Close />
        </Fab>

        <Grid items container spacing={32} style={{paddingTop: 0, paddingBottom: 0}}>

            <Grid item lg={6} md={6} sm={12} style={{paddingTop: 0, paddingBottom: 0}}>
              <div style={{width: "100%", height: this.state.windowWidth < 960 ? 150 : 450}}>
                <img src={_.get(this.props.dishDetails, "avatarURL", "")} alt=""
                  style={{ width: "100%", height: this.state.windowWidth < 960 ? 150 : 450, objectFit: "cover"}}/>
              </div>
            </Grid>

            <Grid lg={6} md={6} sm={12} style={{paddingTop: 10}}>
                  <div style={{width: "90%", minWidth: 350, marginTop: 0, height: this.state.windowWidth < 960 ? 150 : 300, overflowY: "scroll", marginLeft: this.state.windowWidth < 960 ? 40 : "0%"}}>
                    <Typography variant="h5" component="h2">
                      {_.get(this.props.dishDetails, "dishName", "")}
                    </Typography>
                    <Typography color="textSecondary" variant="subtitle1">
                      Price: ${parseFloat(_.get(this.props.dishDetails, "price", 0)).toFixed(2)}
                    </Typography>
                    <Divider style={{marginTop: 10, marginBottom: 10}}/>
                    <TextField label="Special instructions note" name="instructions"
                      value={this.state.instructions || _.get(this.props.dishDetails, "orderNote", "")}
                      onChange={(e) => {
                        this.setState({ [e.target.name] : e.target.value, detailsChanged: true })
                      }}
                      style={{width: "90%"}} multiline rowMax={3}/>
                    <br/>
                    <div style={{marginTop: 10}}>
                      <Typography color="textSecondary" variant="subtitle1">
                        Ingredients: {_.join(_.map(_.get(this.props.dishDetails, "ingredients", []), i => _.get(i, "text", "")), ", ")}
                      </Typography>
                    </div>
                  </div>

                  <div style={{marginTop: 30, height: 50, width: "90%", marginLeft: "5%", marginRight: "5%"}}>
                    <Grid items container spacing={32}>
                      <Grid item lg={4} md={4} sm={5} xs={5}>
                        <Grid items container spacing={32}>
                          <Grid item lg={5} md={5} sm={5} xs={5}>
                            <Fab aria-label="Remove" className={classes.fab} size="small" style={{marginTop: 5, float: "left", backgroundColor: "white"}}
                              onClick={() => {
                                if (this.state.servings > 0) {
                                  this.setState({ servings : this.state.servings-1, detailsChanged: true, servingsChanged: true})
                                }
                              }}>
                              <Remove />
                            </Fab>
                          </Grid>
                          <Grid item lg={3} md={3} sm={3} xs={3}>
                            <Typography color="textSecondary" variant="subtitle1" style={{marginTop: 15, textAlign: "center"}}>
                              { this.state.servings <= 1 ? _.get(this.props.dishDetails, "orderServings", 1) : this.state.servings}
                            </Typography>
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Fab aria-label="Add" className={classes.fab} size="small" style={{marginTop: 5, float: "left", backgroundColor: "white"}}
                              onClick={() => {
                                // console.log("adding servings", this.state.servings);
                                if (this.state.servings < _.parseInt(_.get(this.props.dishDetails, "servings", 0))) {
                                  this.setState({ servings : this.state.servings+1, detailsChanged: true, servingsChanged: true})
                                }
                              }}>
                              <Add />
                            </Fab>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item lg={8} md={8} sm={7} xs={7}>
                        <Button size="medium" id={`btn_${_.get(this.props.dishDetails, "id", "")}`}
                          style={{textAlign: "center", backgroundColor: "rgb(241, 119, 33)", color: "black", width: "85%", minWidth: 150, marginLeft: "10%", marginRight: "5%", marginTop: 5}}
                          onClick={(e) => {
                            e.preventDefault()
                            this.setState({ orderedDishes : this.props.orderDishes})
                            if (this.state.detailsChanged) {
                              // prevOrderedDishes = _.pullAllBy(this.state.orderedDishes, [{'id': this.props.dishDetails.id}], 'id')
                              // this.setState({ removedDishes :  prevOrderedDishes })
                              //
                              // this.props.orderDish(this.state.removedDishes)

                              // this.setState({ removedDishes : _.pullAllBy(this.state.orderedDishes, [{'id': this.props.dish.id}], 'id') })
                              // console.log("removeDish", this.state.removedDishes);
                              // this.props.orderDish(this.state.removedDishes)

                              prevOrderedDishes = this.props.orderDishes
                              prevOrderedDishes = _.pullAllBy(prevOrderedDishes, [{'id': this.props.dishDetails.id}], 'id')
                              this.setState({ removedDishes : prevOrderedDishes })
                              this.props.orderDish(prevOrderedDishes)

                              this.props.orderDish(_.concat(this.props.orderDishes, [_.merge(this.props.dishDetails, {'orderServings': this.state.servings, 'orderNote': this.state.instructions})]))

                              document.getElementById(`btn${_.get(this.props.dishDetails, "id", "")}`).innerHTML = "Remove"

                              // this.setState({ detailsChanged: false})
                            } else if (_.findIndex(this.props.orderDishes, ['id', this.props.dishDetails.id]) != -1) {
                              prevOrderedDishes = this.props.orderDishes
                              // console.log("prevOrderedDishes", prevOrderedDishes);
                              prevOrderedDishes = _.pullAllBy(prevOrderedDishes, [{'id': this.props.dishDetails.id}], 'id')
                              // console.log("prevOrderedDishes", prevOrderedDishes, this.props.orderDishes);
                              this.setState({ removedDishes : prevOrderedDishes })
                              this.props.orderDish(prevOrderedDishes)
                              document.getElementById(`btn${_.get(this.props.dishDetails, "id", "")}`).innerHTML = "Add"

                            } else {
                              this.props.orderDish(_.concat(this.props.orderDishes, [_.merge(this.props.dishDetails, {'orderServings': this.state.servings, 'orderNote': this.state.instructions})]))
                              document.getElementById(`btn${_.get(this.props.dishDetails, "id", "")}`).innerHTML = "Remove"
                            }
                            this.setState({ detailsChanged: false, instructions: "", servings: 1})

                            document.getElementById("dishViewMore").style.display = "none"


                          }}>{ this.state.detailsChanged ? "Add Dish" : _.findIndex(this.props.orderDishes, ['id', this.props.dishDetails.id]) != -1 ? "Remove dish" : "Add dish"}</Button>
                        </Grid>
                      </Grid>
                  </div>
            </Grid>
          </Grid>
      </Card> : <CircularProgress color="secondary"/>
  }
}

DishDetails.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  // searchedKitchens: state.findFood.searchedKitchens || [],
  selectedKitchen: state.findFood.selectedKitchen || [],
  address: state.findFood.address || "",
  searchedDishes : state.findFood.searchedDishes || [],
  orderDishes: state.findFood.orderDishes || [],
  orderUpdated: state.findFood.orderUpdated || false,

  dishDetails: state.findFood.dishDetails || [],
  removedDish: state.findFood.removedDish || false

})

const mapDispatchToProps = (dispatch) => ({
  setSelectedKitchen: selectedKitchen => dispatch({ type: "GET_SELECTED_KITCHEN", selectedKitchen }),
  setSearchedDishes: searchedDishes => dispatch({ type: 'GET_SEARCHED_DISHES', searchedDishes }),
  orderDish: orderDishes => dispatch({ type: 'ORDER_DISHES', orderDishes }),
  setDishDetails: dishDetails => dispatch({ type: 'DISH_DETAILS', dishDetails })

})

export default withStyles(styles)(withFirebase(connect(mapStateToProps, mapDispatchToProps)(DishDetails)))
