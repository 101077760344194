import React       from 'react';
import PropTypes   from 'prop-types';
import { connect } from "react-redux"

import { withRouter }        from 'react-router-dom';
import { withStyles }        from '@material-ui/core/styles';
import { withAuthorization } from '../../Session';
import { withFirebase }      from '../../Firebase';
import firebase              from "firebase";

import { Card, Grid, Button, CardHeader, CardMedia,
            CardContent, CardActions, Typography, CircularProgress, Fab }   from '@material-ui/core';

import { Close } from '@material-ui/icons'

import _           from 'lodash'
import StarRatings from 'react-star-ratings';
import useAsync from 'react-async'

import AddDish     from './AddDish'


const styles = theme => ({
  card: {
    width: "70%",
    marginTop: 30,
    marginLeft: "15%",
    height: 550
  },
  img: {
    height: 130,
    textAlign: 'center'
  },
  cardHeader: {
    paddingBottom: 0,
    margin: 0,
    marginTop: 40,
  },
  cardContent: {
    paddingTop: 0,
    height: "70%"
  },
  cardActions: {
    float: "right",
    paddingTop: 10,
    marginTop: 20,
    marginRight: 20
  },
  close: {
    color: '#aaaaaa',
    float: 'right',
    fontSize: 40,
    fontWeight: 'bold',
    marginRight: 20,
    marginTop: 10,
    '&:hover': {
      color: '#000',
      textDecoration: 'none',
      cursor: 'pointer',
    }

  },
  addDish: {
    display: 'none',
    position: 'fixed',
    zIndex: 1,
    paddingTop: 10,
    paddingBottom: 10,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.3)',
  }
});

class DishDetails extends React.Component {

  constructor() {
    super();

    this.state = {editDish: {}, edit: false, windowWidth: 0, windowHeight: 0}
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.windowWidth != this.state.windowWidth) {
      return true
    }

    if (nextProps.isUpdated != this.props.isUpdated) {
      this.updatedDetails()
      return true
    }
  }

  updatedDetails = async () => {
    var dish = await this.props.firebase.dish(this.props.dishDetails.id).once('value')
    this.props.setDishDetails(_.merge({id: this.props.dishDetails.id}, dish.val()))

    // console.log("dishDetails", _.merge({id: this.props.dishDetails.id}, dish.val()));
  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  }

  postDish = () => {
    var post = {}
    var postedDishes = []
    var kitchenId = ""
    var kitchenCategories = []
    if (this.props.dishDetails.isPosted) {     // if true then unpost
      post = {isPosted: false}
    } else {
      post = {isPosted: true}
      this.props.setPostingDish(this.props.dishDetails)

      document.getElementById("postDish").style.display = "block"
    }

    this.updatedDetails()


    this.props.firebase.kitchens()
    .orderByChild('userId')
    .equalTo(_.get(firebase.auth().currentUser, "uid", ""))
    .limitToFirst(1)
    .once('value', snapshot => {
      // console.log("adding posted dishes kitchen", snapshot.val(), this.props.dishDetails);

      this.setState({ postedDishesIds: _.get(_.first(_.values(snapshot.val())), "postedDishesIds", []) })
      kitchenId = _.first(_.keys(snapshot.val()))

      postedDishes = this.state.postedDishesIds
      kitchenCategories = _.get(_.first(_.values(snapshot.val())), "categories", {})

      // console.log("has postedDishes", postedDishes, _.first(_.values(snapshot.val())));


      if (this.props.dishDetails.isPosted) {
        _.pull(postedDishes, this.props.dishDetails.id)
        kitchenCategories[this.props.dishDetails.category] ? kitchenCategories[this.props.dishDetails.category] -= 1 : kitchenCategories[this.props.dishDetails.category] = 0

      } else {
        postedDishes.push(this.props.dishDetails.id)
        // _.concat(postedDishes, this.props.dish.id)
        kitchenCategories[this.props.dishDetails.category] ? kitchenCategories[this.props.dishDetails.category] += 1 : kitchenCategories[this.props.dishDetails.category] = 1
      }

      // console.log("postedDishes", postedDishes, this.props.dishDetails.isPosted, this.props.dishDetails.id);
      // console.log("kitchenCategories", kitchenCategories, this.props.dishDetails.category);

      this.props.firebase.kitchen(kitchenId).update({ postedDishesIds: postedDishes, categories: kitchenCategories })
      .then(postedDish => {
        // console.log("dish is posted");
      }).catch(err => {
        // console.log("dish posting error");
      })

    })

    this.props.firebase.dish(this.props.dishDetails.id).update(post)
    .then(dish => {
      // console.log("dish is posted", dish);
      this.props.postedDish(true)

      this.props.setMessage({text: `Successfully ${this.props.dishDetails.isPosted ? "posted" : "unposted"} dish`, variant: 'success'})
      this.setState({})
    })
    .catch(error => {
      this.props.setMessage({text: `Error ${this.props.dishDetails.isPosted ? "posting" : "unposting" } dish`, variant: 'error'})
      // console.log("error posting dish", error);
    })
  }

  // postDish = () => {
  //   var post = {}
  //   if (this.props.dishDetails.isPosted) {     // if true then unpost
  //     post = {isPosted: false}
  //   } else {
  //     post = {isPosted: true}
  //   }
  //   this.props.firebase.dish(this.props.dishDetails.id).update(post)
  //   .then(dish => {
  //     console.log("dish is posted", dish);
  //     this.props.postedDish(true)
  //     document.getElementById("dishDetails").style.display = "none"
  //   })
  //   .catch(error => {
  //     console.log("error posting dish", error);
  //   })
  // }

  deleteDish = () => {
    // console.log("delete dish id", this.props.dishDetails);
    this.props.firebase.dish(this.props.dishDetails.id).remove()
    .then(dish => {
      // console.log("dish is deleted", dish);
      this.props.postedDish(true)
      this.props.setMessage({text: "Successfully deleted dish", variant: 'success'})
      document.getElementById("dishDetails").style.display = "none"
    })
    .catch(error => {
      // console.log("error posting dish", error);
      this.props.setMessage({text: "Error deleting dish", variant: 'error'})
    })
  }

  getImage(name) {
    var image = this.props.firebase.dishStorage(name)
    // console.log("image", image);
  }

  showIngredients() {
    var ingredients = []
    _.each(_.get(this.props.dishDetails, "ingredients", []), (val, key) => {
      ingredients.push(val.text)
    })

    return ingredients
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div id={`detailsCard_${_.get(this.props.dishDetails, "id", "0")}`} className="dishDetails" style={{overflowY: 'scroll', overflowX: "wrap" }}>
          <Card className={classes.card} style={{width: this.state.windowWidth < 700 ? "90%" : "70%", marginLeft: this.state.windowWidth < 700 ? "5%" : "15%"}}>

              <Fab aria-label="Close" className={classes.fab} size="small"
              style={{float: "right", backgroundColor: "white",
              position: "relative", marginTop: 10, marginRight: 10}}
              onClick={() => {
                document.getElementById("dishDetails").style.display = "none"
              }}>
                <Close />
              </Fab>
            <CardHeader
              title={_.get(this.props.dishDetails, "dishName", "")}
              className={classes.cardHeader}
              subheader={`Postes on ${_.get(this.props.dishDetails, "timestamp", "")}`}
            />

          {!_.size(this.props.dishDetails) && <CircularProgress color="secondary"/>}
          <CardContent className={classes.cardContent}>
              <div style={{height: this.state.windowHeight-350, overflowY: "scroll"}}>
              <Grid items spacing={32} container>
                <Grid item sm={12} lg={5}>
                  <div style={{float: "left", marginTop: 10, height: "90%"}}>
                    <StarRatings
                      rating={_.get(this.props.dishDetails, "rating", 1)}
                      starRatedColor="#ffd633"
                      numberOfStars={5}
                      name='rating'
                      starDimension="20px"
                      starSpacing="0px"
                      style={{marginTop: 10}}
                    />
                    {` ${_.get(this.props.dishDetails, "rating", "0")}`}
                    <br/>
                    <Typography variant="subheading">
                      {_.get(this.props.dishDetails, "noOrdered", 0) == 1 ? "Ordered once" : `Ordered ${_.get(this.props.dish, "noOrdered", 0)} times`}
                    </Typography>
                    <img src={_.get(this.props.dishDetails, "avatarURL", "")} style={{ width: "100%", maxHeight: this.state.windowWidth < 700 ? 150 : 270, objectFit: "cover"}}/>
                  </div>
                </Grid>
                <Grid item sm={12} lg={7}>
                  <div style={{marginTop: this.state.windowWidth < 700 ? 0 : 10, overflowY: "scroll", overflowX: "wrap", height: "100%"}}>
                    <Typography variant="h4">
                      {_.get(this.props.dishDetails, "price", "") ? `$${_.get(this.props.dishDetails, "price", "")}` : ""}
                    </Typography>
                    <br/>
                    <Typography variant="subtitle1">
                      {_.get(this.props.dishDetails, "description", "")}
                    </Typography>
                    <br/>
                    <Typography variant="subheading">
                    {_.size(_.get(this.props.dishDetails, "categoriesMethods", [])) ? `Categories: ${_.join(_.get(this.props.dishDetails, "categoriesMethods", []), ", ")}` : ``}
                    </Typography>
                    <br/>

                    <Typography variant="subheading">
                      {_.join(this.showIngredients(), ", ") ? `Ingredients: ${_.join(this.showIngredients(), ", ")}` : ""}
                    </Typography>
                    <br/>
                    <Typography variant="subheading">
                      {_.size(_.get(this.props.dishDetails, "restrictionsMethods", [])) ? `Restrictions ${_.join(_.get(this.props.dishDetails, "restrictionsMethods", []), ", ")}` : ``}
                    </Typography>
                    <br/>
                    <Typography variant="subheading" enm='h1'>
                      Servings: {_.get(this.props.dishDetails, "servings", "") ? `${_.get(this.props.dishDetails, "servings", "")}` : ``}
                    </Typography>
                    <br/>
                  </div>
                </Grid>
              </Grid>
              </div>
              <br/>
              <br/>
              <CardActions className={classes.cardActions} style={{marginTop: this.state.windowWidth < 700 ? 0 : "auto"}}>
                <Button size="medium" variant="contained" style={{backgroundColor: "#99cc00"}}
                  onClick={this.postDish}>
                  {_.get(this.props.dishDetails, "isPosted", "") ? "Unpost" : "Post"}
                </Button>
                <Button size="medium" variant="contained" style={{backgroundColor: "#99cc00",  marginLeft: 10}}
                  onClick={(e) => {
                    e.preventDefault()
                    // console.log("clicked on edit", this.props.dishDetails);
                    document.getElementById("dishDetails").style.display = "none"
                    document.getElementById("editDish").style.display = "block"
                  }}>
                  Edit
                </Button>
                <Button size="medium" variant="contained" style={{backgroundColor: "red",  marginLeft: 10}}
                  onClick={this.deleteDish}>
                  Delete
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </div>
    </div>
    );
  }
}

// const condition = authUser => !!authUser;
const condition = authUser =>
  authUser && !!authUser.roles && !!authUser.roles['cook'];

DishDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  isUpdated: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  // console.log("post dishes state", state)
  return {
    isUpdated : state.dishes.isUpdated || false,
  }
}

const mapDispatchToProps = (dispatch) => ({
  postedDish: isUpdated => dispatch({ type: 'POST_DISH', isUpdated }),
  setEditDish: dish => dispatch({ type: "GET_EDIT_DISH", dish}),
  setMessage: message => dispatch({ type: 'GET_MESSAGE', message }),
  setPostingDish: postingDish => dispatch({ type: 'GET_POSTING_DISH', postingDish}),
  setDishDetails: dishDetails => dispatch({ type: 'GET_DISH_DETAILS', dishDetails}),
})

export default withStyles(styles)
(withAuthorization(condition)
  (withRouter
    (withFirebase
      (connect(mapStateToProps, mapDispatchToProps)
        (DishDetails)
      )
    )
  )
);
