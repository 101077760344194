import React from 'react'
import PropTypes from 'prop-types';
import { connect }           from "react-redux"
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { TextField, Grid, Typography, CircularProgress } from '@material-ui/core';
import Checkout from './Checkout'
import _ from 'lodash'
import { withFirebase } from '../../Firebase';
import { useAsync } from "react-async"
import StarRatings from 'react-star-ratings';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
});

class ReviewOrder extends React.Component {
  constructor() {
    super()

    this.state = { rating: 0, comment: "", loading: false }
    this.changeRating = this.changeRating.bind(this);
    this.changeComment = this.changeComment.bind(this);

  }

  componentDidMount() {
    this.setState({ loading: true })
  }
  changeRating( newRating, name ) {
    this.setState({
      [name]: newRating
    });

    var updateRating = {rating: newRating, comment: this.props.review.comment}

    this.props.setReview(updateRating)
    // console.log("prop", this.state, newRating, name);
  }

  changeComment(e) {
    // console.log("comment", e.target);
    this.setState({ [e.target.name] : e.target.value})
    var updateComment = {rating: this.props.review.rating, comment: e.target.value}

    this.props.setReview(updateComment)
  }

  render() {
    return this.state.loading ?
          <div style={{height: 150, display: "flex", textAlign: "center", marginBottom: 50}}>

            <Grid items container spacing={32}>
              <Grid item lg={12} xs={12}>
                <StarRatings
                rating={this.state.rating}
                starRatedColor="#2ab22a"
                changeRating={this.changeRating}
                numberOfStars={5}
                name='rating'
                starHoverColor="rgb(241, 119, 33)"
                starDimension="40px"
              />
            </Grid>
          <Grid item lg={12} xs={12}>

          <TextField placeholder="Add a Comment"
          value={this.state.comment} name="comment"
          label={"Comment"} placeholder="Comment"
          onChange={this.changeComment}
          style={{width: 300}}
          multiline
          variant="outlined"
          rowMax={4}/>
          </Grid>
          </Grid>

        </div> : <CircularProgress color="secondary"/>
  }

}

ReviewOrder.propTypes = {
  classes: PropTypes.object.isRequired,
  currentOrder: PropTypes.array.isRequired,
};

const mapStateToProps = ( state ) => ({
  paymentStatus: state.clientOrder.paymentStatus || "",
  currentOrder: state.findFood.orderDishes || [],
  paymentMethod: state.clientOrder.paymentMethod || "",
  review: state.clientOrder.review || {}
})

const mapDispatchToProps = ( dispatch ) => ({
  setPaymentStatus: paymentStatus => dispatch({ type: "GET_PAYMENT_STATUS", paymentStatus}),
  setReview: review => dispatch({ type: 'GET_REVIEW', review }),

})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withFirebase(ReviewOrder)));
