import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import { connect }           from "react-redux"

import {geolocated} from 'react-geolocated'
import Geocode from "react-geocode"
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress } from '@material-ui/core';

import { withAuthorization } from '../../Session';

import _ from 'lodash'

import Categories from './categories'

import firebase from "firebase";
import { withFirebase } from '../../Firebase';

import SearchKitchens from './SearchKitchens'

import KitchensList from './KitchensList'
import Kitchen from './Kitchen'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  }
});

class FindFood extends React.Component {

  constructor(props) {
    super(props)

    this.state = {loading: false};
  }

  componentDidMount() {
    this.setState({ loading: true })
  }

  render() {
    const { classes } = this.props;

    return this.state.loading ? <div style={{width: "100%"}}>
              <div style={{width: "100%"}}>
                <Grid items container spacing={32} style={{width: "100%", margin: 0, padding: 0}}>
                  <Grid item lg={12} xs={12} style={{padding: 0}}>
                    <SearchKitchens/>
                  </Grid>
                  <Grid  item lg={12} xs={12} style={{padding: 0, marginTop: 20}}>
                    { !_.size(this.props.selectedKitchen) && <KitchensList/>}
                    {_.size(this.props.selectedKitchen) ? <Kitchen kitchen={this.props.selectedKitchen}/> : ""}

                  </Grid>
                </Grid>
              </div>
            </div> : <CircularProgress color="secondary"/>
  }
}

FindFood.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  selectedKitchen: state.findFood.selectedKitchen || []
})

const mapDispatchToProps = (dispatch) => ({
  setSelectedKitchen: selectedKitchen => dispatch({ type: "GET_SELECTED_KITCHEN", selectedKitchen })
})

export default withStyles(styles)(withFirebase(connect(mapStateToProps, mapDispatchToProps)(FindFood)))
