import React from 'react';
import PropTypes from 'prop-types'
import { connect }           from "react-redux"

import { withStyles } from '@material-ui/core/styles';

import { Paper, Grid, InputBase, Divider, IconButton,
          Button, Typography, Card, TextField, FormControl,
           InputLabel, Select, Input, MenuItem, CircularProgress } from '@material-ui/core'
import LocationIcon from '@material-ui/icons/LocationOn';
import { Category, Schedule, Event } from '@material-ui/icons';

import firebase from "firebase";
import { withFirebase } from '../../Firebase';

import _ from 'lodash'

import { geolocated } from 'react-geolocated'

import Categories from './categories'
import Geocode from "react-geocode"

// import GoogleLocations from 'google-locations';

// var locations = new GoogleLocations('AIzaSyBs1CQxECUVd2raz8V5ucjr6xJIuMNsnnM');


Geocode.setApiKey("AIzaSyBs1CQxECUVd2raz8V5ucjr6xJIuMNsnnM")
Geocode.enableDebug()

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "60%",
  },
  root: {
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    width: "100%",
    // minWidth: "60%"
  },
})

class SearchKitchens extends React.Component {
  constructor() {
    super()

    this.state = { address: "", kitchens: [], datetime: "",
      scheduleDate: "", scheduleTime: "", loading: false}
  }

  componentDidMount() {
    this.props.setCoords(this.props.coords)

    if (_.size(this.props.selectedKitchen)) {
      if (document.getElementById("categories")) {
        document.getElementById("categories").style.display = "none"
      }
      if (document.getElementById("searchFields")) {
        document.getElementById("searchFields").style.position = "fixed"
      }
      if (document.getElementById("searchForm")) {
        document.getElementById("searchForm").style.marginTop = 0
      }

    }
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions);

    this.setState({ loading: true })

  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.windowWidth != this.state.windowWidth) {
      return true
    }
  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  }

  handleChange = name => event => {
    this.setState({ [name] : event.target.value})
    if (name == "address") {
      this.props.setAddress(event.target.value)
    }
  }

  useCurrentAddress() {
    var lat = _.get(this.props.coords, "latitude", "") || 44.00
    var lngt = _.get(this.props.coords, "longitude", "") || 41.2

    Geocode.fromLatLng(lat, lngt).then(res => {
      // console.log("address found: ", res.results[0].formatted_address);
      this.setState({address : res.results[0].formatted_address ? res.results[0].formatted_address : ""})

      this.props.setAddress(res.results[0].formatted_address ? res.results[0].formatted_address : "")
    }).catch(err => {
      // console.log("err", err);
      this.setState({address: ""})
    })
  }

  findClosestKitchens() {
    // properties
      // closest distance, either by lat, long or city - zip
      // dish posted
      // kitchen - addPostedDishes - postedDishesIds = ['kjahsdkajshd', 'kajshdkajshd']

    var addressWords = _.words(_.toLower(this.props.address), /[^, ]+/g),
        zip = 0,
        foundKitchens = []


    _.each(addressWords, word => {
      if (_.parseInt(word) && word.length >= 5 && word.length <= 7) {
        zip = _.parseInt(word)
      }
    })

    // also get kitchens by coordinates
    this.props.firebase.kitchens()
    .once('value', snapshot => {
      foundKitchens = _.filter(snapshot.val(),
      val => {
        return (val.zipcode === `${zip}` || _.indexOf(addressWords, _.toLower(val.city)) != -1)
                && _.size(_.get(val, 'postedDishesIds', []))
                && this.checkCategories(this.props.selectedCategories, _.get(val, "categories", ""))
      })

      this.setState({kitchens: foundKitchens})
      this.props.setSearchedKitchens(foundKitchens)
      this.props.setSelectedKitchen({})

      if (_.size(foundKitchens)) {
        document.getElementById("categories").style.display = "none"
        document.getElementById("searchFields").style.position = "fixed"
        document.getElementById("searchForm").style.marginTop = 0
      }
    })
  }

  checkCategories(selectedCategories, categories) {
    var catMatch = false,
        lowerCategories = _.map(categories, cat => _.toLower(cat))

    if (_.size(selectedCategories)) {
      catMatch = false
      _.each(selectedCategories, selCat => {
        if (_.indexOf(lowerCategories, _.toLower(selCat)) != -1) {
          catMatch = true
          return
        }
      })
    } else {
      catMatch = true
    }

    return catMatch
  }

  handleDateChange(name, date) {
    // console.log("updating date state", name, date);
        this.setState({
            [name] : `${date.getMonth()+1} ${date.getDate()} ${date.getFullYear()}`,
        });
  }

  updateTimeValues = event => {

    var type = event.target.name.substr(event.target.name.length-2, event.target.name.length-2)

    if (type == "HR") {
      if (event.target.value < 24) {
        this.setState({ [event.target.name]: event.target.value });
      }
    } else if (type == "MM") {
      if (event.target.value < 60) {
        this.setState({ [event.target.name]: event.target.value });
      }
    }



    // console.log("time type", type, event.target.value);
    // this.props.setPaymentMethod(event.target.value)
  };

  toggleElement(id) {
    // console.log("toggleElement", document.getElementById(id));
    var element = document.getElementById(id)

    if (element) {
      // console.log("elem", element);
      if (element.style.display === "none") {
        element.style.display = "block"
      } else {
        element.style.display = "none"
      }
    }

  }

  getDate(date) {
    var day  = date.getDay(),
        month = date.getMonth(),
        date = date.getDate()


    var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                  "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"]

    return `${days[day]}, ${months[month]} ${date}`
  }

  getTime(selectedDate, tense) {
      var date = new Date(),
          hours = date.getHours(),
          minutes = date.getMinutes(),
          meridiem = "AM",
          hour = hours,
          times = []

      if (hour > 12) {
        hour = hour - 12
        meridiem = "PM"
      }

      if (minutes > 30) {
        minutes = 30
      } else {
        minutes = 0
      }

      // console.log("getTime1", this.getDate(date), this.getDate(date) == selectedDate, selectedDate, tense);

      if (minutes == 0) {
        times.push(`${hour}:00 ${meridiem} - ${hour}:30 ${meridiem}`)
        times.push(`${hour}:30 ${meridiem} - ${hour+1}:00 ${meridiem}`)
      }

      if (minutes == 30) {
        times.push(`${hour}:30 ${meridiem} - ${hour+1}:00 ${meridiem}`)
      }

      hour += 1

      var noTimes = 0

      do {
          times.push(`${hour}:00 ${meridiem} - ${hour}:30 ${hour == 12 ? "PM" : meridiem}`)
          times.push(`${hour}:30 ${hour == 12 ? "PM" : meridiem} - ${hour+1 > 12 ? 1 : hour+1}:00 ${hour == 12 ? "PM" : meridiem}`)
          hour += 1
          if (hour > 12) {
            hour = hour - 12
            meridiem = meridiem == "AM" ? "PM" : "AM"
          }
        noTimes += 1
        // console.log("noTimes", noTimes);
      } while(noTimes <= 22)


      // console.log("times", tense, selectedDate, times);

      return times
  }

  getFutureDateOpts() {
    var datenow = new Date()

    var dates = []

    dates.push(this.getDate(datenow))


    for (var i = 0; i < 6; i++) {
      datenow.setDate(datenow.getDate()+1)
      dates.push(this.getDate(datenow))
    }

    // console.log("times", dates);

    return dates

  }

  selectize(options, label, name) {
    const { classes } = this.props
    return     <FormControl className={classes.formControl} style={{padding: 10}}>
        <InputLabel shrink htmlFor="age-label-placeholder">
          {label}
        </InputLabel>
        <Select
          value={_.get(this.state, name, "")}
          onChange={this.handleSelectizeChange}
          input={<Input required name={name} id="age-label-placeholder" />}
          displayEmpty
          name={name}
          label={label}
          placeholder={label}

          className={classes.selectEmpty}
          style={{width: 130}}
        >
          {_.map(options, opt => {
            return <MenuItem value={opt}>{opt}</MenuItem>
          })}
        </Select>
      </FormControl>
  }

  handleSelectizeChange = e => {

    // console.log("times", e.target);
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { classes } = this.props

    return this.state.loading ?
      <div id="searchForm" style={{marginTop: _.size(this.props.selectedKitchen) ? 0 : 100, backgroundColor: "white", display: "block", width: "100%"}}>
        <Typography  component="h2" variant="display1" gutterBottom style={{marginLeft: "3%", overflow: "hidden"}}>
          Find Homemade Food
        </Typography>
        <div id='searchFields'
          style={{width: "100%", marginTop: 20,
          backgroundColor: "white", display: "block",
          zIndex: 1000,
          position: _.size(this.props.selectedKitchen) ? "fixed" : "static"}}>

          <Grid items container spacing={32} style={{paddingBottom: 0,  backgroundColor: "white", width: "100%", margin: 0}}>
            <Grid item lg={2} md={2} xs={2} style={{paddingBottom: 0}}>
              <Button style={{width: "100%", color: "rgb(241, 119, 33)", padding: this.state.windowWidth <= 800 ? 10 : 15}}

                onClick={() => {
                  if (document.getElementById("orderTime")) {
                    if ( document.getElementById("orderTime").style.display == "none") {
                        document.getElementById("orderTime").style.display = "block"
                        if (document.getElementById("categories")) {
                          document.getElementById("categories").style.display = "none"
                        }
                    } else {
                      document.getElementById("orderTime").style.display = "none"
                    }
                  }
                }}>
                {this.state.windowWidth <= 800 ? "" : this.state.scheduleDate ? "Scheduled" : "ASAP"}
                <Schedule style={{color: "rgb(241, 119, 33)", width: 40, height: 40, display: !this.state.scheduleDate && this.state.windowWidth <= 800 ? "block" : "none"}}/>
                <Event style={{color: "rgb(241, 119, 33)", width: 40, height: 40, display: this.state.scheduleDate && this.state.windowWidth <= 800 ? "block" : "none"}}/>
              </Button>
            </Grid>
            <Grid item lg={5} md={5} xs={6} style={{paddingBottom: 0}}>
              <Paper className={classes.root} elevation={1}>
                {/* <IconButton className={classes.iconButton} aria-label="Menu">
                  <MenuIcon />
                </IconButton> */}
                <InputBase id="address"
                  placeholder="Enter your city name or zip code"
                  className={classes.textField}
                  value={this.state.address || this.props.address}
                  onChange={this.handleChange('address')}
                  margin="normal"
                  style={{width: "100%"}}
                  required />
                <Divider className={classes.divider} />
                <IconButton
                  onClick={() => {this.useCurrentAddress()}}
                  color="primary"
                  className={classes.iconButton}
                  aria-label="Directions"
                  style={{marginRight: 0}}>

                  <LocationIcon style={{color: "rgb(241, 119, 33)"}}/>


                </IconButton>
              </Paper>
            </Grid>
            <Grid item lg={2} md={2} xs={1} style={{paddingLeft: 0, paddingRight: 10}}>
              <Button style={{width: "100%", marginTop: 10, backgroundColor: "white"}}
                onClick={() => {
                  if (document.getElementById("categories")) {
                    if (document.getElementById("categories").style.display == "none") {
                      document.getElementById("categories").style.display = "block"
                      if (document.getElementById("orderTime")) {
                        document.getElementById("orderTime").style.display = "none"
                      }
                    } else {
                      document.getElementById("categories").style.display = "none"
                    }
                  }
                }}>
                <Category style={{color: "rgb(241, 119, 33)", height: 35, width: 35}}/>
                <Typography style={{marginLeft: 20}}>
                  {this.state.windowWidth <= 960 ? "" : "Categories"}
                </Typography>
              </Button>
            </Grid>

            <Grid item lg={3} md={3} xs={3} style={{paddingLeft: 0}}>
              <Button style={{backgroundColor: "rgb(241, 119, 33)" , marginTop: 15, marginLeft: 10}}
                onClick={() => this.findClosestKitchens()}
                disabled={!this.props.address}>
                {this.state.windowWidth <= 700 ? "Find" : this.state.windowWidth > 700 && this.state.windowWidth < 910 ? "Find Kitchens" : "Find Closest Kitchens"}
              </Button>
            </Grid>
          </Grid>

          <div id="categories" style={{display: "none"}}>
            <Categories/>
          </div>


          <div id="orderTime" style={{display: "none"}}
            onMouseEnter={() => document.getElementById("orderTime").style.display = "block"}
            onMouseLeave={() => document.getElementById("orderTime").style.display = "none"}>
            <Card style={{width: 300, height: 250, maxHeight: 300, overflowY: "scroll", padding: 20}}>
              <Button variant="outlined" onClick={() => {
                  this.setState({ datetime: new Date(), scheduleDate: ""})
                  this.toggleElement("orderTime")
                  this.props.setScheduleDate(new Date())

                }} style={{color: "green"}}>ASAP</Button>
              <h3>OR</h3>

              <div id="schedule" variant="outlined" style={{display: "none", zIndex: -1000, marginBottom: 20}}>

                    {this.selectize(this.getFutureDateOpts(), "Schedule date", "scheduleDate")}
                    {this.selectize(this.getTime(this.getDate(new Date()), "future"), "Schedule time", "scheduleTime")}

              </div>
              <Button color="primary" variant="outlined" onClick={() => {
                  if (document.getElementById("schedule").style.display == "none") {
                    document.getElementById("schedule").style.display = "block"
                  } else {
                    document.getElementById("orderTime").style.display = "none"
                    if (this.state.scheduleDate) {
                      // console.log("selected date", this.state.scheduleDate, this.state.scheduleTimeHR, this.state.scheduleTimeMM, "scheduleDate", `${this.state.scheduleDate} ${this.state.scheduleTimeHR}:${this.state.scheduleTimeMM}:00`);
                      var scheduled = new Date(`${this.state.scheduleDate} ${new Date().getFullYear()} ${_.split(this.state.scheduleTime, " ")[0]}`)

                      this.setState({ datetime: scheduled, scheduleDate: scheduled})
                      this.props.setScheduleDate(scheduled)

                      // console.log("scheduleDate", scheduled, "state", this.state.scheduleDate, this.state.datetime);
                    }
                  }
                }}>Schedule</Button>
            </Card>
          </div>
        </div>
      </div> : <CircularProgress color="secondary"/>
  }
}

SearchKitchens.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  address: state.findFood.address || "",
  selectedCategories: state.findFood.selectedCategories || [],
  selectedKitchen: state.findFood.selectedKitchen || []

})

const mapDispatchToProps = (dispatch) => ({
  setAddress: address => dispatch({ type: "GET_ADDRESS", address}),
  setSearchedKitchens: searchedKitchens => dispatch({ type: "GET_SEARCHED_KITCHENS", searchedKitchens }),
  setCoords: coords => dispatch({ type: "GET_COORDS", coords}),
  setSelectedKitchen: selectedKitchen => dispatch({ type: "GET_SELECTED_KITCHEN", selectedKitchen }),
  setScheduleDate: scheduleDate => dispatch({ type: "GET_SCHEDULE_DATE", scheduleDate })
})

export default withStyles(styles)
(geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})
(withFirebase
  (connect(mapStateToProps, mapDispatchToProps)
  (SearchKitchens)
  )
))
