import React, {  Component } from 'react';
import PropTypes             from 'prop-types';
import { connect }           from "react-redux"

import { withRouter }   from 'react-router-dom';
import firebase         from 'firebase';
import { withStyles }   from '@material-ui/core/styles';
import { withFirebase } from '../../Firebase';

import  { TextField, Slide, MenuItem, Card, FormControl, Fab,
            Button, Typography, Grid, InputAdornment, FormHelperText } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';

import Progress     from 'react-progressbar'
import { useAsync } from "react-async"
import FileUploader from 'react-firebase-file-uploader';
import classnames   from 'classnames';
import _            from 'lodash'
import { WithContext as ReactTags } from 'react-tag-input';
import { Close } from '@material-ui/icons'


import {INGREDIENTS} from './ingredients';
import DishDetails   from './DishDetails'
import './style.css';
import MultipleSelect from './MultipleSelect';
import CategoriesSelect from './CategoriesSelect';
import RestrictionSelect from './RestrictionSelect';


const styles = theme => ({
  card: {
    width: "70%",
    // minHeight: 500,
    marginLeft: "15%",
    marginTop: 30
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  flex: {
    flex: 1,
  },
  margin: {
    margin: theme.spacing.unit,
  },
  textField: {
    flexBasis: 200,
    margin: 0,
    width: "90%"
  },
  close: {
    color: '#aaaaaa',
    float: 'right',
    fontSize: 40,
    fontWeight: 'bold',
    marginRight: 20,
    marginTop: 10,
    '&:hover': {
      color: '#000',
      textDecoration: 'none',
      cursor: 'pointer',
    }

  },
  form: {
    marginTop: 50,
    marginLeft: 30,
    width: "90%",
    // minHeight: 450,
    // maxHeight: 700,
  },
  actionBtns: {
    margin: 10,
    backgroundColor: "#99cc00"
  }
});

////////////////////////
/////// For Tags ///////
////////////////////////

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const suggestions = INGREDIENTS.map((ingredient) => {
  return {
    id: ingredient,
    text: ingredient
  }
})

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const DeliveryOptions = [
  'Self-pickup',
  'Kitchen Delivery',
  '3rd Party(i.e UberEats)',
];

//////////////////
//// categories //
/////////////////
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Categories = [
  'Asian',
  'American',
  'Indian',
  'African',
  'Mediterranean',
  'European',
  'Latin',
  'Mexican',
  'Other',
];

const Restrictions = [
  'Vegan',
  'Vegeterian',
  'Halal',
  'Kosher',
  'None',
];

///////////////////////////////
//// categories&restrictions //
//////////////////////////////


const delimiters = [KeyCodes.comma, KeyCodes.enter];


class AddDish extends Component {
  constructor() {
    super()
    this.state = {
      username: "",
      avatar: "",
      isUploading: false,
      progress: 0,
      avatarURL: "",
      expanded: false,
      open: false,
      amount: 0,
      amountValid: true,
      amountError: '',
      password: '',
      weight: '',
      showPassword: false,
      dish_name:'',
      dish_name_error:'',
      dishNameValid: true,
      dishNameError: '',
      servings: '',
      description: '',
      description_error:'',
      descriptionValid: true,
      descriptionError: '',
      restrictionsValid: true,
      restrictionsError: '',
      categoriesValid: true,
      categoriesError: '',
      avatar: '',
      tags: [],
      tagsError: '',
      tagsValid: true,
      suggestions: suggestions,
      tagsUpdated: false,
      categoriesMethods: [],
      restrictionsMethods: [],
      windowWidth: 0,
      windowHeight: 0
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleTagClick = this.handleTagClick.bind(this);
  }

  // componentDidMount() {
  //   // this.updateStatesEditDish()
  //   // this.props.getDisheRestrictions()
  //   }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions);
    // console.log("dishEdit", this.props.dish);

  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.windowWidth != this.state.windowWidth) {
      return true
    }
  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  }

  // getDisheRestrictions() {
  //   var restrictionsMethods = []
  //   _.each(_.get(this.props.dishDetails, "restrictionsMethods", []), (val, key) => {
  //     restrictionsMethods.push(val.text)
  //   })
  //
  //   return restrictionsMethods
  // }

  changeRestrictions = (event) => {
    event.preventDefault();
    let restrictions = event.target.value;
    let valid = true;
    let error = [];
    // console.log("restrictions", restrictions)
    if (!_.size(restrictions)) {
      restrictions = [];
        valid = false;
        error = 'This field is required';
    }

    this.setState({
      restrictionsMethods: restrictions,
      restrictionsValid: valid,
      restrictionsError: error,
    });
}

changeCategories = (event) => {
  event.preventDefault();
  let categories = event.target.value;
  let valid = true;
  let error = [];
  // console.log("categories", categories)
  if (!_.size(categories)) {
    categories = [];
      valid = false;
      error = 'This field is required';
  }

  this.setState({
    categoriesMethods: categories,
    categoriesValid: valid,
    categoriesError: error,
  });
}

  changeDishName = (event) => {
    let dish_name = event.target.value;
    let valid = true;
    let error = '';

    if (!dish_name) {
        dish_name = '';
        valid = false;
        error = 'This field is required';
    } else if (dish_name.length < 4) {
        valid = false;
        error = 'Please enter at leaset 4 characters';
    }


    // console.log("dishname error", valid, error)

    this.setState({
        dish_name: dish_name,
        dishNameValid: valid,
        dishNameError: error,
    });
}
changeDescription = (event) => {
  let description = event.target.value;
  let valid = true;
  let error = '';

  if (!description) {
      description = '';
      valid = false;
      error = 'This field is required';
  } else if (description.length < 4 || description.length >= 128) {
      valid = false;
      error = 'Please enter at leaset 128 characters';
  }

  this.setState({
      description: description,
      descriptionValid: valid,
      descriptionError: error,
  });
}


handleChangePrice = (event) => {
  // console.log("price", event.target.value, _.parseInt(event.target.value))
  let amount = event.target.value;
  let valid = true;
  let error = ''
  if (!amount || !parseFloat(event.target.value)) {
    amount = '';
    valid = false;
    error = 'This field is required';
  } else if(amount > 100) {
    valid = false;
    error = "Please enter a reasonable price"
  }

  if (typeof parseFloat(amount) === "number") {
    this.setState({
        amount: parseFloat(amount),
        amountValid: valid,
        amountError: error,
    });
  }
};

  updateStatesEditDish() {
    // console.log("edit dishes", this.props.dish);

    if (this.props.isEdit && this.props.dish) {
      _.each(this.props.dish, (val, key) => {
        // console.log("value", val, key);
        this.setState({key: val})
      })
    }
    // console.log("state", this.state);
  }

  validate = (event) => {
    const state = this.state;
    const dishNameEntered = !!state.dish_name;
    const descriptionEntered = !!state.descriptionEntered;
    const amountEntered = !!state.amountEntered;
    const categoriesSelected = !!state.categoriesSelected;
    const restrictionsSelected = !!state.restrictionsSelected;

    this.setState({
        dishNameValid: dishNameEntered && state.dishNameValid,
        dishNameValid: dishNameEntered ? state.dishNameValid : 'This field is required',
        descriptionValid: descriptionEntered && state.descriptionValid,
        descriptionValid: descriptionEntered ? state.descriptionValid: 'This field is required',
        amountValid: amountEntered && state.amountValid,
        amountValid: amountEntered ? state.amountValid: 'This field is required',
        categoriesValid: categoriesSelected ? state.categoriesSelected : 'This field is required',
        restrictionsValid: restrictionsSelected ? state.restrictionsSelected: 'This field is required',
    });
}

isDisabled = () => {
  if (!this.state.dish_name
    && !this.state.description
    && !this.state.amount
    && !this.state.avatarURL
    && !this.state.categoriesSelected
    && !this.state.restrictionsSelected)
    return true;   //!this.state.tags || this.props.restrictionsMethods || this.props.categoriesMethods}
}

isAddEnabled() {
  const dishNameEntered = this.state.dish_name || _.get(this.props.dish, "dish_name");
  const descriptionEntered = this.state.description || _.get(this.props.dish, "description");
  const dishname = this.state.dishNameValid;
  const description = this.state.descriptionValid;
  const amountValid = this.state.amountValid;
  const amountEntered = this.state.amount || _.get(this.props.dish, "amount");
  const picture = this.state.avatarURL || _.get(this.props.dish, "avatarURL");
  const categoriesMethods = _.size(this.state.categoriesMethods) ? this.state.categoriesMethods : _.get(this.props.dish, "categoriesMethods");
  const restrictionsMethods = _.size(this.state.restrictionsMethods) ? this.state.categoriesMethods : _.get(this.props.dish, "restrictionsMethods");

  // console.log("validations",dishNameEntered , dishname ,
  // _.size(categoriesMethods) , this.state.categoriesValid ,
  // descriptionEntered , description ,
  // amountValid , amountEntered ,
  // picture ,
  // _.size(restrictionsMethods) , this.state.restrictionsValid )

  // console.log("dishName validations", this.state.dish_name, _.get(this.props.dish, "dish_name"))
  // if (dishNameEntered && dishname &&
  //   _.size(categoriesMethods) && this.state.categoriesValid &&
  //   descriptionEntered && description &&
  //   amountValid && amountEntered &&
  //   picture &&
  //   _.size(restrictionsMethods) && this.state.restrictionsValid
  //   )
  // {
  //   return true;
  // }

  if (this.props.edit) {
    // only values
    if (dishNameEntered && descriptionEntered && amountEntered && picture && categoriesMethods && restrictionsMethods) {
      return true
    }
    return false
  } else {
    if (dishname && description && amountValid && picture && categoriesMethods && restrictionsMethods) {
      return true
    } else {
      return false
    }
  }
  // validation variables


  // if (_.size(categoriesMethods) && this.state.categoriesValid) {
  //   return true
  // }
  return false;
}
  /////////////////////
  // Image upload /////
  /////////////////////

  handleChangeUsername = event =>
    this.setState({ username: event.target.value });

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = progress => this.setState({ progress });

  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = filename => {
    this.setState({
      avatar: filename,
      progress: 100,
      isUploading: false,
    });
    firebase
      .storage()
      .ref('kitchen_pictures/dishes_pictures')
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ avatarURL: url }));


    // console.log("filename", filename);
    // console.log("image", _.split(filename, ".")[0]);
    var dishImage = _.split(filename, ".")
    this.setState({ avatar: filename, progress: 100, isUploading: false, image: dishImage });
    // firebase
    //   .storage()
    //   .ref("kitchen_pictures/dishes_pictures")
    //   .child(filename)
    //   .getDownloadURL()
    //   .then(url => this.setState({ avatarURL: url }));
  };

  /////////////////////
  /// Form handling ///
  /////////////////////

  handleChange = props => event => {
    // console.log("props", props);
    this.setState({ [props]: event.target.value });
  };

  updateInput = e  => {
     // console.log("updating input", e.target.name, e.target.value);
     // console.log("state", this.state);
     this.setState({
       [e.target.name]: e.target.value
     });
  }

  addDish = async () => {
        // console.log("state", this.state);

        // console.log("delivery method", this.props.deliveryMethods)

        var dish = {},
            kitchen = []

        await firebase
          .storage()
          .ref("kitchen_pictures/dishes_pictures")
          .child(this.state.avatar)
          .getDownloadURL()
          .then(url => this.setState({ avatarURL: url }));

        dish = {
          dishName: this.state.dish_name,
          avatarURL: this.state.avatarURL,
          noOrdered: 0,
          price: this.state.amount,
          rating: 0,
          timestamp: new Date().toString(),
          userId: _.get(firebase.auth().currentUser, "uid", ""),
          isPosted: false,
          description: this.state.description,
          deliveryMethods: this.props.deliveryMethods,
          categoriesMethods: this.state.categoriesMethods,
          restrictionsMethods: this.state.restrictionsMethods,
          servings: this.state.servings,
          ingredients: this.state.tags,

        }

        // console.log("dish multiselect", this.props.categoriesMethods, this.props.RestrictionsMethods)

        this.props.firebase.dishes().push().set(dish)
        .then(dish => {
          this.props.postedDish(true)
          this.props.setMessage({text: "Successfully added dish", variant: 'success'})
          this.props.firebase.kitchens()
          .orderByChild('userId')
          .equalTo(_.get(firebase.auth().currentUser, "uid", ""))
          .once('value', snapshot => {
            console.log("kitchen found", snapshot.val(), _.first(_.keys(snapshot.val())), _.values(snapshot.val()), _.get(_.first(_.values(snapshot.val())), "categories", []), this.state.categoriesMethods);

            this.props.firebase.kitchen(_.first(_.keys(snapshot.val())))
            .update({categories: _.union(_.get(_.first(_.values(snapshot.val())), "categories", []), this.state.categoriesMethods)})

            console.log("merge", _.get(_.first(_.values(snapshot.val())), "categories", []), this.state.categoriesMethods, _.union(_.get(_.first(_.values(snapshot.val())), "categories", []), this.state.categoriesMethods));
          })
          this.cancel()
        })
        .catch(error => {
          // console.log("error adding dishes", error);
          this.props.setMessage({text: "Error adding dishes", variant: 'error'})
        })
  }

  editDish = async () => {

    // console.log("called edit dish");
    var dish = {}
    var kitchenId = ""
    var kitchenCategories = []

    if (this.state.avatar) {
      await firebase
        .storage()
        .ref("kitchen_pictures/dishes_pictures")
        .child(this.state.avatar)
        .getDownloadURL()
        .then(url => this.setState({ avatarURL: url }));
    }

    dish = {
      dishName: this.state.dish_name || _.get(this.props.dish, "dishName", ""),
      avatarURL: this.state.avatarURL || _.get(this.props.dish, "avatarURL", ""),
      price: this.state.amount || _.get(this.props.dish, "price", ""),
      timestamp: new Date().toString(),
      description: this.state.description || _.get(this.props.dish, "description", ""),
      deliveryMethods: this.props.deliveryMethods || _.get(this.props.dish, "deliveryMethods", ""),
      restrictionsMethods: this.state.restrictionsMethods ||  _.get(this.props.dish, "restrictionsMethods", ""),
      categoriesMethods: this.state.categoriesMethods ||  _.get(this.props.dish, "categoriesMethods", ""),
      servings: this.state.servings || _.get(this.props.dish, "servings", ""),
      ingredients: this.state.tagsUpdated ? this.state.tags : _.concat(this.state.tags, _.get(this.props.dish, "ingredients", [])),
    }


    // console.log("dish multiselect", this.props.categoriesMethods, this.props.RestrictionsMethods)


    this.props.firebase.kitchens()
    .orderByChild('userId')
    .equalTo(_.get(firebase.auth().currentUser, "uid", ""))
    .limitToFirst(1)
    .once('value', snapshot => {
      // console.log("adding category dishes kitchen", snapshot.val(), this.props.dish);

      kitchenId = _.first(_.keys(snapshot.val()))

      // if value is changed
      if (this.state.categoriesMethods) {
        console.log("category changed");
        // if dish is not posted


        this.props.firebase.kitchen(kitchenId).update({ categories: _.union(_.get(_.first(_.values(snapshot.val())), "categories", []), this.state.categoriesMethods) })
        .then(postedDish => {
          // console.log("dish is posted");
        }).catch(err => {
          // console.log("dish posting error");
        })

        // if posted - remove old category and add new category

        // if (this.props.dish.isPosted) {
        //   // console.log("dish is posted");
        //   kitchenCategories[this.props.dish.category] ? kitchenCategories[this.props.dish.category] -= 1 : kitchenCategories[this.props.dish.category] = 0
        //   kitchenCategories[this.state.categoriesMethods] ? kitchenCategories[this.state.categoriesMethods] += 1 : kitchenCategories[this.state.categoriesMethods] = 1
        //
        // } // else doesn't matter, category will be added when dish is posted
      }

      // console.log("kitchenCategories", kitchenCategories, this.props.dish.category);


    })

    this.props.firebase.dish(_.get(this.props.dish, "id", "0")).update(dish)
    .then(dish => {
      this.props.postedDish(true)
      this.props.setMessage({text: "Successfully updated dish", variant: 'success'})
      this.cancel()
    })
    .catch(error => {
      // console.log("error adding dishes", error);
      this.props.setMessage({text: "Error updating dish", variant: 'error'})
    })
  }

  cancel() {
    this.setState({
      username: "",
      isUploading: false,
      progress: 0,
      avatarURL: "",

      expanded: false,
      open: false,
      amount: '',
      password: '',
      weight: '',
      showPassword: false,
      dish_name:'',
      deliveryMethods: [],
      categoriesMethods: [],
      restrictionsMethods: [],
      servings: '',
      description: '',
      avatar: '',
      tags: [],
      suggestions: suggestions,
    })
  }

  //////////////////////
  ///// Tags ///////////
  //////////////////////

  handleDelete(i) {
     const { tags } = this.state;

     if (!this.state.tagsUpdated && this.props.isEdit && _.get(this.props.dish, "ingredients", [])) {
       this.setState({tagsUpdated: true})
       this.setState({
         tags: _.concat(this.state.tags, _.get(this.props.dish, "ingredients", []))
         .filter((tag, index) => index !== i)
       })
     } else {
       this.setState({
         tags: tags.filter((tag, index) => index !== i),
       });
     }
   };

  handleAddition(tag) {
    // console.log("tags", tag);

    if (!this.state.tagsUpdated && this.props.isEdit && _.get(this.props.dish, "ingredients", [])) {
      _.each(_.get(this.props.dish, "ingredients", []), i => {
        this.setState(state => ({ tags: [...state.tags, i] }));
      })
      this.setState({tagsUpdated: true})
    }
    this.setState(state => ({ tags: [...state.tags, tag] }));
    // console.log("state", this.state)
   };

   handleDrag(tag, currPos, newPos) {
     if (!this.state.tagsUpdated && this.props.isEdit && _.get(this.props.dish, "ingredients", [])) {
       _.each(_.get(this.props.dish, "ingredients", []), i => {
         this.setState(state => ({ tags: [...state.tags, i] }));
       })
       this.setState({tagsUpdated: true})
     }

     const tags = [...this.state.tags];
     const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

     // re-render
     this.setState({ tags: newTags });
   };

   handleTagClick(index) {
     // console.log('The tag at index ' + index + ' was clicked');
   };
//////////////////////////////////////
//// categories&restrictions handle //
/////////////////////////////////////
  handleChangeCategories = (event) => {
    this.setState({categoriesMethods : event.target.value})
  }
  handleChangeRestrictions = (event) => {
    this.setState({restrictionsMethods : event.target.value})
  }
//////////////////////////////////////
//// categories&restrictions handle //
/////////////////////////////////////
  render() {
    const { classes} = this.props;
    const { tags, suggestions,deliveryMethods, categoriesMethods, restrictionsMethods} = this.state;
    const isEnabled = this.isAddEnabled();

    return (
      <div>
        <Card className={classes.card} style={{width: this.state.windowWidth < 700 ? "90%" : "70%", marginLeft: this.state.windowWidth < 700 ? "5%" : "15%"}}>
          <Fab aria-label="Close" className={classes.fab} size="small"
          style={{float: "right", backgroundColor: "white",
          position: "relative", marginTop: 10, marginRight: 10}}
            onClick={() => {
              // console.log("dishes edit", this.props.dish);
              if (this.props.isEdit) {
                document.getElementById("editDish").style.display = "none"
                document.getElementById("dishDetails").style.display = "none"
              }
              document.getElementById("addDish").style.display = "none"
              this.cancel()
            }}>
            <Close />
          </Fab>
          <form className={classes.form}>
            <Typography variant="h4" color="inherit" className={classes.flex} style={{textAlign: "center", marginBottom: 10}}>
              {this.props.isEdit ? "Edit Dish" : "Add New Dish"}
            </Typography>
            <div style={{height: this.state.windowHeight-300, overflowY: "scroll"}}>
              <Grid items spacing={32} container>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    id="dish_name"
                    name="dish_name"
                    className={classnames(classes.margin, classes.textField)}
                    value={this.state.dish_name || _.get(this.props.dish, "dishName", "")}
                    onChange={this.changeDishName}
                    error={!this.state.dishNameValid}
                    helperText={this.state.dishNameError}
                    label="Dish Name"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="description"
                  name="description"
                  className={classnames(classes.margin, classes.textField)}
                  value={this.state.description || _.get(this.props.dish, "description", "")}
                  onChange={this.changeDescription}
                  error={!this.state.descriptionValid}
                  helperText={this.state.descriptionError}
                  label="Tell your Tasters about your dish"
                  multiline
                  rowsMax={4}
                />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div style={{width: "100%"}}>
                  <FormControl style={{width: "90%"}}>
                    <InputLabel htmlFor="select-multiple-checkbox">Categories</InputLabel>
                    <Select
                      multiple
                      value={_.size(this.state.categoriesMethods) ?  this.state.categoriesMethods : _.get(this.props.dish, "categoriesMethods", [])}
                      onChange={this.changeCategories}
                      error={!this.state.categoriesValid}
                      helperText={this.state.categoriesError}
                      input={<Input id="select-multiple-checkbox" />}
                      renderValue={selected => selected.join(', ')}
                      MenuProps={MenuProps}
                      style={{width: "100%"}}
                    >
                      {Categories.map(name => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={this.state.categoriesMethods.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div style={{width: "100%"}}>
                  <FormControl style={{width: "90%"}}>
                    <InputLabel htmlFor="select-multiple-checkbox">Restrictions</InputLabel>
                    <Select
                      multiple
                      value={_.size(this.state.restrictionsMethods) ? this.state.restrictionsMethods : _.get(this.props.dish, "restrictionsMethods", [])}
                      onChange={this.changeRestrictions}
                      error={!this.state.restrictionsValid}
                      helperText={this.state.restrictionsError}
                      input={<Input id="select-multiple-checkbox" />}
                      renderValue={selected => selected.join(', ')}
                      MenuProps={MenuProps}
                      style={{width: "100%"}}
                    >
                      {Restrictions.map(name => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={restrictionsMethods.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                <TextField
                  type = "number"
                  id="filled-adornment-amount"
                  className={classnames(classes.margin, classes.textField)}
                  label="Price"
                  value={this.state.amount || _.get(this.props.dish, "price", "")}
                  name="amount"
                  onChange={this.handleChangePrice}
                  error={!this.state.amountValid}
                  helperText={this.state.amountError}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                >
                </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <label>Ingredients</label>
                    <br/><br/>
                    <ReactTags
                      tags={this.state.tagsUpdated ? this.state.tags :
                        _.concat(this.state.tags, _.get(this.props.dish, "ingredients", []))}
                      placeholder={"Add ingredients"}
                      style={{backgroundColor: "grey"}}
                      suggestions={suggestions}
                      delimiters={delimiters}
                      value={this.state.tagsUpdated ? this.state.tags :
                        _.concat(this.state.tags, _.get(this.props.dish, "ingredients", []))}
                      handleDelete={this.handleDelete}
                      handleAddition={this.handleAddition}
                      handleDrag={this.handleDrag}
                      handleTagClick={this.handleTagClick}
                      onChange={this.handleChange('tags')}
                      allowUnique={true}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} style={{margin: 0}}>
                  {this.props.isEdit ? "Current Image" : this.state.avatarURL ? "Image" : ""}
                  <br/><br/>
                  <img height={100} src={this.state.avatarURL || _.get(this.props.dish, "avatarURL", "")}/>
                </Grid>
                <Grid item xs={12} sm={6} md={6} style={{paddingTop: 0}}>
                    Dish images
                  <br/>
                  <br/>
                  <label style={{backgroundColor: 'steelblue', color: 'white', padding: 10, borderRadius: 4, pointer: 'cursor'}}>
                    Choose file
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="avatar"
                    filename={this.state.filename}
                    storageRef={firebase.storage().ref("kitchen_pictures/dishes_pictures")}
                    onUploadStart={this.handleUploadStart}
                    onUploadError={this.handleUploadError}
                    onUploadSuccess={this.handleUploadSuccess}
                    onProgress={this.handleProgress}
                    multiple
                  />
                  </label>
                  <br/>
                  <br/>
                  {this.state.isUploading && <Progress completed={this.state.progress} />}
                  {this.state.avatar}
                </Grid>
              </Grid>
            </div>
          </form>
          <div style={{marginTop: 10, marginBottom: 20, float: "right", marginRight: "5%"}}>
            <Button classname={classes.actionBtns} style={{backgroundColor: "#99cc00", margin: 10}}
              onClick={() => {
                if (this.props.isEdit) {
                  document.getElementById("editDish").style.display = "none"
                } else {
                  document.getElementById("addDish").style.display = "none"
                }
              }}>
              Cancel
            </Button>
            <Button classname={classes.actionBtns} style={{backgroundColor: "#99cc00", margin: 10}}
              disabled = {!isEnabled}
              onClick={() => {
                // console.log("clicked edit dish", this.props.isEdit);
                if (this.props.isEdit) {
                  this.editDish()
                  document.getElementById("editDish").style.display = "none"
                  
                } else {
                  this.addDish()
                  document.getElementById("addDish").style.display = "none"
                }
              }}>
              {this.props.isEdit ? "Save Dish" : "Add Dish"}
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

AddDish.propTypes = {
  classes: PropTypes.object.isRequired,
  isUpdated: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  // console.log("post dishes state", state)
  return {
    isUpdated : state.dishes.isUpdated || false,
    deliveryMethods: state.dishes.deliveryMethods || [],
    restrictionsMethods: state.dishes.restrictionsMethods || [],
    categoriesMethods: state.dishes.categoriesMethods || [],
  }
}

const mapDispatchToProps = (dispatch) => ({
  postedDish: isUpdated => dispatch({ type: 'POST_DISH', isUpdated }),
  setMessage: message => dispatch({ type: 'GET_MESSAGE', message })
})

export default withStyles(styles)
(withRouter
  (withFirebase
    (connect(mapStateToProps, mapDispatchToProps)
      (AddDish)
    )
  )
);
