import React from 'react';
import PropTypes from 'prop-types';
import { connect }           from "react-redux"
import { withStyles } from '@material-ui/core/styles';
import { withAuthorization } from '../../Session';
import firebase from 'firebase'
import { withFirebase } from '../../Firebase';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Card, Divider, Grid, Avatar, CircularProgress } from '@material-ui/core';
import _ from 'lodash'
import Checkout from './Checkout';
import OrderSteps from './OrderSteps'
import { useAsync } from "react-async"

import PrevOrder from './PrevOrder'

/// show order summary on side
// other side
  // on the top show status
    // on payment method, get status on payment if successful adding card enable complete step
    // show order details, payment method, address, time, enable place your order,
        // push order to orders collection -- one order could have many kitchens
            // clientOrders, cookOrders
            // id, userId, dishIds, total_amount, servings, status --- clientOrders -
            // id, cookId, dishIds, #clientOrder, servings, status

    // Show Status

const styles = theme => ({
  orderSummary: {
    width: '100%',
    marginTop: theme.spacing.unit * 2,
    overflowX: 'auto',
    marginLeft: "2%",
    height: 400
  },
  table: {
    minWidth: 700,
  },
});

let id = 0;
function createData(orderNumber, customerName, location, dishName, DeliveryOption, OrderStatus) {
  id += 1;
  return { orderNumber, customerName, location, dishName, DeliveryOption, OrderStatus };
}

const rows = [
  createData('1','Ahmed', '21 Celeveland Blvd Caldwell ID', 24, 4.0, 'deliverd'),
  createData('2','Kanchan', '12 Celeveland Blvd Caldwell ID', 37, 4.3,'delivered'),
  createData('3','Granzow', '120 Celeveland Blvd Caldwell ID', 24, 6.0,'delivered'),
  createData('4','Moha' , '1211 Celeveland Blvd Caldwell ID', 67, 4.3,'delivered'),
  createData('5','Alaa', '2009 Celeveland Blvd Caldwell ID', 49, 3.9,'delivered'),

];

let loadedStripe = false;


// <Table className={classes.table}>
//   <TableHead>
//     <TableRow>
//       <TableCell>Order Number</TableCell>
//       <TableCell align="right">Custmer Name </TableCell>
//       <TableCell align="right">Location</TableCell>
//       <TableCell align="right">Dish Name</TableCell>
//       <TableCell align="right">Delivery Option</TableCell>
//       <TableCell align="right">OrderStatus</TableCell>
//     </TableRow>
//   </TableHead>
//
//   <TableBody>
//     {rows.map(row => (
//       <TableRow key={row.id}>
//         <TableCell component="th" scope="row">
//           {row.orderNumber}
//         </TableCell>
//         <TableCell align="right">{row.customerName}</TableCell>
//         <TableCell align="right">{row.location}</TableCell>
//         <TableCell align="right">{row.dishName}</TableCell>
//         <TableCell align="right">{row.DeliveryOption}</TableCell>
//         <TableCell align="right">{row.OrderStatus}</TableCell>
//
//       </TableRow>
//     ))}
//   </TableBody>
// </Table>


class PreviousOrders extends React.Component {
  constructor() {
      super();
      this.state = {previousOrders: [], dishesInfo: [], kitchenName: "", loading: false};
      this.getPreviousOrders = this.getPreviousOrders.bind(this);

    }

  componentDidMount() {

    // if (window.Stripe) {
    //   this.setState({stripe: window.Stripe('pk_test_FnGbY0qD0mRH5bM9fssEyKiE00sqBowRGQ')});
    // } else {
    //   document.querySelector('#stripe-js').addEventListener('load', () => {
    //     // Create Stripe instance once Stripe.js loads
    //     this.setState({stripe: window.Stripe('pk_test_FnGbY0qD0mRH5bM9fssEyKiE00sqBowRGQ')});
    //   });
    // }

    this.getPreviousOrders()

    this.setState({ loading: true })

  }

  componentWillMount() {
    // console.log("dishImage changed", nextState.dishImages);
    // if (nextState.dishImages != this.state.dishImages) {
    //   console.log("dishImages", this.state.dishImages);
    // }

    // this.getPreviousOrders()
  }

  getPreviousOrders() {
    this.props.firebase.orders()
    .orderByChild('userId').equalTo(_.get(firebase.auth().currentUser, "uid", ""))
    .once('value', snapshot => {
      this.setState({previousOrders: snapshot.val()})
    })
  }




  // getPreviousOrders()  {
  //   var orders = [],
  //       dishId = "",
  //       dish = []

  //  this.props.firebase.orders()
  //   .orderByChild('userId')
  //   .equalTo(_.get(firebase.auth().currentUser, "uid", ""))
  //   .once('value', async (snapshot) => {
  //     // orders = await _.map(snapshot.val(), (val, key) => _.merge({'orderId' : key}, val))
  //
  //     // orders = await _.filter(orders, 'dishes')
  //     // console.log("orders", orders);
  //     // _.each(orders, async (order) => {
  //     //   console.log("order", order, _.get(order, "dishes", []));
  //     //
  //     //   await _.each(_.get(order, "dishes", []), async (dish) => {
  //     //
  //     //     dishId = await _.get(dish, 'dishId', "")
  //     //
  //     //     if (dishId ) {
  //     //       dish = await this.getDishValues(dishId)
  //     //       console.log("info", dish);
  //     //       this.setState({ dishesInfo: _.concat(this.state.dishesInfo, dish ) })
  //     //     }
  //     //   })
  //     // })
  //     // console.log("orders", orders);
  //     // dish = orders
  //
  //       this.setState({ previousOrders : snapshot.val() })
  //
  //
  //     return dish
  //
  //   })
  //
  //   // if (_.size(dish)) {
  //   //   return dish
  //   //   // this.setState({ previousOrders : dish})
  //   // }
  // }

  // getKitchenName = async (userId) => {
  //   if (userId) {
  //     const kitchen = await this.props.firebase.kitchens()
  //                             .orderByChild('userId').equalTo(userId)
  //                             .limitToFirst(1).once('value');
  //
  //     const kitchenValues = kitchen.val();
  //
  //     this.setState({kitchenName: _.get(_.first(_.values(kitchenValues)), "kitchen_name", "") })
  //   }
  // }

  // getDishValues = async (dishId) => {
  //   const dish = await this.props.firebase.dish(dishId).once('value')
  //
  //   const dishObj = dish.val()
  //
  //   return [{ [dishId] : {'avatarURL': _.get(dishObj, "avatarURL", ""),
  //                         'price': _.get(dishObj, "price", "0"),
  //                         "dishName" : _.get(dishObj, "dishName", "")}}]
  // }

  // dish(dish) {
  //   const { classes } = this.props
  //
  //   var dishInfo = _.get(_.find(this.state.dishesInfo, dish.dishId), dish.dishId, {})
  //
  //   return <div>
  //     <Avatar alt="" src={_.get(dishInfo, "avatarURL", "")}
  //       className={classes.bigAvatar}
  //       style={{width: 130, height: 130}} />
  //     <p>{_.get(dishInfo, "dishName", "")}</p>
  //     <p>Servings: {_.get(dish, "servings", "")}</p>
  //     <p>Order note: {_.get(dish, "orderNote", "")}</p>
  //   </div>
  // }

  // getKitchensName = async (userId) => {
  //   var kithenName = ""
  //
  //
  //
  //   // await this.props.kitchen(userId).once('value', snapshot => {
  //   //   console.log("found ordered kitchen", userId, snapshot.val());
  //   // })
  // }

  // order(order) {
  //   return <Card>
  //       <div style={{width: "100%", backgroundColor: "grey"}}>
  //         <Grid items container spacing={32}>
  //           <Grid item lg={4}>
  //             <h3>Order Placed</h3>
  //             <p>{_.get(order, "orderId", "")}</p>
  //           </Grid>
  //           <Grid item lg={4}>
  //             <h3>{_.get(order, "kitchenName", "undefined")}</h3>
  //             <p>{_.get(order, "kitchenUserId", "undefined")}</p>
  //           </Grid>
  //           <Grid item lg={4}>
  //             <h3>Total</h3>
  //             <p>${_.get(order, "totalAmount", "0")}</p>
  //           </Grid>
  //         </Grid>
  //       </div>
  //
  //       <div style={{backgroundColor: "white"}}>
  //         <Grid items container spacing={32}>
  //
  //             {
  //               _.map(_.get(order, "dishes", []), dish => {
  //                 return <Grid item lg={4} md={3} sm={2}>
  //                   {this.dish(dish)}
  //                 </Grid>
  //               })
  //             }
  //
  //         </Grid>
  //         {
  //           // this.getKitchensName(_.get(order, "kitchenUserId", ""))
  //         }
  //       </div>
  //   </Card>
  // }

  // showAllPreviousOrders() {
  //   console.log("previousOrders", this.getPreviousOrders(), this.state.dishesInfo);
  //   return _.map(this.getPreviousOrders(), (order, orderId) => {
  //
  //     return <div style={{margin: 20}}>
  //     <PrevOrder order={order} />
  //   </div>
  //   })
  // }

  render () {
    const { classes } = this.props;

    return this.state.loading ?
      <div style={{marginTop: 50}}>
      <h2 style={{marginLeft: 20, marginRight: 20}}>Previous Orders</h2>
      <Grid items container spacing={32}>
      {  _.map(this.state.previousOrders, (order, orderId) => {
        return <Grid item lg={12} xs={12}>
          <div style={{marginLeft: 20, marginRight: 20}}>
            <PrevOrder order={order} orderId={orderId}/>
          </div>
      </Grid>
      })}
      </Grid>

    </div> : <CircularProgress color="secondary"/>
  }

}

const condition = authUser =>
  authUser && !!authUser;

PreviousOrders.propTypes = {
  classes: PropTypes.object.isRequired,
  orderDishes: PropTypes.object.isRequired
};

// Dishes.propTypes = {
//   classes: PropTypes.object.isRequired,
//   dishes: PropTypes.array.isRequired,
//   isUpdated: PropTypes.bool.isRequired,
//   dishDetails: PropTypes.object.isRequired,
//   editDish: PropTypes.object.isRequired
// };

const mapStateToProps = (state) => {
  // console.log("orders state", state)
  return {
    orderDishes : state.findFood.orderDishes || []
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentOrder: currentOrder => dispatch({ type: 'GET_CURRENT_ORDER', currentOrder }),
  // postDishes: selectedDishes => dispatch({ type: 'GET_SELECTED_DISHES', selectedDishes }),
})

export default withStyles(styles)
(withAuthorization(condition)
  (connect(mapStateToProps, mapDispatchToProps)
    (withFirebase(PreviousOrders))
  )
);
