import React        from 'react';
import PropTypes    from 'prop-types'
import { connect }  from "react-redux"

import { Link } from 'react-router-dom';

import StarRatings from 'react-star-ratings';
import _ from 'lodash'

import firebase from "firebase";
import { withFirebase } from '../../Firebase';

import { withStyles } from '@material-ui/core/styles';

import { Paper, Grid, InputBase, Divider, IconButton,
          Fab, TextField, Button, Typography, AppBar,
          Tabs, Tab, Card, CardContent, Avatar, CircularProgress }       from '@material-ui/core'
import { ExpandMore, ExpandLess, Close, Add, Remove } from '@material-ui/icons'


import CheckoutItems from './CheckoutItems'
import Dish from './Dish'
import DishDetails from './DishDetails'

import * as ROUTES from '../../../constants/routes'



const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "60%",
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 600,
  },
})


class Kitchen extends React.Component {
  constructor() {
    super()

    this.state = {
                  tab: 0,
                  postedDishes: [],
                  category: "",
                  windowWidth: 0,
                  windowHeight: 0,
                  allPostedDishes: [],
                  loading: false
                 }

    this.handleChange = this.handleChange.bind(this);
  }

  updateDimensions() {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight })
  }

  componentDidMount() {
    this.getDishes()
    this.updateDimensions()

    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.setState({ loading: true })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUpdate(nextProps, nextState) {
    // when isUpdated changed from false to true
    if (nextProps.orderUpdated != this.props.orderUpdated) {
      return true
    }
  }

  handleChange(event, newValue) {
    this.setState({tab: newValue})
  }

  getDishes() {
    var postedDishesIds = _.get(this.props.selectedKitchen, "postedDishesIds", []),
        postedDishes = [],
        category = "",
        kitchenPostedDishes = []


    var dishesWithid = []
    var categories = []
    var categoriesGroup = []
    // var
    // get dishes by kitchenUserId
    // filter these dishes by category and kitchens postedDishesIds
    var allPostedDishes = []

    // get all posted dishes from posteddishesIds
    _.each(_.get(this.props.selectedKitchen, "postedDishesIds", []), (dishId, index) => {
      this.props.firebase.dish(dishId).once('value', snapshot => {
        // console.log("allPostedDishes", allPostedDishes, index, _.size(_.get(this.props.selectedKitchen, "postedDishesIds", []))-1);
        if (this.checkDishAvailability(snapshot.val())) {
          _.each(_.get(snapshot.val(), "categoriesMethods", []), val => {
              postedDishes.push({ category: val, [val] : _.merge({'id': dishId }, snapshot.val() ) })

            // console.log("postedDishes", postedDishes);
          })

          allPostedDishes.push(_.merge({'id': dishId }, snapshot.val() ))

        }

        if (index == _.size(_.get(this.props.selectedKitchen, "postedDishesIds", []))-1) {
          categories = _.map(_.groupBy(postedDishes, "category"), (cat, key) => {
            // console.log("cat", cat, key);

            return { [key] : _.map(cat, val => {
              // console.log("cat val", val, key, _.get(val, key))
              return _.get(val, key)
            }) }
          })
          // console.log("categories", categories);
          this.setState({ postedDishes: categories, allPostedDishes: allPostedDishes})
        }
      })

    })
    // get all categories
    // this.props.firebase.dishes()
    // .orderByChild('userId')
    // .equalTo(_.get(this.props.selectedKitchen, "userId", ""))
    // .once('value', snapshot => {
    //   console.log("all kitchen dishes", snapshot.val(), _.find(snapshot.val(), "categoriesMethods"));
    //   _.map(snapshot.val(), (val) => {
    //     _.merge(categories, _.get(val, "categoriesMethods", []))
    //   })
    //
    //   console.log("categories", categories);
    //   categoriesGroup = _.groupBy(snapshot.val(), val => {
    //     return _.size(_.intersection(_.get(val, "categoriesMethods", []), categories))
    //   })
    //
    //   console.log("categoriesGroup", categoriesGroup);
    //
    //   dishesWithid = _.map(snapshot.val(), (val, key) =>
    //   _.merge({'dishId': key}, val) )
    //   console.log("dishesWithid", dishesWithid);
    //   _.each(_.get(this.props.selectedKitchen, "categoriesMethods", []), (val) => {
    //     postedDishes.push({ [val] : _.filter(dishesWithid, (v, k) => _.get(v, "categoriesMethods") === val && v.isPosted && this.checkDishAvailability(v)) })
    //     console.log("dishes by category", val, postedDishes);
    //     this.setState({ postedDishes: postedDishes})
    //
    //   })

    // })





    // _.each(_.get(this.props.selectedKitchen, "categories", {}), (val, key) => {
    //     this.props.firebase.dishes()
    //     .orderByChild('category')
    //     .equalTo(key)
    //     .once('value', snapshot => {
    //       postedDishes.push({ [key] : snapshot.val()})
    //       this.setState({ postedDishes: postedDishes})
    //     })
    // })
  }

  checkDishAvailability(dish) {
    var isAvailable = false
    var dateNow = this.props.scheduleDate ? this.props.scheduleDate : new Date()
    var dishExpiryDatetime = ""
    var cookingDatetime = ""
    var orderDeadlineDatetime = ""
    var orderAcceptingDatetime = ""

    if (this.props.scheduleDate && this.props.scheduleDate != dateNow) {
      dateNow = this.props.scheduleDate
    }
    // console.log("check available", dish, dateNow);
    if (_.get(dish, "isPosted", false)) {
      if (_.get(dish, "cookingAvailability", "") === "availableNow" || _.get(dish, "cookingAvailability", "") === "availableLater") {
        dishExpiryDatetime = new Date(_.get(dish, "dishExpiryDatetime", ""))
        cookingDatetime = new Date(_.get(dish, "cookingDatetime", ""))
        isAvailable = dateNow < dishExpiryDatetime && dateNow > cookingDatetime
        // console.log("dishExpiryDatetime", dateNow, dishExpiryDatetime, dateNow < dishExpiryDatetime);
      } else if (_.get(dish, "cookingAvailability", "") === "availableOnRequest") {
        orderDeadlineDatetime = new Date(_.get(dish, "orderDeadlineDatetime", ""))
        orderAcceptingDatetime = new Date(_.get(dish, "orderAcceptingDatetime", ""))
        isAvailable = dateNow < orderDeadlineDatetime && dateNow > orderAcceptingDatetime
        // console.log("orderDeadlineDatetime", dateNow, orderDeadlineDatetime, dateNow < orderDeadlineDatetime);
      }
    }

    return isAvailable
  }


  dishesList() {
    var category = _.union(["All"], _.get(this.props.selectedKitchen, "categories", []))[this.state.tab]
    // console.log("category", category, this.state.postedDishes);
    return (
      <div>
      {

          category == "All" &&
            _.map(this.state.allPostedDishes, (dish, key) => {
                return <Dish dish={dish}/>
            })
        }
        {
          category != "All" &&

            _.map(this.state.postedDishes, (dishesCategory) => {
              return _.map(_.get(dishesCategory, category, []), val => {
                // console.log("dishesCategory", dishesCategory, "val", _.values(dishesCategory), val);

                return <Dish dish={val}/>
              })
            })
      }
      </div>
    )
  }

  showDishes() {
    var category = _.keys(_.merge({all: 1}, _.get(this.props.selectedKitchen, "categories", {})))[this.state.tab],
        dishesIds = _.get(this.props.selectedKitchen, "postedDishesIds", [])

    return (
      <div>
        {this.dishesList()}
      </div>
    )
  }



  render() {
    const { classes } = this.props

    return this.state.loading ?
      <div style={{width: "100%"}}>
        <div style={{ width: "100%", height: 500, paddingTop: "2%", marginTop: 10, paddingBottom: "2%", backgroundColor: "white"}}>
          <div style={{width: "100%", height: 250, position: "relative"}}>
            <div style={{width: "30%", minWidth: 200, overflow: "scroll",
                          height: 150, position: "absolute", float: "right",
                          backgroundColor: "rgb(255, 255, 255, 0.5)", display: "block",
                          marginTop: this.state.windowWidth <= 960 ? 100 : 50, marginLeft: this.state.windowWidth < 500 ? "25%" : "60%"}}>
              <div style={{marginLeft: "5%"}}>
                <h2 style={{color: "black", margin: 0}}>{_.get(this.props.selectedKitchen, "kitchen_name", "")}</h2>
                <p style={{fontSize: 15}}>{_.get(this.props.selectedKitchen, "street", "")}, </p>
                <p style={{fontSize: 15}}>{_.get(this.props.selectedKitchen, "city", "")}, {_.get(this.props.selectedKitchen, "zipcode", "")}</p>
                <StarRatings
                  rating={parseFloat(_.get(this.props.selectedKitchen, "ratings", 0))/parseFloat(_.get(this.props.selectedKitchen, "totalRating", 1))}
                  starRatedColor="rgb(241, 119, 33)"
                  numberOfStars={5}
                  name='rating'
                  starDimension="20px"
                  starSpacing="0px"
                />
              </div>
            </div>

            <img src={_.get(this.props.selectedKitchen, "avatarURL", "https://img.freepik.com/free-photo/board-amidst-cooking-ingredients_23-2147749529.jpg?size=626&ext=jpg")}
            alt="" style={{ width: "100%", height: 250, objectFit: "cover"}}/>

          </div>

          <AppBar position="static" color="default" style={{marginBottom: 10}}>
            <Tabs
              value={this.state.tab}
              onChange={this.handleChange}
              indicatorColor="orange"
              textColor="primary"
              variant="scrollable"
              scrollButtons="on"
            >
              {_.map(_.union(["All"], _.get(this.props.selectedKitchen, "categories", [])), val => {
                return <Tab label={val}/>
              })}
            </Tabs>
          </AppBar>

          <Grid items container spacing={32} style={{width: "100%", margin: 0, padding: 0}}>
            <Grid item lg={8} md={8} xs={12}>
              {this.showDishes()}
            </Grid>

            <Grid item lg={4} md={4} xs={12} style={{width: "100%", padding: 0}}>
              {
                this.state.windowWidth > 960
                &&
                <div style={{width: "30%"}}>

                  <Button disabled={_.size(this.props.orderDishes) == 0} variant="contained"
                  style={{backgroundColor: "#88C603", width: "30%", position: "fixed", bottom : 5}}
                  >
                    <Link to={ROUTES.CLIENT_ORDER} style={{textDecoration: "none", color: "black", width: "100%"}}>Checkout</Link>
                  </Button>
                  <br/>
                  {_.size(this.props.orderDishes) != 0 ?
                    <div style={{position: "fixed", width: "30%", bottom: 50}}>
                    <CheckoutItems orderDishes={this.props.orderDishes} setDishDetails={this.props.setDishDetails}/></div> : ""
                  }

                </div>
              }

              {
                this.state.windowWidth <= 960
                &&
                <div style={{width: "100%"}}>
                  <div id="expandCheckoutItems" style={{display: "none", position: "fixed", bottom: 60, width: "95%"}}>
                    <CheckoutItems orderDishes={this.props.orderDishes} setDishDetails={this.props.setDishDetails} />
                  </div>

                  <br/>
                  <Button disabled={_.size(this.props.orderDishes) == 0} variant="contained"
                    style={{backgroundColor: "#88C603", width: "100%", position: "fixed", top: this.state.windowHeight-50, height: 50}}
                     >

                    <Link to={ROUTES.CLIENT_ORDER} style={{textDecoration: "none", color: "black", width: "70%"}}>Checkout</Link>

                    <IconButton
                      onClick={() => {
                        if(document.getElementById("expandCheckoutItems")) {
                          document.getElementById("expandCheckoutItems").style.display === "none" ?
                            document.getElementById("expandCheckoutItems").style.display = "block" :
                              document.getElementById("expandCheckoutItems").style.display = "none"
                        }
                      }}
                      color="secondary"
                      className={classes.iconButton}
                      aria-label="Directions"
                      style={{marginRight: 0}}>

                      {
                        document.getElementById("expandCheckoutItems")
                        && document.getElementById("expandCheckoutItems").style.display === "block"
                        && <ExpandMore style={{color: "rgb(241, 119, 33)"}}/>
                      }

                      {
                        document.getElementById("expandCheckoutItems")
                        && document.getElementById("expandCheckoutItems").style.display === "none"
                        && <ExpandLess style={{color: "rgb(241, 119, 33)"}}/>
                      }

                    </IconButton>

                  </Button>

                </div>
              }

            </Grid>
          </Grid>

          <div id="dishViewMore"
            style={{display: 'none',
                  position: 'fixed',
                  zIndex: 1,
                  paddingTop: 10,
                  paddingBottom: 10,
                  left: 0,
                  top: 40,
                  width: '100%',
                  height: '100%',
                  overflow: 'auto',
                  backgroundColor: 'rgba(0,0,0,0.3)'}}>

              <div id="details">
                <DishDetails/>
              </div>

          </div>
        </div>
      </div> : <CircularProgress color="secondary"/>
  }
}

Kitchen.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  // searchedKitchens: state.findFood.searchedKitchens || [],
  selectedKitchen: state.findFood.selectedKitchen || [],
  address: state.findFood.address || "",
  searchedDishes : state.findFood.searchedDishes || [],
  orderDishes: state.findFood.orderDishes || [],
  orderUpdated: state.findFood.orderUpdated || false,

  dishDetails: state.findFood.dishDetails || [],
  removedDish: state.findFood.removedDish || false,
  scheduleDate: state.findFood.scheduleDate || ""

})

const mapDispatchToProps = (dispatch) => ({
  setSelectedKitchen: selectedKitchen => dispatch({ type: "GET_SELECTED_KITCHEN", selectedKitchen }),
  setSearchedDishes: searchedDishes => dispatch({ type: 'GET_SEARCHED_DISHES', searchedDishes }),
  orderDish: orderDishes => dispatch({ type: 'ORDER_DISHES', orderDishes }),
  setDishDetails: dishDetails => dispatch({ type: 'DISH_DETAILS', dishDetails }),
  setTotalAmount: totalAmount => dispatch({ type: 'GET_TOTAL_AMOUNT', totalAmount })
})

export default withStyles(styles)(withFirebase(connect(mapStateToProps, mapDispatchToProps)(Kitchen)))
