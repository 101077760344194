import React from 'react';
import { Grid } from '@material-ui/core'


class Quiz extends React.Component {
    constructor(props) {
      super(props)

      var dataSet = [
        {
          question: "Which one of these statements about bacteria is true?",
          answers: [
            "All types of bacteria give food poisoning",
            "Freezing makes food last longer by killing bacteria",
            "Bacteria grow fastest when they are warm",
            "All bacteria need air to survive"
          ],
          correct: 2
        },
        {
          question: "The temperature inside your fridge should be: ",
              answers: [
                "10°C",
                "4°C",
                "0°C",
                "-2°C"
              ],
              correct: 1
        },
         {
              question: "Which one of these foods is likely to contain the most bacteria?",
              answers: [
                "Cooked chicken",
                "Tinned cream",
                "Frozen raw chicken",
                "Bottled mayonnaise"
              ],
              correct: 2
            },
            {
              question: "Food poisoning bacteria will multiply readily between:",
              answers: [
                "-18°C – 0°C",
                "0°C – 5°C",
                "5°C – 63°C",
                "63°C – 90°C"
              ],
              correct: 2
            },
            {
              question: "The temperature in your freezer should be:",
              answers: [
                "-2°C",
                "-9°C",
                "-12°C",
                "-18°C"
              ],
              correct: 3
            },
            {
              question: "At work, the best way to dry your hands after washing is:",
              answers: [
                "Using a warm air dryer",
                "Give them a shake",
                "Using a cotton towel",
                "Using a paper towel"
              ],
              correct: 3
            },
            {
              question: "Food contaminated with food poisoning bacteria would:",
              answers: [
                "Smell",
                "Change colour",
                "Look and taste normal",
                "Be slimy and bitter"
              ],
              correct: 2
            },
            {
              question: " Food poisoning only occurs because of bad practice in:",
              answers: [
                "Restaurants",
                "Retail shops",
                "Home or domestic kitchens",
                "Any of the above"
              ],
              correct: 3
            },
            {
              question: "If you have reported diarrhoea and vomiting to your local authority or the Environmental Health Department after eating out it definitely means that:              ",
              answers: [
                "The food business was at fault",
                "It was your last meal that made you ill",
                "That practices in the you home could be improved",
                "Evidence will have to be obtained by the Environmental Health Department to positively prove where you pick up the bug that made you ill"
              ],
              correct: 3
            },
            {
              question: "I hereby agree on the terms and conditions of using this platform",
              answers: [
                "YES",
                "NO",
                "What are you talking about?",
                "Not sure"
              ],
              correct: 0
            },
      ];

      this.state = {current:0, dataSet:dataSet, correct:0, incorrect:0}
      this.handleClick = this.handleClick.bind(this)

    } // end constructor
    

    handleClick(choice) {
      if (choice == this.state.dataSet[this.state.current].correct) {
        this.setState({correct: this.state.correct + 1})
      } else {
        this.setState({incorrect: this.state.incorrect + 1})
      }

      console.log("score", this.state.correct, choice);

      if (this.state.correct == 9) {
        document.getElementById("quizarea").style.display = "none"
        document.getElementById("passedtest").style.display = "block"
      } else {
        if (this.state.current == 9) {
          this.setState({current: 0})
          this.setState({incorrect: 0})
          this.setState({correct: 0})
        } else {
             this.setState({current: this.state.current + 1})
        }
      }
    }

    render() {
      return(
        <div>
          <h1 style={{marginTop:"10%", textAlign:"center"}}> Food Safety Test </h1>
          <Grid items container spacing={32} style={{width: "60%", marginLeft: "20%", padding: 5}}>
            <Grid item sm={6} xs={12} style={{padding: 5}}>
              <h2 style={{width: "80%", backgroundColor: "yellowgreen", borderRadius: 10, textAlign: "center", padding: "1em"}}>
                Correct: {this.state.correct}
              </h2>
            </Grid>
            <Grid item sm={6} xs={12} style={{padding: 5}}>
              <h2 style={{width: "80%", backgroundColor: "darkorange", borderRadius: 10, textAlign: "center", padding: "1em"}}>
                Inorrect: {this.state.incorrect}
              </h2>
            </Grid>
          </Grid>
          <div id="quizarea" style={{display: "block"}}>
            <QuizArea handleClick={this.handleClick} dataSet={this.state.dataSet[this.state.current]} />
          </div>
          <div id="passedtest" style={{display: "none", textAlign: "center", width: "60%", marginLeft: "20%"}}>
            <h2 >Congratulations! You successfully passed the test.</h2>
            <p>Copy this code when you build kitchen. <b>{Math.random().toString(36).substring(3)}</b></p>
          </div>
        </div>
      )
    }
  }

  function Question(props) {
    var style = {
      color: "Black",
    }
    return (
      <h1 style={style}>{props.dataSet.question}</h1>
    )
  }

  function Answer(props) {
    var style = {
      width: "75%",
      height: 50,
      color: "blue"
    }
    return(
      <div>
        <button style={style} onClick={() => props.handleClick(props.choice)}>{props.answer}</button>
      </div>
    )
  }

  function AnswerList(props) {
    var answers = []
    for (let i = 0; i < props.dataSet.answers.length; i++) {
      answers.push(<Answer choice={i} handleClick={props.handleClick} answer={props.dataSet.answers[i]} />)
    }
    return(
      <div>
        {answers}
      </div>
    )
  }

  function QuizArea(props) {
    var style = {
      width: "75%",
      display: "block",
      textAlign: "center",
      boxSizing: "border-box",
      float: "left",
      padding: "10 2em",
      marginLeft:"10%"
    }
    return(
      <div style={style}>
        <Question dataSet={props.dataSet} />
        <AnswerList dataSet={props.dataSet} handleClick={props.handleClick} />
      </div>
    )
  }

  function TotalCorrect(props) {
      var style = {
      display: "inline-block",
      padding: "1em",
      background: "#00ff00",
      margin: "0 1em 0 0",
    }
    return(
      <h2 style={style}>Correct: {props.correct}</h2>
    )
  }

  function TotalIncorrect(props) {
    var style = {
      display: "inline-block",
      padding: "1em",
      background: "#ff0000",
      margin: "0 0 0 1em"
    }
    return(
      <h2 style={style}>Incorrect: {props.incorrect}</h2>
    )
  }

  function ScoreArea(props) {
    var style = {
      width: "100%",
      display: "block",
      textAlign: "left",
      float: "left",
      padding: "2em",
      marginLeft: "25%",
    }
    return(
      <div style={style} >
        <TotalCorrect correct={props.correct} />
        <TotalIncorrect incorrect={props.incorrect} />
      </div>
    )
  }

  export default Quiz;
