


const chefdata = {
    "properties": [
    {
        "_id": "593e9297e17df20c4a237d42",
        "index": 0,
        "price": 937180,
        "picture": "img/chef1.png",
    },
    {
        "_id": "593e9297ec4cca9c56bf61af",
        "index": 1,
        "price": 703725,
        "picture": "img/chef2.png",
    },
    {
        "_id": "593e929773c71925e5d7c11c",
        "index": 2,
        "price": 837111,
        "picture": "img/chef3.png",

    },
    {
        "_id": "593e92973d4d15eedb129aee",
        "index": 3,
        "price": 648223,
        "picture": "img/chef4.png",
    },
    {
        "_id": "593e92973d4d15eedb169aee",
        "index": 4,
        "price": 648223,
        "picture": "img/chef5.png",
    },

]
}

export default chefdata;