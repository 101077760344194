import React, { Component } from "react";
import emailjs from 'emailjs-com';

export class Contact extends Component {
  constructor(props) {
    super(props);

  this.state = {
    user_name:"",
    user_email:"",
    messege:"", 

  };
}

 sendEmail = (e) => {
  e.preventDefault();

  emailjs.sendForm('service_oqoc7wp','template_igebk48', e.target, 'user_g4AHXi5R2rvD99dgLzmDO')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log("got error", error.text);
    });
    e.target.reset();
   
    this.setState({
      user_name:"",
      user_email:"",
      message:"", 
  
    })
    
}

  render() {

    return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Get In Touch</h2>
                  <p>
                  Send us a message if you are: <br /> 
                     1) Interested in joining the TasteSwap team, <br/> or if you know someone with the skills  we need to grow and expand.<br/>
                     2) If you have more questions or need help with the app <br /> 
                     3) Feedback is also appreciated!
                  

                  </p>
                </div>
                <form name="sentMessage" id="contactForm" noValidate onSubmit={this.sendEmail}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="user_name"
                          name="user_name"
                          className="form-control"
                          placeholder="Name"
                          required="required"
                          value={this.state.user_name}
                          onChange={e => this.setState({user_name: e.target.value})}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="user_email"
                          name="user_email"
                          value={this.state.user_email}
                          onChange={e => this.setState({user_email: e.target.value})}
                          className="form-control"
                          placeholder="Email"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      value={this.state.message}
                      onChange={e => this.setState({message: e.target.value})}
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                    ></textarea>

                     
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <button 
                  type="submit" value="Send" className="btn btn-custom btn-lg">
                    Send Message
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Contact Info</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  {this.props.data ? this.props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  {this.props.data ? this.props.data.phone : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {this.props.data ? this.props.data.email : "loading"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div className="container text-center">
          <a href="http://www.TasteSwap.com" rel="nofollow">
            <p>
              &copy; 2021 TasteSwap LLC {" "}
            </p>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
