import React from 'react';
import _ from 'lodash'
import { Paper, Grid, InputBase, Divider, IconButton, Fab, TextField,
          Button, Typography, AppBar, Tabs, Tab, Card, CardContent, Avatar } from '@material-ui/core'


const CheckoutItems = ({ orderDishes, windowWidth, windowHeight, setDishDetails }) => {
  var total = 0
  return <Card style={{width: "100%", maxHeight: 410}}>
  <div style={{margin: 20, maxHeight: 500, width: "90%"}}>
    <div>
      <Grid items container spacing={16}>
        <Grid  item xs={9}>
          <Typography variant="h6" component="h6" style={{textAlign: "left"}}>
            Dish
          </Typography>
        </Grid>
        <Grid  item xs={3}>
          <Typography variant="h6" component="h6" style={{textAlign: "right", marginRight: 20}}>
            Price
          </Typography>
        </Grid>
      </Grid>
    </div>
    <div  style={{maxHeight: 300, overflowY: "scroll"}}>
    {_.map(orderDishes, dish => {
      if (_.parseInt(_.get(dish, "orderServings", 0))) {
        total = total + (parseFloat(_.get(dish, "price", 0)) * _.parseInt(_.get(dish, "orderServings", 0)))
      } else {
        total = total + parseFloat(_.get(dish, "price", 0))
      }
    return  <div>
              <Grid items container spacing={16}>
                <Grid  item xs={9}>
                  <Typography variant="subtitle1" color="textSecondary" style={{textAlign: "left"}}>
                    {_.get(dish, "dishName", "Unknown")}, {_.get(dish, "orderServings", "") > 1 ? _.get(dish, "orderServings", "") : ""}
                  </Typography>
                  <Button style={{textAlign: "left", color: "green", backgroundColor: "white"}}
                    onClick={() => {
                      setDishDetails(dish)
                      var viewMoreDisplay1 = document.getElementById("dishViewMore")

                      viewMoreDisplay1.style.display === "none" ? viewMoreDisplay1.style.display = "block" : viewMoreDisplay1.style.display = "none"

                    }}>
                    change
                  </Button>
                </Grid>
                <Grid  item xs={3}>
                  <Typography variant="subtitle1" color="textSecondary" style={{textAlign: "right", marginRight: 20}}>
                    ${_.parseInt(_.get(dish, "orderServings", 0)) <= 1 ? parseFloat(_.get(dish, "price", 0)).toFixed(2) : (parseFloat(_.get(dish, "price", "0")) * _.parseInt(_.get(dish, "orderServings", 1))).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </div>

    })}
    </div>
    <Divider style={{margin: 10}}/>
    <Grid items container spacing={16}>
      <Grid  item xs={9}>
        <Typography variant="h5" component="h2" style={{textAlign: "left"}}>
          Total
        </Typography>
      </Grid>
      <Grid  item xs={3}>
        <Typography variant="h5" component="h2" style={{textAlign: "right", marginRight: 20}}>
          ${total.toFixed(2)}
        </Typography>
      </Grid>
    </Grid>
  </div>
  </Card>
}

export default CheckoutItems
